import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { AppRoutePermissions, AppRoutePermissionsDenied } from '../app.routes.permissions';
import { take, map } from 'rxjs/operators';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
        this.routePermissions = AppRoutePermissions;
        this.routePermissionsDenied = AppRoutePermissionsDenied;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        return this.checkLoginAndRole(route.url[0].path, state.url);
    };
    AuthGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.checkChildLoginAndRole(childRoute._urlSegment.segments, state.url);
    };
    AuthGuard.prototype.checkLoginAndRole = function (url, fullPath) {
        var _this = this;
        return this.authService.whoAmI().pipe(take(1), map(function (res) {
            var hasPermission = res.userId != null;
            if (!hasPermission) {
                _this.router.navigate(['/login'], { queryParams: { redirectUrl: fullPath } });
            }
            return _this.checkDeniedRoute(url, res.type, res.profile.isAdmin, res.profile.isManager, res.profile.isDesigner)
                && _this.checkPermission(url, res.type, res.profile.isAdmin, res.profile.isManager, res.profile.isDesigner);
        }));
    };
    AuthGuard.prototype.checkChildLoginAndRole = function (segments, fullPath) {
        var _this = this;
        return this.authService.whoAmI().pipe(take(1), map(function (res) {
            var e_1, _a;
            var hasPermission = res.userId != null;
            if (!hasPermission) {
                _this.router.navigate(['/login'], { queryParams: { redirectUrl: fullPath } });
            }
            var nPath = '';
            try {
                for (var segments_1 = tslib_1.__values(segments), segments_1_1 = segments_1.next(); !segments_1_1.done; segments_1_1 = segments_1.next()) {
                    var urlSegment = segments_1_1.value;
                    if (nPath) {
                        nPath += '/';
                    }
                    nPath += urlSegment.path;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (segments_1_1 && !segments_1_1.done && (_a = segments_1.return)) _a.call(segments_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            var manager = (!res.profile.isAdmin && !res.profile.isDesigner);
            return _this.checkDeniedRoute(nPath, res.type, res.profile.isAdmin, res.profile.isManager, res.profile.isDesigner)
                && _this.checkPermission(nPath, res.type, res.profile.isAdmin, res.profile.isManager, res.profile.isDesigner);
        }));
    };
    AuthGuard.prototype.checkPermission = function (url, userType, isAdmin, isManager, isDesigner) {
        var e_2, _a;
        try {
            for (var _b = tslib_1.__values(this.routePermissions), _c = _b.next(); !_c.done; _c = _b.next()) {
                var e = _c.value;
                if (e.url === url) {
                    return e.userType === userType && (e.admin === isAdmin || e.manager === isManager || e.designer === isDesigner);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return true;
    };
    AuthGuard.prototype.checkDeniedRoute = function (url, userType, isAdmin, isManager, isDesigner) {
        var e_3, _a;
        try {
            for (var _b = tslib_1.__values(this.routePermissionsDenied), _c = _b.next(); !_c.done; _c = _b.next()) {
                var e = _c.value;
                if (e.url === url && e.userType === userType && e.designer === isDesigner && e.manager === isManager && e.admin === isAdmin) {
                    return false;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return true;
    };
    return AuthGuard;
}());
export { AuthGuard };
