import {KeyVal, OnClean, ProductsServiceData} from '../custom-interfaces';
import {Injectable} from '@angular/core';
import {ApiService} from '../api/api-service'
import {ApiClient} from '../api/api-client';
import {
  ActiveFilter,
  ActiveFilterValues,
  ApiError,
  ApiResponseMetadata, CustomerProductsRequest,
  GetProductList$Response,
  ProductListRow, ProductOrderBy,
  ProductOrderByValues, ProductStatusUpdateRequest, SortOrder,
  SortOrderValues, UpdateProductStatus$Response, UserOrderByValues
} from '../api/api-types';
import {Observable, ReplaySubject, Subject} from 'rxjs';

@Injectable()
export class ProductsService implements OnClean {

  private products: { [key: number]: ProductListRow[] };
  private metadata = new ApiResponseMetadata();
  private products$: Subject<GetProductList$Response> = new ReplaySubject<GetProductList$Response>(null);

  private _serviceData: ProductsServiceData = new ProductsServiceData;

  constructor(private api: ApiService, private apiClient: ApiClient) {
    this.products = {};
    this.initServiceData();
  }

  updateProductStatus(productId: number, status: number): Observable<UpdateProductStatus$Response> {
    const request: ProductStatusUpdateRequest = {status: status};
    return this.api.products.updateProductStatus(productId, request);
  }

  getProducts(customerId: number = null, name: string = null, code: string = null, activeFilter: ActiveFilter = null,
              orderBy: ProductOrderBy, order: SortOrder, pageNum: number): Observable<GetProductList$Response> {
    let forceFetch = false;

    if (
        this._serviceData.customerId !== customerId
        || this._serviceData.name !== name
        || this._serviceData.code !== code
        || this._serviceData.listOrderBy !== orderBy
        || this._serviceData.listSortOrder !== order
        || this._serviceData.activeFilter !== activeFilter
    ) {
      forceFetch = true;
      this.products = {};
    } else if (!this.products[pageNum] || this.products[pageNum].length === 0) {
      forceFetch = true;
    }

    if (forceFetch) {
      const limits = this.calculateOffsetAndLimit(pageNum);
      const request: CustomerProductsRequest = {
        customerId: customerId ? customerId : undefined,
        name: name ? name : undefined,
        code: code ? code : undefined,
        activeFilter: activeFilter ? activeFilter : ActiveFilterValues.ALL,
        orderBy: orderBy ? orderBy : undefined,
        order: order ? order : undefined,
        offset: limits.offset,
        limit: limits.limit
      };

      this.products$.unsubscribe();
      this.products$ = this.subscribeToProducts(request, pageNum);
    } else {
      this.products$.next({
        data: this.products[pageNum],
        metadata: this.metadata
      });
      this._serviceData.currentPage = pageNum;
    }

    this._serviceData.customerId = customerId;
    this._serviceData.name = name;
    this._serviceData.code = code;
    this._serviceData.listOrderBy = orderBy;
    this._serviceData.listSortOrder = order;
    this._serviceData.activeFilter = activeFilter;

    return this.products$;
  }

  private subscribeToProducts(request: CustomerProductsRequest, pageNum: number): Subject<GetProductList$Response> {
    var subscription = new ReplaySubject<GetProductList$Response>(null);

    this.api.products.getProductList(request, '').subscribe(res => {
      if (res) {
        if (res.data) {
          this.products[pageNum] = res.data;
        }
        if (res.metadata) {
          this.metadata.limit = res.metadata.limit;
          this.metadata.offset = res.metadata.offset;
          if (res.metadata.totalCount != null) {
            this.metadata.totalCount = res.metadata.totalCount;
            this._serviceData.totalCount = res.metadata.totalCount;
          }
        }
      }

      this.products$.next({
        data: this.products[pageNum],
        metadata: this.metadata
      });
      this._serviceData.currentPage = pageNum;
    }, (error: ApiError) => {
      this.products$.error(error);
    });

    return subscription;
  }

  clean(): void {
    this.products = [];
    this.metadata.offset = undefined;
    this.metadata.limit = undefined;
    this.metadata.totalCount = undefined;
    this.initServiceData();
  }

  initServiceData(): void {
    this._serviceData.listOrderBy = ProductOrderByValues.name;
    this._serviceData.listSortOrder = SortOrderValues.ASC;
    this._serviceData.totalCount = 0;
    this._serviceData.rowsPerPage = 25;
    this._serviceData.currentPage = 1;
    this._serviceData.activeFilter = ActiveFilterValues.ALL;
    this._serviceData.code = null;
    this._serviceData.customerId = null;
    this._serviceData.name = null;
  }

  public refreshCurrentPage(): void {
    this.products[this._serviceData.currentPage] = [];
  }

  private calculateOffsetAndLimit(pageNum: number): any {
    const offset = (pageNum - 1) * this._serviceData.rowsPerPage;
    const limit = offset + this._serviceData.rowsPerPage;
    return { offset: offset, limit: limit }
  }

  getServiceData(): ProductsServiceData {
    return this._serviceData;
  }
}
