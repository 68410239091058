import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AppRoutePermissions, AppRoutePermissionsDenied} from '../app.routes.permissions';
import {UserData, UserType} from '../api/api-types';
import {take, map} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  private routePermissions = AppRoutePermissions;
  private routePermissionsDenied = AppRoutePermissionsDenied;

  constructor(
      private authService: AuthService,
      private router: Router
  ) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkLoginAndRole(route.url[0].path, state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkChildLoginAndRole((childRoute as any)._urlSegment.segments, state.url);
  }

  checkLoginAndRole(url: string, fullPath: string): Observable<boolean> {
    return this.authService.whoAmI().pipe(take(1), map((res: UserData) => {
      const hasPermission = res.userId != null;
      if (!hasPermission) {
        this.router.navigate(['/login'], { queryParams: { redirectUrl: fullPath }});
      }
      return this.checkDeniedRoute(url, res.type, res.profile.isAdmin, res.profile.isManager, res.profile.isDesigner)
          && this.checkPermission(url, res.type, res.profile.isAdmin, res.profile.isManager, res.profile.isDesigner);
    }));
  }

  checkChildLoginAndRole(segments: UrlSegment[], fullPath): Observable<boolean> {
    return this.authService.whoAmI().pipe(take(1), map((res: UserData) => {
      const hasPermission = res.userId != null;
      if (!hasPermission) {
        this.router.navigate(['/login'], { queryParams: { redirectUrl: fullPath }});
      }
      let nPath = '';
      for (const urlSegment of segments) {
        if (nPath) {
          nPath += '/';
        }
        nPath += urlSegment.path;
      }
      const manager = (!res.profile.isAdmin && !res.profile.isDesigner);
      return this.checkDeniedRoute(nPath, res.type, res.profile.isAdmin, res.profile.isManager, res.profile.isDesigner)
          && this.checkPermission(nPath, res.type, res.profile.isAdmin, res.profile.isManager, res.profile.isDesigner);
    }));
  }

  private checkPermission(url: string, userType: UserType, isAdmin: boolean, isManager: boolean, isDesigner: boolean): boolean {
    for (const e of this.routePermissions) {
      if (e.url === url) {
        return e.userType === userType && (e.admin === isAdmin || e.manager === isManager || e.designer === isDesigner);
      }
    }
    return true;
  }

  private checkDeniedRoute(url: string, userType: UserType, isAdmin: boolean, isManager: boolean, isDesigner: boolean): boolean {
    for (const e of this.routePermissionsDenied) {
      if (e.url === url && e.userType === userType && e.designer === isDesigner && e.manager === isManager && e.admin === isAdmin) {
        return false;
      }
    }
    return true;
  }

}
