import {OnClean} from '../custom-interfaces';
import {Injectable} from '@angular/core';
import {ApiService} from '../api/api-service';
import {
  ApiResponseMetadata,
  CommonProductListRow,
  CommonProductsByGroups,
  CustomerStandardProductSalesUnit,
  GetAvailableStandardProductsWithUnits$Response,
  GetCommonProductsInGroups$Response,
  GetCustomerStandardProductsSalesUnits$Response,
  ProductAvailableSalesUnit,
  SaveStandardProductCustomerSalesUnit$Response,
  SaveStandardProductOrder$Response,
  StandardProductOrderSaveResource,
  StandardProductsWithUnits
} from '../api/api-types';
import {forkJoin, Observable, ReplaySubject, Subject} from 'rxjs';
import {CommonProductListOrderRow, StandardProductsFilter} from '../common/inventory';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ErrorService} from '../api/error-service';
import {localMoment} from '../common/DateUtil';
import {StandardProductOrderDateValidator} from '../shared/validation/StandardProductOrderDateValidator';

@Injectable()
export class StandardProductsService implements OnClean {

  public standardProductFilter: StandardProductsFilter = new StandardProductsFilter();

  private metadata = new ApiResponseMetadata();
  private commonProductsInGroupsSubject: Subject<GetCommonProductsInGroups$Response> = new ReplaySubject<GetCommonProductsInGroups$Response>();
  private purchaseForm: FormGroup;


  constructor(private api: ApiService,
              private fb: FormBuilder,
              private errorService: ErrorService) {
  }

  public get productsArray(): FormArray {
    return (this.purchaseForm.controls.products as FormArray);
  }

  public removeAllCommonProducts() {
    this.productsArray.controls.forEach((r, i) => this.productsArray.removeAt(i));
  }

  clean(): void {
    this.metadata.totalCount = undefined;
    this.metadata.limit = undefined;
    this.metadata.offset = undefined;
    this.standardProductFilter = new StandardProductsFilter();
  }

  createStandardProductOrder(customerId: number, order: StandardProductOrderSaveResource): Observable<SaveStandardProductOrder$Response> {
    return this.api.standardProducts.saveStandardProductOrder(customerId, order);
  }


  public createInitialFormForPurchases(row: CommonProductListOrderRow) {
    return this.fb.group({
      productId: [row.productId],
      itemCode: [row.itemCode],
      itemName: [row.itemName],
      ean: [row.ean],
      itemGroupId: [row.itemGroupId],
      itemGroupName: [row.itemGroupName],
      availableQuantity: [row.availableQuantity],
      salesUnit: [row.salesUnit],
      inventUnit: [row.inventUnit],
      externalItemId: [row.externalItemId],
      orderDeliveryDate: [row.orderDeliveryDate ? new Date(row.orderDeliveryDate) : undefined,
        [Validators.required, StandardProductOrderDateValidator.dateValidator]],
      orderQuantity: [row.orderQuantity, [
        Validators.required,
        Validators.min(1),
        Validators.max(row.availableQuantity)
      ]]
    });
  }

  getAvailableStandardProductUnits(): Observable<GetAvailableStandardProductsWithUnits$Response> {
    return this.api.standardProducts.getAvailableStandardProductsWithUnits();
  }

  saveCustomerSpecificSalesUnit(customerId: number, selectedUnit: ProductAvailableSalesUnit): Observable<SaveStandardProductCustomerSalesUnit$Response> {
    return this.api.standardProducts.saveStandardProductCustomerSalesUnit(customerId, selectedUnit);
  }

  getCustomerStandardProductSalesUnits(customerId: number): Observable<GetCustomerStandardProductsSalesUnits$Response> {
    return this.api.standardProducts.getCustomerStandardProductsSalesUnits(customerId);
  }


  getCommonProductsInGroupsWithCustomerSpecificSalesUnits(customerId: number) {
    this.commonProductsInGroupsSubject = new ReplaySubject<GetCommonProductsInGroups$Response>(null);
    forkJoin([this.api.products.getCommonProductsInGroups(),
      this.api.standardProducts.getCustomerStandardProductsSalesUnits(customerId),
      this.api.standardProducts.getAvailableStandardProductsWithUnits()]
    ).subscribe(results => {
      const commonProductsByGroups = results[0].data;
      const customerStandardProductSalesUnits = results[1].data;
      const availableUnits = results[2].data;
      const map = commonProductsByGroups.map(grp => {
        grp.products = grp.products.map(product => product = this.changeToCustomerSpecificSalesUnit(product,
            customerStandardProductSalesUnits, availableUnits));
        return grp;
      });
      this.commonProductsInGroupsSubject.next({
        data: map,
        metadata: undefined
      });
    }, error => {
      this.errorService.handleError(error, '');
    });
    return this.commonProductsInGroupsSubject;
  }


  private changeToCustomerSpecificSalesUnit(productRow: CommonProductListRow,
                                            customerStandardProductSalesUnits: CustomerStandardProductSalesUnit[],
                                            availableUnits: StandardProductsWithUnits[]): CommonProductListRow {
    const productId = parseInt(productRow.productId, 10);
    const availableSalesUnits = availableUnits.find(product => product.id === productId);
    const salesUnit = customerStandardProductSalesUnits.find(product => product.productId === productId);
    if (salesUnit !== undefined && availableSalesUnits !== undefined) {
      const temp = availableSalesUnits.possibleConversions.find(unit => unit.toUnitId === salesUnit.customSalesUnitId);
      productRow.salesUnit = temp.toUnit;
      productRow.availableQuantity = productRow.availableQuantity / temp.coefficient;
    }
    return productRow;
  }
}
