import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {ApiService} from '../api/api-service';
import {
  ActivateUser$Response,
  AdUserUpdate,
  ApiResponseMetadata,
  DeactivateUser$Response,
  GetLastUsersFetch$Response,
  GetManagersList$Response,
  GetUsers$Response,
  GetUsersRequest,
  GetUserViewData$Response,
  LoginAsUser$Response,
  ManagerListRow,
  PullUsersFromAxo$Response,
  SaveNotificationsRequest,
  SaveUserNotifications$Response,
  SendInvitation$Response,
  SortOrder,
  SortOrderValues,
  ToggleAdmin$Response,
  ToggleAdminRequest,
  ToggleNotificationEmails$Response,
  UpdateAdUsername$Response,
  UserListRow,
  UserOrderBy,
  UserOrderByValues,
  UserType
} from '../api/api-types';
import {OnClean, UserServiceData} from '../custom-interfaces';
import {ApiClient} from '../api/api-client';


@Injectable()
export class UsersService implements OnClean {

  private pages: { [key: number]: UserListRow[] };
  private metadata: ApiResponseMetadata = new ApiResponseMetadata;
  private usersListSubject: Subject<GetUsers$Response> = new ReplaySubject<GetUsers$Response>(null);

  private _serviceData: UserServiceData = new UserServiceData();
  private managersList: ManagerListRow[] = [];
  private managersSubject: Subject<GetManagersList$Response> = new ReplaySubject<GetManagersList$Response>();
  private managerMetadata: ApiResponseMetadata = new ApiResponseMetadata;


  constructor(private api: ApiService, private apiClient: ApiClient) {
    this.pages = {};
    this.initServiceData();

    setInterval(() => {
      this.managersList = [];
      this.managerMetadata = new ApiResponseMetadata;
    }, 900000);
  }

  private doCount(customerId: number, email: string, userType: UserType): boolean {
    return this._serviceData.totalCount === 0
        || this._serviceData.customerId !== customerId
        || this._serviceData.userType !== userType
        || this._serviceData.emailOrName !== email;
  }

  getUsers(customerId: number = null, emailOrName: string = null, userType: UserType = null, orderBy: UserOrderBy,
           order: SortOrder, pageNum: number): Observable<GetUsers$Response> {

    let forceFetch = false;
    if (this._serviceData.customerId !== customerId
        || this._serviceData.emailOrName !== emailOrName
        || this._serviceData.userType !== userType
        || this._serviceData.listOrderBy !== orderBy
        || this._serviceData.listSortOrder !== order) {
      forceFetch = true;
      this.pages = {};
    } else if (!this.pages[pageNum] || this.pages[pageNum].length === 0) {
      forceFetch = true;

    }

    if (forceFetch) {
      this.usersListSubject.unsubscribe();
      this.usersListSubject = new ReplaySubject<GetUsers$Response>(null);
      const cal = this.calculateOffsetAndLimit(pageNum);
      const request: GetUsersRequest = {
        customerId: customerId,
        emailOrName: emailOrName,
        userType: userType ? userType : null,
        orderBy: orderBy ? orderBy : undefined,
        order: order ? order : undefined,
        offset: cal.offset,
        limit: cal.limit,
        count: this.doCount(customerId, emailOrName, userType)
      };

      this.api.users.getUsers(request).subscribe(res => {
        if (res && res.data) {
          this.pages[pageNum] = res.data;
        }
        if (res && res.metadata) {
          this.metadata.limit = res.metadata.limit;
          this.metadata.offset = res.metadata.offset;
          if (res.metadata.totalCount != null) {
            this.metadata.totalCount = res.metadata.totalCount;
            this._serviceData.totalCount = res.metadata.totalCount;
          }
        }

        this.usersListSubject.next({
          data: this.pages[pageNum],
          metadata: this.metadata
        });
        this._serviceData.currentPage = pageNum;

      }, (err) => {
        this.usersListSubject.error(err);
      });

    } else {
      this.usersListSubject.next({
        data: this.pages[pageNum],
        metadata: this.metadata
      });
      this._serviceData.currentPage = pageNum;
    }

    this._serviceData.listOrderBy = orderBy;
    this._serviceData.listSortOrder = order;
    this._serviceData.emailOrName = emailOrName;
    this._serviceData.userType = userType;
    this._serviceData.customerId = customerId;

    return this.usersListSubject;
  }

  private calculateOffsetAndLimit(page: number): any {
    const offset = (page - 1) * this._serviceData.rowsPerPage;
    const limit = offset + this._serviceData.rowsPerPage;
    return {offset: offset, limit: limit}
  }

  toggleNotificationEmails(userId: number, value: boolean): Observable<ToggleNotificationEmails$Response> {
    return this.api.users.toggleNotificationEmails({
      userId: userId,
      value: value
    });
  }

  sendInvitation(userId: number): Observable<SendInvitation$Response> {
    return this.api.users.sendInvitation(userId);
  }

  deactivateUser(userId: number): Observable<DeactivateUser$Response> {
    return this.api.users.deactivateUser(userId);
  }

  clean(): void {
    this.pages = {};
    this.metadata.offset = undefined;
    this.metadata.limit = undefined;
    this.metadata.totalCount = undefined;
    this.initServiceData();
  }

  initServiceData(): void {
    this._serviceData.listOrderBy = UserOrderByValues.email;
    this._serviceData.listSortOrder = SortOrderValues.ASC;
    this._serviceData.customerId = null;
    this._serviceData.emailOrName = null;
    this._serviceData.userType = '';
    this._serviceData.totalCount = 0;
    this._serviceData.rowsPerPage = 25;
    this._serviceData.currentPage = 1;
  }

  getServiceData(): UserServiceData {
    return this._serviceData;
  }

  pullUsersFromAxapta(): Observable<PullUsersFromAxo$Response> {
    return this.api.users.pullUsersFromAxo();
  }

  getLastPullData(): Observable<GetLastUsersFetch$Response> {
    return this.api.users.getLastUsersFetch();
  }

  getManagers(forceFetch: boolean = false): Observable<GetManagersList$Response> {
    if (!this.managersList.length || forceFetch) {
      this.managersSubject = new ReplaySubject<GetManagersList$Response>(null);
      this.api.users.getManagersList().subscribe(res => {
        if (res && res.data) {
          this.managersList = res.data;
          this.metadata = res.metadata;
          this.managersSubject.next({
            data: this.managersList,
            metadata: this.managerMetadata
          });
        }
      }, error => {
        this.managersSubject.error(error);
      });
    } else {
      this.managersSubject.next({
        data: this.managersList,
        metadata: this.managerMetadata
      })
    }
    return this.managersSubject;
  }

  activateUser(userId: number): Observable<ActivateUser$Response> {
    return this.api.users.activateUser(userId);
  }

  saveAdUsername(request: AdUserUpdate): Observable<UpdateAdUsername$Response> {
    return this.api.users.updateAdUsername(request);
  }

  getUserViewData(userId: number): Observable<GetUserViewData$Response> {
    return this.api.users.getUserViewData(userId);
  }

  saveNotifications(request: SaveNotificationsRequest): Observable<SaveUserNotifications$Response> {
    return this.api.users.saveUserNotifications(request)
  }

  toggleAdmin(request: ToggleAdminRequest): Observable<ToggleAdmin$Response> {
    return this.api.users.toggleAdmin(request);
  }

  loginAsUser(userId: number): Observable<LoginAsUser$Response> {
    return this.api.users.loginAsUser(userId);
  }
}
