<div class="row">
  <div class="col-xs-6 col-xs-offset-3 panel-login">
    <h3 translate>auth.passwordRecovery.header</h3>

    <form novalidate autocomplete="off" [formGroup]="resetPasswordForm">
      <div class="form-group">
        <p>{{username}}</p>
      </div>
      <div class="form-group">
        <input formControlName="password1" autocomplete="false" class="form-control"
               type="password" placeholder="{{'auth.passwordRecovery.password' | translate }}">
        <control-message [errors]="resetPasswordFormErrors"
                         fieldName="password1"
                         position="bottom"
                         color="red"
                         cssClass="">
        </control-message>
      </div>
      <div class="form-group">
        <input formControlName="password2" autocomplete="false" class="form-control"
               type="password" placeholder="{{'auth.passwordRecovery.repeatPassword' | translate }}">
        <control-message [errors]="resetPasswordFormErrors"
                         fieldName="password2"
                         position="bottom"
                         color="red"
                         cssClass="">
        </control-message>
      </div>
      <div class="form-group">
        <button [disabled]="resetPasswordForm.touched && !resetPasswordForm.valid" type="submit"
                class="btn btn-bd-default" (click)="resetPassword($event)"
                translate>auth.passwordRecovery.save
        </button>
      </div>
    </form>
  </div>
</div>
