import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorService} from './error-service';
import {environment} from '../../environments/environment';
import {environment as avi} from '../../environments/app-version';
import {Lang} from './api-types';
import {NotificationService} from '../shared/notification/notification.service';
import {Notification} from '../shared/notification/notification.model';
import {Observable} from 'rxjs';
import {catchError, map, take, tap} from 'rxjs/operators';
import {FileUploadResponse} from '../custom-interfaces';
import {HttpResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class ApiClient {

  public headers: any = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0'
  };
  private versionNotification: Notification;
  private UID_HASH: string;

  constructor(private http: HttpClient,
              private errorService: ErrorService,
              private notificationService: NotificationService) {}

  public execute(method: string, url: string, body: any): Observable<any> {
    const headers = {...this.headers};
    this.addAuthorizationHeader(headers);
    if (method === 'GET') {
      return this.http.get(environment.ABSOLUTE_PATH + url, {headers: headers, observe: 'response', params: this.createHttpParams(body)})
      .pipe(
          tap(res => this.checkApiVersion(res)),
          map(res => res.body),
          tap(res => this.checkForEmptyResponse(res)),
          catchError(err => this.errorService.httpError(url, err))
      );

    } else if (method === 'POST') {
      return this.http.post(environment.ABSOLUTE_PATH + url, body, {headers: headers, observe: 'response'})
      .pipe(
          tap(res => this.checkApiVersion(res)),
          map(res => res.body),
          tap(res => this.checkForEmptyResponse(res)),
          catchError(err => this.errorService.httpError(url, err))
      );
    } else if (method === 'PUT') {
      return this.http.put(environment.ABSOLUTE_PATH + url, body, {headers: headers, observe: 'response'})
      .pipe(
          tap(res => this.checkApiVersion(res)),
          map(res => res.body),
          tap(res => this.checkForEmptyResponse(res)),
          catchError(err => this.errorService.httpError(url, err))
      );
    }
  }

  public getImage(url: string, reportProgress?: boolean | false): Observable<any> {
    const headers = {...this.headers};
    this.addAuthorizationHeader(headers);
    headers['Content-Type'] = 'image';
    headers['Accept'] = '*';
    headers['Access-Control-Allow-Headers'] = 'Content-Length';
    headers['Access-Control-Expose-Headers'] = 'Content-Length';
    return this.http.get(environment.ABSOLUTE_PATH + url,
        {headers: headers, observe: 'events', responseType: 'blob', reportProgress: reportProgress});
  }

  public getFile(url: string, reportProgress?: boolean | false): Observable<any> {
    const headers = {...this.headers};
    this.addAuthorizationHeader(headers);
    headers['Content-Type'] = 'blob';
    headers['Accept'] = '*';
    headers['Access-Control-Allow-Headers'] = 'Content-Length';
    headers['Access-Control-Expose-Headers'] = 'Content-Length';
    if (reportProgress) {
      return this.http.get(environment.ABSOLUTE_PATH + url,
          {headers: headers, observe: 'events', responseType: 'blob', reportProgress: true});
    } else {
      return this.http.get(environment.ABSOLUTE_PATH + url,
          {headers: headers, observe: 'response', responseType: 'blob', reportProgress: false});
    }
  }

  public getTextFile(url: string, reportProgress?: boolean | false): Observable<any> {
    const headers = {...this.headers};
    this.addAuthorizationHeader(headers);
    headers['Content-Type'] = 'blob';
    headers['Accept'] = '*';
    headers['Access-Control-Allow-Headers'] = 'Content-Length';
    headers['Access-Control-Expose-Headers'] = 'Content-Length';
    return this.http.get(environment.ABSOLUTE_PATH + url,
        {headers: headers, observe: 'events', responseType: 'text', reportProgress: reportProgress});
  }

  public exportInventory(url: string): Observable<HttpResponse<Blob>> {
    const headers = {...this.headers};
    this.addAuthorizationHeader(headers);
    headers['Accept'] = '*';
    headers['Access-Control-Allow-Headers'] = 'Content-Length';
    headers['Access-Control-Expose-Headers'] = 'Content-Length';
    return this.http.get(
        environment.ABSOLUTE_PATH + 'api/inventory/download' + url,
        {headers: headers, observe: 'response', responseType: 'blob'}
    );
  }

  public getTerms(lang: Lang): Observable<HttpResponse<Blob>> {
    const headers = {...this.headers};
    this.addAuthorizationHeader(headers);
    const url = 'api/order/terms/' + lang;
    return this.http.get(
        environment.ABSOLUTE_PATH + url,
        {headers: headers, observe: 'response', responseType: 'blob'}
    ).pipe(
        catchError(err => this.errorService.httpError(url, err))
    );
  }

  uploadFile(file: File): Observable<FileUploadResponse> {
    const url = 'api/file/upload';
    const headers = {
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Origin': '*',
      'Accept': '*',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    };
    this.addAuthorizationHeader(headers);
    const formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);
    return this.http.post(environment.ABSOLUTE_PATH + url, formData, {headers})
    .pipe(
        catchError(err => this.errorService.httpError(url, err)),
        map((r: FileUploadResponse) => r)
    );
  }

  private addAuthorizationHeader(headers: {}): void {
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {
      headers['Authorization'] = 'Bearer ' + jwtToken;
    } else {
      headers['Authorization'] = '';
    }
  }

  private checkApiVersion(res) {
    if (res && res.headers) {
      const feVersion = avi.APP_VERSION;
      const appVersion = (<HttpHeaders>res.headers).get('app-version');
      if (!!feVersion && !!appVersion && feVersion !== appVersion && !this.versionNotification) {
        this.versionNotification = this.notificationService.newApiVersionNotification();
        this.versionNotification.onHide.pipe(take(1)).subscribe(() => {
          this.versionNotification = undefined;
        });
      }
    }
    this.checkUserId(res);
  }

  private createHttpParams(paramsBody: any): HttpParams {
    if (!paramsBody) return undefined;
    let params: HttpParams = new HttpParams;
    Object.keys(paramsBody)
      .filter(key => !!paramsBody[key])
      .forEach(key => (params = params.append(key, paramsBody[key])));
    return params;
  }

  private checkUserId(res) {
    if (res && res.headers) {
      const hash = (<Headers>res.headers).get('uid-hash');
      if (hash) {
        if (this.UID_HASH !== undefined) {
          if (this.UID_HASH !== hash) {
            window.location.reload();
            return;
          }
        } else {
          this.UID_HASH = hash;
        }
      }
    }
  }

  private checkForEmptyResponse(res: any) {
    if (!res.data || Object.keys(res.data).length === 0 && res.data.constructor === Object) {
      return;
    }
    if (typeof res.data === 'boolean' && res.data === false) {
      throw false;
    } else if (typeof res.data === 'object' && (res.data == null || res.data === undefined)) {
      throw false;
    }
  }

  public clean() {
    this.UID_HASH = undefined;
  }
}
