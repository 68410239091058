import {Injectable, Injector} from '@angular/core';
import {KeyVal, OnClean} from '../custom-interfaces';
import {Paginator} from '../api/paginator';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {CallOffStorageType, InventoryFilterData, InventoryListCallOffRow} from '../common/inventory';
import {ApiClient} from '../api/api-client';
import {CALL_OFF_STORAGE_NAME, LocalStorageService} from '../common/localStorage.service';
import {AuthService} from './auth.service';
import {ApiService} from '../api/api-service';
import {instantToDate, toDateString} from '../common/DateUtil';
import {requestParamsToUrl} from '../common/ApiUtil';
import {ApiError, GetInventory$Response, SortOrderValues} from '../api/api-types';
import {take} from 'rxjs/operators';

@Injectable()
export class InventoryService implements OnClean {

  public callOffForm: FormGroup;
  public paginator: Paginator = new Paginator();
  public inventoryFilterData: InventoryFilterData = new InventoryFilterData();
  public inventoryLoadedEvent: Subject<any> = new ReplaySubject();

  private _inventoryList: InventoryListCallOffRow[] = [];
  private subscriptions = new Subscription();

  constructor(
      private fb: FormBuilder,
      private injector: Injector,
      private apiClient: ApiClient,
      private apiService: ApiService,
      private authService: AuthService,
      private localStorageService: LocalStorageService
  ) {
  }

  public find(): void {
    const data = this.inventoryFilterData;
    const params = [
      new KeyVal('customer-id', data.customerId),
      new KeyVal('from-date', data.startDate ? toDateString(data.startDate) : null),
      new KeyVal('to-date', data.endDate ? toDateString(data.endDate) : null),
      new KeyVal('include-settled', data.includeClosed),
    ];

    this.paginator.reset();
    this.paginator.isLoading = true;
    const reqParams = params
    .concat(this.paginator.dataAsKeyVal)
    .concat(data.inventorySortOrder.toKeyVal());

    this.apiService.inventory.getInventory(requestParamsToUrl(reqParams)).pipe(take(1)).subscribe((res: GetInventory$Response) => {
      this._inventoryList = res.data.map((r) => {
        return {...r, callOffQuantity: undefined, callOffDate: undefined};
      });
      this.filterAndSort();
      this.inventoryLoadedEvent.next();
    }, (error: ApiError) => {
      // error
    });
  }

  public download(type: string): Observable<any> {
    const data = this.inventoryFilterData;
    const params = [
      new KeyVal('customer-id', data.customerId),
      new KeyVal('from-date', data.startDate ? instantToDate(data.startDate) : null),
      new KeyVal('to-date', data.endDate ? instantToDate(data.endDate) : null),
      new KeyVal('include-settled', data.includeClosed),
      new KeyVal('file-type', type),
    ];
    return this.apiClient.exportInventory(requestParamsToUrl(params));
  }

  public filterAndSort(): void {
    const order = this.inventoryFilterData.inventorySortOrder._order;
    const direction = this.inventoryFilterData.inventorySortOrder._direction;
    let list = this._inventoryList.slice();
    list = this.filter(list);

    if (!order || !direction) {
      // maintain natural order
    } else if (SortOrderValues.ASC === direction) {
      list = list.sort((n1, n2) => {
        if (n1[order] > n2[order]) {
          return 1;
        } else if (n1[order] < n2[order]) {
          return -1;
        }
        return 0;
      });
    } else if (SortOrderValues.DESC === direction) {
      list = list.sort((n1, n2) => {
        if (n1[order] < n2[order]) {
          return 1;
        } else if (n1[order] > n2[order]) {
          return -1;
        }
        return 0;
      });
    }
    this.paginator.addPages(list);
    this.paginator.isLoading = false;
  }

  public filter(list: InventoryListCallOffRow[]): InventoryListCallOffRow[] {
    const filter = !this.inventoryFilterData.filter ? '' : this.inventoryFilterData.filter;
    return list.filter((row) => {
      return this.includes(row.productName, filter) || this.includes(row.productNumber, filter) || this.includes(row.salesOrderId, filter)
          || this.includes(row.externalItemId, filter) || this.includes(row.purchOrderFormNum, filter);
    });
  }

  public initializeAndFillCallOffForm() {
    this.callOffForm = this.fb.group({
      products: this.fb.array([]),
      orderNumber: ['', Validators.maxLength(25)],
      comment: ['', Validators.maxLength(1000)]
    });

    const storageCallOff = this.localStorageService.getJsonFromStorage(CALL_OFF_STORAGE_NAME);
    if (!!storageCallOff) {
      try {
        const callOffObj = storageCallOff as CallOffStorageType;
        if (callOffObj.userId != this.authService.userId) {
          this.localStorageService.putIntoStorage(CALL_OFF_STORAGE_NAME, undefined);
          return;
        }
        this.preFillForm(callOffObj);
      } catch (e) {
        console.error(e);
      }
    }

    const subs = this.callOffForm.valueChanges.subscribe(() => {
      const callOff = this.callOffForm.getRawValue() as CallOffStorageType;
      callOff.userId = this.authService.userId;
      this.localStorageService.putJsonIntoStorage(CALL_OFF_STORAGE_NAME, callOff);
    });
    this.subscriptions.add(subs);
  }

  public get productsArray(): FormArray {
    return (this.callOffForm.controls.products as FormArray);
  }

  public removeAllProducts() {
    while (this.productsArray.length !== 0) {
      this.productsArray.removeAt(0);
    }
  }

  public getCallOffProductRowToFormGroup(row: InventoryListCallOffRow) {
    // const prevCalloff = row.callOffRows === undefined || row.callOffRows.length === 0 ?
    //     0 : row.callOffRows.reduce((a, b) => a + b.orderedQuantity, 0);
    return this.fb.group({
      index: [row.index],
      salesOrderId: [row.salesOrderId],
      purchOrderFormNum: [row.purchOrderFormNum],
      productName: [row.productName],
      productNumber: [row.productNumber],
      externalItemId: [row.externalItemId],
      deliveryDate: [row.deliveryDate],
      orderedQuantity: [row.orderedQuantity],
      deliveredQuantity: [row.deliveredQuantity],
      quantityInWarehouse: [row.quantityInWarehouse],
      unit: [row.unit],
      inventTransId: [row.inventTransId],
      lineNum: [row.lineNum],
      callOffDate: [row.callOffDate ? new Date(row.callOffDate) : undefined, [Validators.required]],
      callOffQuantity: [row.callOffQuantity, [
        Validators.required,
        Validators.min(1),
        Validators.max(Math.max(row.orderedQuantity, row.quantityInWarehouse))
      ]]
    });
  }

  private preFillForm(callOff: CallOffStorageType) {
    this.callOffForm.controls.orderNumber.setValue(callOff.orderNumber, {onlySelf: true});
    this.callOffForm.controls.comment.setValue(callOff.comment, {onlySelf: true});
    this.addProductsToForm(callOff.products);
  }

  private addProductsToForm(products: InventoryListCallOffRow[]) {
    products.forEach((r) => {
      this.productsArray.push(this.getCallOffProductRowToFormGroup(r));
    });
  }

  private includes(x: string, filter: string): boolean {
    return (x ? x.toLocaleLowerCase() : '').includes((filter ? filter.toLocaleLowerCase() : ''));
  }

  clean(): void {
    this.subscriptions ? this.subscriptions.unsubscribe() : null;
    this.paginator.reset();
    this.inventoryFilterData = new InventoryFilterData();
  }

}
