import {Pipe, PipeTransform} from '@angular/core';
import {NGSP_UNICODE} from '@angular/compiler';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: any, format: string = '###.##'): any {
    if (value == null || value === undefined) {
      return '';
    }

    const formatDot = format.indexOf('.');
    const numOfDecimals = formatDot !== -1 ? format.substr(formatDot + 1).length : 0;
    let val = value.toString();
    if (numOfDecimals > 0 && !isNaN(val)) {
      val = Number.parseFloat(val).toFixed(numOfDecimals);
      return val.replace(/\B(?=(\d{3})+(?!\d).)/g, ' ');
    }
    if (numOfDecimals === 0) {
      if (val.indexOf('.') !== -1) {

        const float = parseFloat(val);
        const roundFloat = Math.round(float);
        val = roundFloat.toString()

      }
      return val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    }
  }

}
