import {setTheme} from 'ngx-bootstrap/utils';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {enGbLocale} from 'ngx-bootstrap/locale';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {etLocale} from './shared/datepicker-locale/et';
import {AppService} from './services/app.service';

defineLocale('et', etLocale);
defineLocale('engb', enGbLocale);

@Component({
  selector: 'app',
  template: `
    <header id="app-header"></header>
    <app-notification></app-notification>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
    <footer></footer>`,
  styleUrls: ['app.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(private appService: AppService) {
    setTheme('bs3');
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
