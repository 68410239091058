import {Pipe, PipeTransform} from '@angular/core';
import {toTimeZone} from '../../common/DateUtil';
import moment from 'moment';

const FORMAT = 'DD/MM/YYYY HH:mm:ss';

@Pipe({
  name: 'toTimestamp'
})
export class ToTimestampPipe implements PipeTransform {
  transform(timestamp: string, parseFormat: string = 'YYYY-MM-DD HH:mm:ss:ZZ'): string {
    return !!timestamp ? moment(timestamp, parseFormat).tz('Europe/Tallinn').format(FORMAT) : '';
    // return !!timestamp ? toTimeZone(moment(timestamp)).utc().format(FORMAT) : '';
  }
}
