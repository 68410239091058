/*
 * Method to clean data stored in the service.
 * Will be called on logout to destroy all data related to the user.
 * */
import {
  ActiveFilter,
  ComplaintOrderBy,
  DesignOrderBy,
  MailTemplateOrderBy,
  ProductOrderBy,
  PurchaseOrderOrderBy,
  SortOrder,
  UserOrderBy
} from './api/api-types';

export interface OnClean {
  clean(): void;
}

export declare interface FieldError {
  errorCode: string;
  parameter: any;
}

export declare interface FieldErrors {
  [errorCode: string]: FieldError;
}

export declare interface FormValidationErrors {
  [field: string]: FieldErrors;
}

export class OrderChange {
  propertyName: string;
  order: SortOrder;
}

export class MailTemplateServiceData {
  listOrderBy: MailTemplateOrderBy;
  listSortOrder: SortOrder;
  totalCount: number;
  rowsPerPage: number;
  currentPage: number;
}

export class UserServiceData {
  listOrderBy: UserOrderBy;
  listSortOrder: SortOrder;
  customerId: number;
  emailOrName: string;
  userType: string;
  totalCount: number;
  rowsPerPage: number;
  currentPage: number;
}

export class DesignServiceData {
  listOrderBy: DesignOrderBy;
  listSortOrder: SortOrder;
  designName: string;
  designEan: string;
  designId: string;
  customerId: number;
  status: string;
  totalCount: number;
  rowsPerPage: number;
  currentPage: number;
  showAll: boolean;
}

export class ComplaintsServiceData {
  listOrderBy: ComplaintOrderBy;
  listSortOrder: SortOrder;
  customerId: number;
  complaintNumber: string;
  purchaseOrderNumber: string;
  managerId: number;
  status: string;
  totalCount: number;
  rowsPerPage: number;
  currentPage: number;
  showAll: boolean;
}

export class InventoryServiceData {
  listOrderBy: string;
  listSortOrder: SortOrder;
  customerId: number;
  startDate: Date;
  endDate: Date;
  includeClosed: boolean;
  showAll: boolean;
}

export type InventoryOrderBy =
    'salesOrderId'
    | 'productName'
    | 'productNumber'
    | 'deliveryDate'
    | 'orderedQuantity'
    | 'deliveredQuantity'
    | 'quantityInWarehouse';
export namespace InventoryOrderByValues {
  export const salesOrderNo: InventoryOrderBy = 'salesOrderId';
  export const productName: InventoryOrderBy = 'productName';
  export const productNo: InventoryOrderBy = 'productNumber';
  export const deliveryDate: InventoryOrderBy = 'deliveryDate';
  export const orderedQuantity: InventoryOrderBy = 'orderedQuantity';
  export const deliveredQuantity: InventoryOrderBy = 'deliveredQuantity';
  export const quantityInWarehouse: InventoryOrderBy = 'quantityInWarehouse';
}

export class DesignEditComment {
  designId: number;
  commentId: number;
  imageLayer: any;
  imageLayerData: string;
  drawLayer: any;
  drawLayerData: string;
  comment: string;
  isMyComment: boolean;
  hasImage: boolean;
}

export class PurchasesServiceData {
  listOrderBy: PurchaseOrderOrderBy;
  listSortOrder: SortOrder;
  purchaseOrderNum: string;
  customerId: number;
  status: string;
  totalCount: number;
  rowsPerPage: number;
  currentPage: number;
  selectedCustomerId: number;
  showAll: boolean;
  myOrders: boolean;
}

export class ProductsServiceData {
  // Fields for list
  listOrderBy: ProductOrderBy;
  listSortOrder: SortOrder;
  totalCount: number;
  rowsPerPage: number;
  currentPage: number;

  // List content fields
  customerId: number;
  name: string;
  code: string;
  activeFilter: ActiveFilter;
}

export class FileUploadResponse {
  fileName: string;
  filePath: string;
}

export class KeyVal {
  key: string;
  value: any;

  constructor(key: string, value: any) {
    this.key = key;
    this.value = value;
  }
}

export class ListSortOrder {
  _order: string;
  _direction: SortOrder;

  constructor(order: string, direction: SortOrder) {
    this._order = order;
    this._direction = direction;
  }

  public toKeyVal(): KeyVal[] {
    return [
      new KeyVal('_order', this._order),
      new KeyVal('_direction', this._direction)
    ];
  }
}

export interface PaginatorData {
  _offset?: number;
  _limit?: number;
}