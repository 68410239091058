import { OnDestroy } from '@angular/core';
import { ApiService } from '../api/api-service';
import { ApiClient } from '../api/api-client';
import { UsersService } from './users.service';
import { ErrorService } from '../api/error-service';
import { CallOffService } from './call-off.service';
import { Router } from '@angular/router';
import { DesignsService } from './designs.service';
import { AddressService } from './address.service';
import { ProductService } from './product.service';
import { CustomerService } from './customer.service';
import { InventoryService } from './inventory.service';
import { ComplaintsService } from './complaints.service';
import { MailTemplateService } from './mail-template.service';
import { LocalStorageService } from '../common/localStorage.service';
import { NotificationService } from '../shared/notification/notification.service';
import { PurchaseOrderService } from './purchase-order.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { BsLocaleService } from 'ngx-bootstrap';
import { defineLocale } from 'ngx-bootstrap/chronos';
import * as locales from 'ngx-bootstrap/locale';
import { StandardProductsService } from './standard-products.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../api/api-service";
import * as i3 from "../api/api-client";
import * as i4 from "./auth.service";
import * as i5 from "./users.service";
import * as i6 from "../api/error-service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "ngx-bootstrap/datepicker";
import * as i9 from "./call-off.service";
import * as i10 from "./designs.service";
import * as i11 from "./address.service";
import * as i12 from "./product.service";
import * as i13 from "./customer.service";
import * as i14 from "./inventory.service";
import * as i15 from "./complaints.service";
import * as i16 from "./mail-template.service";
import * as i17 from "../common/localStorage.service";
import * as i18 from "../shared/notification/notification.service";
import * as i19 from "./purchase-order.service";
import * as i20 from "./standard-products.service";
var AppService = /** @class */ (function () {
    function AppService(router, api, apiClient, authService, usersService, errorService, translate, localeService, callOffService, designsService, addressService, productService, customerService, inventoryService, complaintService, templatesService, localStorageService, notificationService, purchaseOrderService, standardProductsService) {
        var _this = this;
        this.router = router;
        this.api = api;
        this.apiClient = apiClient;
        this.authService = authService;
        this.usersService = usersService;
        this.errorService = errorService;
        this.translate = translate;
        this.localeService = localeService;
        this.callOffService = callOffService;
        this.designsService = designsService;
        this.addressService = addressService;
        this.productService = productService;
        this.customerService = customerService;
        this.inventoryService = inventoryService;
        this.complaintService = complaintService;
        this.templatesService = templatesService;
        this.localStorageService = localStorageService;
        this.notificationService = notificationService;
        this.purchaseOrderService = purchaseOrderService;
        this.standardProductsService = standardProductsService;
        this.subscriptions = new Subscription;
        this.errorService.logOut.subscribe(function () {
            _this.clean();
        });
        this.importLocales();
    }
    AppService.prototype.ngOnDestroy = function () {
        this.subscriptions ? this.subscriptions.unsubscribe() : null;
    };
    AppService.prototype.clean = function () {
        this.apiClient.clean();
        this.authService.clean();
        this.usersService.clean();
        this.callOffService.clean();
        this.designsService.clean();
        this.addressService.clean();
        this.productService.clean();
        this.customerService.clean();
        this.inventoryService.clean();
        this.complaintService.clean();
        this.templatesService.clean();
        this.localStorageService.clean();
        this.notificationService.clean();
        this.purchaseOrderService.clean();
        this.standardProductsService.clean();
    };
    AppService.prototype.importLocales = function () {
        for (var locale in locales) {
            defineLocale(locales[locale].abbr, locales[locale]);
        }
    };
    AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ApiService), i0.ɵɵinject(i3.ApiClient), i0.ɵɵinject(i4.AuthService), i0.ɵɵinject(i5.UsersService), i0.ɵɵinject(i6.ErrorService), i0.ɵɵinject(i7.TranslateService), i0.ɵɵinject(i8.BsLocaleService), i0.ɵɵinject(i9.CallOffService), i0.ɵɵinject(i10.DesignsService), i0.ɵɵinject(i11.AddressService), i0.ɵɵinject(i12.ProductService), i0.ɵɵinject(i13.CustomerService), i0.ɵɵinject(i14.InventoryService), i0.ɵɵinject(i15.ComplaintsService), i0.ɵɵinject(i16.MailTemplateService), i0.ɵɵinject(i17.LocalStorageService), i0.ɵɵinject(i18.NotificationService), i0.ɵɵinject(i19.PurchaseOrderService), i0.ɵɵinject(i20.StandardProductsService)); }, token: AppService, providedIn: "root" });
    return AppService;
}());
export { AppService };
