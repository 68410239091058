import {Injectable} from '@angular/core';
import {ApiService} from '../api/api-service';
import {
  AddressListRow,
  ApiError,
  GetCustomerAddresses$Response,
  SaveAddress$Response,
  SaveAddressForm,
  UseAddress$Response
} from '../api/api-types';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {OnClean} from '../custom-interfaces';


@Injectable()
export class AddressService implements OnClean {

  private customerAddresses: { [key: number]: AddressListRow[] };
  private customerAddressesSubject: Subject<GetCustomerAddresses$Response> = new ReplaySubject<GetCustomerAddresses$Response>(null);

  constructor(private api: ApiService) {
    this.clean();
    setTimeout(() => {
      this.clean();
    }, 900000); // 15min
  }

  public getCustomerAddresses(customerId: number, force: boolean = false): Observable<GetCustomerAddresses$Response> {
    if (force || !this.customerAddresses[customerId] || this.customerAddresses[customerId].length === 0) {
      this.customerAddressesSubject.unsubscribe();
      this.customerAddressesSubject = new ReplaySubject<GetCustomerAddresses$Response>(null);

      this.api.address.getCustomerAddresses(customerId).subscribe(res => {
        if (res && res.data) {
          this.customerAddresses[customerId] = res.data;
          this.customerAddressesSubject.next({
            data: res.data,
            metadata: res.metadata
          });
        }
      }, (err: ApiError) => {
        this.customerAddressesSubject.error(err);
      });
    } else {
      this.customerAddressesSubject.next({
        data: this.customerAddresses[customerId],
        metadata: null
      });
    }
    return this.customerAddressesSubject;
  }

  public useAddress(customerId: number, addressId: number): Observable<UseAddress$Response> {
    return this.api.address.useAddress(customerId, addressId);
  }

  public saveAddress(address: SaveAddressForm): Observable<SaveAddress$Response> {
    return this.api.address.saveAddress(address);
  }

  clean(): void {
    this.customerAddresses = {};
  }

}
