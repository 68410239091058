import {OnClean} from '../custom-interfaces';
import {Injectable} from '@angular/core';

export const CALL_OFF_STORAGE_NAME = 'CALL-OFF';

@Injectable()
export class LocalStorageService implements OnClean {

  public getFromStorage(key: string) {
    return this.localStorage.getItem(key);
  }

  public getJsonFromStorage(key: string) {
    const val = this.getFromStorage(key);
    try {
      return !!val ? JSON.parse(val) : undefined;
    } catch (e) {
      console.log(e);
    }
    return undefined;
  }

  public putIntoStorage(key: string, value: string) {
    this.localStorage.setItem(key, value);
  }

  public putJsonIntoStorage(key: string, object: any) {
    this.putIntoStorage(key, !!object ? JSON.stringify(object) : "");
  }

  clean(): void {
    this.localStorage.removeItem(CALL_OFF_STORAGE_NAME);
  }

  private get localStorage() {
    return window.localStorage;
  }
}
