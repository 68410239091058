import {Injectable} from '@angular/core';
import {ApiService} from '../api/api-service';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {
  ApiResponseMetadata,
  CustomerListRow, CustomerTypes, GetAvailableStandardProductsWithUnits$Response,
  GetCustomerData$Response,
  GetCustomersList$Response, GetCustomerTypes$Response,
  GetRepresentativeCustomersList$Response
} from '../api/api-types';
import {OnClean} from '../custom-interfaces';
import {map} from 'rxjs/operators';

export interface CustomerListData {
  list: CustomerListRow[];
  metadata: ApiResponseMetadata;
}

@Injectable()
export class CustomerService implements OnClean {

  private customers: CustomerListData;
  private reprCustomers: CustomerListData;
  private customersListSubject: Subject<GetCustomersList$Response> = new ReplaySubject<GetCustomersList$Response>(null);
  private reprCustomersListSubject: Subject<GetRepresentativeCustomersList$Response>
      = new ReplaySubject<GetRepresentativeCustomersList$Response>(null);

  constructor(private api: ApiService) {
    // refresh customers list every 5min = 300000ms
    setInterval(() => {
      this.clean();
    }, 300000);
  }

  public getCustomersList(forceFetch: boolean = false): Observable<GetCustomersList$Response> {
    if (!this.customers || !this.customers.list || forceFetch) {
      this.customersListSubject.unsubscribe();
      this.customersListSubject = new ReplaySubject<GetCustomersList$Response>(null);
      this.api.customer.getCustomersList().subscribe(res => {
        if (res && res.data) {
          this.customers = {
            list: res.data,
            metadata: res.metadata
          };
          this.customersListSubject.next({
            data: this.customers.list,
            metadata: this.customers.metadata
          });
        }
      }, (err) => {
        this.customersListSubject.error(err);
      });
    } else {
      this.customersListSubject.next({
        data: this.customers.list,
        metadata: this.customers.metadata
      });
    }

    return this.customersListSubject;
  }

  public getRepresentativeCustomersList(forceFetch: boolean = false): Observable<GetRepresentativeCustomersList$Response> {
    if (!this.reprCustomers || !this.reprCustomers.list || forceFetch) {
      this.reprCustomersListSubject.unsubscribe();
      this.reprCustomersListSubject = new ReplaySubject<GetRepresentativeCustomersList$Response>(null);
      this.api.customer.getRepresentativeCustomersList().subscribe(res => {
        if (res && res.data) {
          this.reprCustomers = {
            list: res.data,
            metadata: res.metadata
          };
          this.reprCustomersListSubject.next({
            data: this.reprCustomers.list,
            metadata: this.reprCustomers.metadata
          });
        }
      }, (err) => {
        this.customersListSubject.error(err);
      })
    } else {
      this.customersListSubject.next({
        data: this.reprCustomers.list,
        metadata: this.reprCustomers.metadata
      });
    }

    return this.reprCustomersListSubject;
  }

  public getCustomers(isAdmin: boolean): Observable<CustomerListRow[]> {
    return isAdmin ? this.getCustomersList().pipe(map((r) => r.data)) : this.getMyCustomersList();
  }

  public getMyCustomersList(): Observable<CustomerListRow[]> {
    return this.getCustomersList().pipe(map((r) => r.data.filter((e) => !!e.myCustomer)));
  }

  getCustomerData(customerId: number): Observable<GetCustomerData$Response> {
    return this.api.customer.getCustomerData(customerId);
  }

  getCustomerTypes(customerId: number): Observable<GetCustomerTypes$Response> {
    return this.api.customer.getCustomerTypes(customerId);
  }

  updateCustomerTypes(customerTypes: CustomerTypes) {
    return this.api.customer.saveCustomerType(customerTypes);
  }


  clean(): void {
    this.customers = undefined;
    this.reprCustomers = undefined;
  }
}
