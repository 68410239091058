import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {ModalModule} from 'ngx-bootstrap';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InvitationComponent} from './invitation/invitation.component';
import {LoadingModule} from 'ngx-loading';
import {PasswordRecoveryComponent} from './password-recovery/password-recovery.component';
import {AuthService} from '../services/auth.service';
import {AuthGuard} from '../services/auth-guard.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingModule,
    RouterModule.forChild([
      {
        path: 'invitation',
        component: InvitationComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'resetpassword',
        component: PasswordRecoveryComponent
      }
    ]),
    SharedModule,
    ModalModule.forRoot()
  ],
  declarations: [
    LoginComponent,
    InvitationComponent,
    PasswordRecoveryComponent
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthService,
    AuthGuard
  ]
})

export class AuthModule {
  constructor() {
  }
}
