<div class="row">
  <div class="col-xs-6 col-xs-offset-3 panel-login">
    <h3 translate>auth.invitation.setPassword</h3>

    <form [formGroup]="invitationForm" autocomplete="off">
      <p>{{username}}</p>
      <div class="form-group">
        <input formControlName="password1" id="pwd1" class="form-control" type="password" autocomplete="off"
               placeholder="{{ 'auth.invitation.password' | translate }}">
        <control-message [errors]="formErrors" fieldName="password1" position="bottom" color="red">
        </control-message>
      </div>
      <div class="form-group">
        <input formControlName="password2" id="pwd2" class="form-control" type="password" autocomplete="off"
               placeholder="{{ 'auth.invitation.repeatPassword' | translate }}">
        <control-message [errors]="formErrors" fieldName="password2" position="bottom" color="red">
        </control-message>
      </div>
      <div class="form-group">
        <input formControlName="agreement" type="checkbox" autocomplete="off">
        <span class="margin-left-5" translate>auth.invitation.agreement.checkboxPart1</span>
        <b><a href="#" (click)="$event.preventDefault();openModal(agreementModal, true)">
          {{'auth.invitation.agreement.checkboxPart2' | translate}}
        </a></b>
        <span translate>auth.invitation.agreement.checkboxPart3</span>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-bd-default" (click)="setPassword()"
                [disabled]="!invitationForm.valid">
          {{'auth.invitation.save' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>

<ng-template #agreementModal>
  <div class="modal-header">
    <h3 class="modal-title pull-left" translate>auth.invitation.agreement.title</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p [innerHtml]="'auth.invitation.agreement.part1' | translate : part1Params"></p>
    <p [innerHtml]="'auth.invitation.agreement.part2' | translate"></p>
    <p [innerHtml]="'auth.invitation.agreement.part3' | translate"></p>
  </div>
</ng-template>

<ng-template #successModal>
  <div class="modal-header">
    <h3 class="modal-title pull-left"></h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeAndNavigate()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p [innerHtml]="'auth.invitation.success.message' | translate"></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-bd-default" (click)="closeAndNavigate()" translate>
      auth.invitation.success.close
    </button>
  </div>
</ng-template>
