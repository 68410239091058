// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return
import { LocaleData } from 'ngx-bootstrap/chronos/locale/locale.class';
import { getWeek } from 'ngx-bootstrap/chronos/units/week';
import { getDayOfWeek } from 'ngx-bootstrap/chronos/units/day-of-week';

//! moment.js locale configuration
//! locale : Estonian [et]
//! author : Vladislav Redkin : https://github.com/vlats05

function processRelativeTime(
    num: number,
    withoutSuffix: boolean,
    key: string,
    isFuture: boolean
): string {
  const str = num.toString();

  const format: any = {
    m: ['eine Minute', 'einer Minute'],
    h: ['eine Stunde', 'einer Stunde'],
    d: ['ein Tag', 'einem Tag'],
    dd: [`${str} Tage`, `${str} Tagen`],
    M: ['ein Monat', 'einem Monat'],
    MM: [`${str} Monate`, `${str} Monaten`],
    y: ['ein Jahr', 'einem Jahr'],
    yy: [`${str} Jahre`, `${str} Jahren`]
  };

  return withoutSuffix ? format[key][0] : format[key][1];
}

export const etLocale: LocaleData = {
  abbr: 'et',
  months : 'Jaanuar_Veebruar_Märts_Aprill_Mai_Juuni_Juuli_August_September_Oktoober_November_Detsember'.split('_'),
  monthsShort : 'Jan_Veb_Mär_Apr_Mai_Jun_Jul_Aug_Sep_Okt_Nov_Dets'.split('_'),
  monthsParseExact: true,
  weekdays : 'Pühapäev_Esmaspäev_Teisipäev_Kolmapäev_Neljapäev_Reede_Laupäev'.split('_'),
  weekdaysShort : 'P_E_T_K_N_R_L'.split('_'),
  weekdaysMin : 'P_E_T_K_N_R_L'.split('_'),
  weekdaysParseExact: true,
  longDateFormat : {
     LT: 'HH:mm',
     LTS: 'HH:mm:ss',
     L: 'DD/MM/YYYY',
     LL: 'MMMM D, YYYY',
     LLL: 'MMMM D, YYYY h:mm A',
     LLLL: 'dddd, MMMM D, YYYY h:mm A'
  },
  calendar : {
     sameDay: '[Täna, kell] LT',
     nextDay: '[Homme, kell] LT',
     nextWeek: 'dddd [at] LT',
     lastDay: '[Yesterday at] LT',
     lastWeek: '[Last] dddd [at] LT',
     sameElse: 'L'
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  // DD/MM/YYYY HH:mm:ss
  ordinal(num: number, token?: string): string {
    return `${num}.`;
  },
  week : {
    dow : 1, // Monday is the first day of the week.
    doy : 4  // The week that contains Jan 4th is the first week of the year.
  }
};