import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiError} from '../../api/api-types';
import {ValidationService} from '../../shared/validation/validation.service';
import {NotificationService} from '../../shared/notification/notification.service';
import {FormValidationErrors} from '../../custom-interfaces';
import {requiredNoSpaceValidator} from '../../shared/validation/required-no-space.validator';
import {ErrorService} from '../../api/error-service';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';

@Component({
  moduleId: module.id,
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  login = true;
  loginForm: FormGroup;
  resetPasswordForm: FormGroup;
  loginFormErrors: FormValidationErrors = {};
  resetPasswordFormErrors: FormValidationErrors = {};
  loginLoading = false;
  resetLoading = false;
  redirectUrl: string;
  isAdmin = false;
  isDesigner = false;

  private subscriptions: Subscription = new Subscription;

  constructor(private router: Router,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private authService: AuthService,
              private errorService: ErrorService,
              private validationService: ValidationService,
              private notificationService: NotificationService) {

    this.loginForm = this.fb.group({
      userName: ['', [requiredNoSpaceValidator, Validators.maxLength(100)]],
      password: ['', [requiredNoSpaceValidator, Validators.maxLength(100)]]
    });

    this.resetPasswordForm = this.fb.group({
      userName: [null, [requiredNoSpaceValidator, Validators.maxLength(100)]]
    });

    this.validationService.addFormErrors(this.loginForm, this.loginFormErrors);
    this.validationService.addFormErrors(this.resetPasswordForm, this.resetPasswordFormErrors);
  }

  ngOnInit(): void {
    this.subscriptions.add(this.authService.whoAmI().subscribe(res => {
      if (res.profile) {
        this.isAdmin = res.profile.isAdmin;
        this.isDesigner = res.profile.isDesigner;
      }

      let page = '/purchase-order';
      if (this.isDesigner) {
        page = '/designs';
      }
      this.redirectUrl = this.route.snapshot.queryParams['redirectUrl'] || page;
    }));
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    const sub = this.authService.whoAmI().subscribe(res => {
      if (res.userId) {
        if (sub) {
          sub.unsubscribe();
        }
        setTimeout(() => {
          this.router.navigate([this.redirectUrl], {});
        });
      }
    });
  }

  authenticate(): void {
    if (this.hasInvalidCredentialsError || this.loginForm.valid) {
      this.loginLoading = true;
      const username = this.loginForm.controls['userName'].value;
      const password = this.loginForm.controls['password'].value;
      this.subscriptions.add(this.authService.authenticate(username, password).subscribe(() => {
        this.loginLoading = false;
        this.router.navigate([this.redirectUrl], {});
      }, (error: ApiError) => {
        this.errorService.handleError(error, '', undefined, this.loginForm, this.loginFormErrors);
        this.loginLoading = false;
      }));
    } else {
      this.validationService.markFormAsDirty(this.loginForm);
    }
  }

  private hasInvalidCredentialsError() {
    return this.loginFormErrors['userName']['auth.login.invalidCredentials'] !== undefined;
  }

  recover() {
    this.login = !this.login;
  }

  sendRecoveryEmail() {
    if (this.resetPasswordForm.valid) {
      this.resetLoading = true;
      this.authService.sendResetPasswordEmail(this.resetPasswordForm.controls['userName'].value).pipe(take(1)).subscribe(res => {
          this.notificationService.success('auth.notifications.passwordResetSucceeded');
          this.login = true;
          this.resetLoading = false;
      }, (error: ApiError) => {
        this.errorService.handleError(error, 'auth.notifications.passwordResetFailed',
            undefined, this.resetPasswordForm, this.resetPasswordFormErrors);
        this.resetLoading = false;
      });
    } else {
      this.validationService.markFormAsDirty(this.resetPasswordForm);
    }
  }

}
