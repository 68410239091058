import {NgModule} from '@angular/core';
import {AppHeaderComponent} from './app-header/app-header.component';
import {AppFooterComponent} from './app-footer/app-footer.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormControlDirective, FormGroupDirective, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToTimestampPipe} from './pipes/timestamp.pipe';
import {NotificationComponent} from './notification/notification.component';
import {ToDatePipe} from './pipes/date.pipe';
import {ControlMessagesComponent} from './validation/control-messages/control-messages.component';
import {TableSorter} from './tablesorter/tablesorter.component';
import {Pager} from './pager/pager.component';
// import {AutocompleteComponent} from './autocomplete/autocomplete.component';
import {TranslateModule} from '@ngx-translate/core';
import {AppToggleButtonComponent} from './app-toggle/app-toggle.component';
import {AppToggleSwitchComponent} from './app-toggle-switch/app-toggle-switch.component';
import {TooltipModule} from 'ngx-bootstrap';
import {LoadingModule} from 'ngx-loading';
import {CanvasComponent} from './canvas-editor/canvas-editor.component';
import {ImageEditorComponent} from './image-editor/image-editor.component';
import {AppAddressAddComponent} from './app-address/app-address-add.component';
import {Autosize} from 'ng-autosize'
import {CanvasColorPickerComponent} from './canvas-color-picker/canvas-color-picker';
import {AppNumberDirective} from './directives/number-directive';
import {NumberFormatPipe} from './pipes/number-format.pipe';
import {UnitPipe} from './pipes/unit.pipe';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {AppScrollButtonComponent} from './app-scroll-button/app-scroll-button.component';
import {Pagination} from './pagination/pagination.component';
import {SortComponent} from './sort/sort.component';
import {NewAutocompleteComponent} from './new-autocomplete/autocomplete.component';
import {LanguageSelectComponent} from './language-select/language-select.component';

@NgModule({
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    LoadingModule,
    TranslateModule,
    TooltipModule.forRoot(),
    AngularSvgIconModule,
  ],
  declarations: [
    AppHeaderComponent,
    AppFooterComponent,
    NotificationComponent,
    ToTimestampPipe,
    ToDatePipe,
    NumberFormatPipe,
    ControlMessagesComponent,
    TableSorter,
    SortComponent,
    Pager,
    Pagination,
    // AutocompleteComponent,
    AppToggleButtonComponent,
    AppToggleSwitchComponent,
    CanvasComponent,
    ImageEditorComponent,
    AppAddressAddComponent,
    Autosize,
    CanvasColorPickerComponent,
    AppNumberDirective,
    UnitPipe,
    AppScrollButtonComponent,
    NewAutocompleteComponent,
    LanguageSelectComponent,
  ],
  exports: [
    AppHeaderComponent,
    AppFooterComponent,
    NotificationComponent,
    ToTimestampPipe,
    ToDatePipe,
    NumberFormatPipe,
    ControlMessagesComponent,
    TableSorter,
    SortComponent,
    Pager,
    Pagination,
    // AutocompleteComponent,
    TranslateModule,
    AppToggleButtonComponent,
    AppToggleSwitchComponent,
    CanvasComponent,
    ImageEditorComponent,
    AppAddressAddComponent,
    Autosize,
    CanvasColorPickerComponent,
    AppNumberDirective,
    UnitPipe,
    AppScrollButtonComponent,
    NewAutocompleteComponent,
    LanguageSelectComponent,
  ],
  providers: [
    FormControlDirective,
    FormGroupDirective,
    ToDatePipe,
    ToTimestampPipe,
    NumberFormatPipe,
    UnitPipe,
  ]
})
export class SharedModule {
  constructor() {
  }
}
