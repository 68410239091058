import {Injectable} from '@angular/core';
import {KeyVal, OnClean} from '../custom-interfaces';
import {
  ApiError,
  ApiResponseMetadata,
  GetAllProductUnits$Response,
  GetProductList$Response,
  GetProductUnitsMap$Response,
  ProductListRow
} from '../api/api-types';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {ApiService} from '../api/api-service';
import {requestParamsToUrl} from '../common/ApiUtil';

@Injectable()
export class ProductService implements OnClean {

  private productList: { [key: number]: ProductListRow[] };
  private metadata = new ApiResponseMetadata();
  private productListSubject: Subject<GetProductList$Response> = new ReplaySubject<GetProductList$Response>(null);

  private unitsList = [];
  private unitListSubject: Subject<GetAllProductUnits$Response> = new ReplaySubject<GetAllProductUnits$Response>(null);

  constructor(private api: ApiService) {
    this.productList = [];
    this.unitsList = [];
    setTimeout(() => {
      this.productList = [];
      this.unitsList = [];
    }, 900000);
  }

  getActiveProducts(customerId: number, forceFetch: boolean = false): Observable<GetProductList$Response> {
    if (forceFetch || !this.productList || this.productList[customerId].length === 0) {
      this.productListSubject.unsubscribe();
      this.productListSubject = new ReplaySubject<GetProductList$Response>(null);
      this.api.products.getActiveProducts(requestParamsToUrl([new KeyVal('customerId', customerId)])).subscribe(res => {
        if (res && res.data) {
          this.productList[customerId] = res.data;
          this.metadata = res.metadata;
          this.productListSubject.next({
            data: this.productList[customerId],
            metadata: this.metadata
          });
        } else {
          this.productListSubject.next({
            data: this.productList[customerId],
            metadata: this.metadata
          });
        }
      }, (error: ApiError) => {
        this.productListSubject.error(error);
      })
    } else {
      this.productListSubject.next({
        data: this.productList[customerId],
        metadata: this.metadata
      });
    }
    return this.productListSubject;
  }

  getAllUnits(): Observable<GetAllProductUnits$Response> {
    if (!this.unitsList || this.unitsList.length === 0) {
      this.unitListSubject.unsubscribe();
      this.unitListSubject = new ReplaySubject<GetAllProductUnits$Response>(null);
      this.api.products.getAllProductUnits().subscribe(res => {
        if (res && res.data) {
          this.unitsList = res.data;
          this.unitListSubject.next({
            data: this.unitsList
          });
        } else {
          this.unitListSubject.next({
            data: this.unitsList
          });
        }
      });
    } else {
      this.unitListSubject.next({
        data: this.unitsList
      });
    }

    return this.unitListSubject;
  }

  getCustomerProductUnits(customerId: number): Observable<GetProductUnitsMap$Response> {
    return this.api.products.getProductUnitsMap(customerId);
  }

  clean(): void {
    this.productList = undefined;
    this.unitsList = [];
    this.metadata.totalCount = undefined;
    this.metadata.limit = undefined;
    this.metadata.offset = undefined;
  }
}
