import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../shared/notification/notification.service';
import {ValidationService} from '../../shared/validation/validation.service';
import {ApiError} from '../../api/api-types';
import {FormValidationErrors} from '../../custom-interfaces';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {requiredNoSpaceValidator} from '../../shared/validation/required-no-space.validator';
import {ErrorService} from '../../api/error-service';
import {CustomValidator} from '../../shared/validation/custom.validator';
import {AuthService} from '../../services/auth.service';

@Component({
  templateUrl: './password-recovery.component.html'
})
export class PasswordRecoveryComponent implements OnInit, OnDestroy {

  public resetPasswordForm: FormGroup;
  public username: string;
  public token: string;
  public resetPasswordFormErrors: FormValidationErrors = {};

  private subscriptions = new Subscription();

  constructor(private fb: FormBuilder,
              private router: Router,
              private authService: AuthService,
              private errorService: ErrorService,
              private activatedRoute: ActivatedRoute,
              private validationService: ValidationService,
              private notificationService: NotificationService) {

    this.resetPasswordForm = this.fb.group({
      password1: ['', [requiredNoSpaceValidator, Validators.minLength(6), Validators.maxLength(20)]],
      password2: ['', [requiredNoSpaceValidator, Validators.minLength(6), Validators.maxLength(20)]]
    }, {
      validator: CustomValidator.passwordConfirm
    });

    this.validationService.addFormErrors(this.resetPasswordForm, this.resetPasswordFormErrors);
  }

  ngOnInit(): void {
    this.authService.logOut(false);
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe((params) => {
      const username: string = params['username'];
      const token: string = params['token'];
      if (username) {
        this.username = username;
      }
      if (token) {
        this.token = token;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  resetPassword(event): void {
    event.preventDefault();
    if (this.resetPasswordForm.valid) {
      const password: string = this.resetPasswordForm.controls['password1'].value;
      this.authService.resetPassword(this.username, this.token, password).pipe(take(1)).subscribe(() => {
        this.notificationService.success('auth.notifications.passwordSetSucceeded', true, true);
        this.router.navigate(['/login', {}]);
      }, (error: ApiError) => {
        this.errorService.handleError(error, 'auth.notifications.passwordSetFailed', undefined,
            this.resetPasswordForm, this.resetPasswordFormErrors);
      });
    }
  }

}
