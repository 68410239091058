import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserData, UserProfile, UserType} from '../../api/api-types';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'header',
  templateUrl: './app-header.component.html'
})

export class AppHeaderComponent implements OnInit, OnDestroy {

  public userData: UserData;
  type: UserType;
  private profile: UserProfile;
  public isLive;
  public isProduction;

  private whoAmI$Subscription: Subscription;

  constructor(private authService: AuthService) {
    this.isLive = environment.live;
    this.isProduction = environment.production;
  }

  ngOnInit(): void {
    this.whoAmI$Subscription = this.authService.whoAmI().subscribe(res => {
      this.userData = res;
      this.type = this.userData.type;
      this.profile = this.userData.profile;
    });
  }

  ngOnDestroy(): void {
    if (this.whoAmI$Subscription) {
      this.whoAmI$Subscription.unsubscribe();
    }
  }

  showTranslationCodes(event: Event): void {
    event.preventDefault();
    if (!this.isLive && !this.userData.profile.isAdmin) {
      return;
    }
    this.authService.useDevTranslations();
  }

  public logout(): void {
    this.authService.logOut();
  }

  public isAdmin(): boolean {
    return this.profile && this.profile.isAdmin;
  }
  public isDesigner(): boolean {
    return this.profile && this.profile.isDesigner;
  }
  public isManager(): boolean {
    return this.profile && this.profile.isManager;
  }
  public isCustomer(): boolean {
    return this.profile && this.profile.isCustomer;
  }
  public representsBuyer(): boolean {
    return this.profile && this.profile.representsBuyer;
  }
  public representsReseller(): boolean {
    return this.profile && this.profile.representsReseller;
  }
}
