import { EventEmitter } from '@angular/core';
import { throwError } from 'rxjs';
import { ExceptionTypeValues } from './api-types';
import { NotificationService } from '../shared/notification/notification.service';
import { ValidationService } from '../shared/validation/validation.service';
import * as i0 from "@angular/core";
import * as i1 from "../shared/notification/notification.service";
import * as i2 from "../shared/validation/validation.service";
var ErrorService = /** @class */ (function () {
    function ErrorService(notificationService, validationService) {
        this.notificationService = notificationService;
        this.validationService = validationService;
        this.logOut = new EventEmitter();
    }
    ErrorService.prototype.httpError = function (url, err) {
        if (err.status === 504) {
            this.notificationService.error('notification.requestTimeout', true, false);
        }
        else if (err.status === 502) {
            this.notificationService.error('notification.badGateway', true, false);
        }
        else if (err.status === 401) {
            if (url !== 'api/auth/authenticate' && url !== 'api/auth/whoami') {
                this.notificationService.error('notification.sessionExpired', true, false);
            }
            this.logOut.emit(true);
        }
        else if (err.status === 404) {
            this.notificationService.error('notification.noInternetConnection', false, false);
        }
        var apiError = err.error;
        if (apiError) {
            switch (apiError.type) {
                case ExceptionTypeValues.ACCESS_DENIED:
                    this.accessDenied(apiError);
                    break;
                case ExceptionTypeValues.GENERAL_ERROR:
                    this.generalError(apiError);
                    break;
                case ExceptionTypeValues.INTERNAL_ERROR:
                    this.internalError(apiError);
                    break;
            }
            return throwError(apiError);
        }
        return throwError(err);
    };
    ErrorService.prototype.accessDenied = function (err) {
        this.notificationService.error(err.code);
        err.notificationShown = true;
    };
    ErrorService.prototype.generalError = function (err) {
        this.notificationService.error(err.code);
        err.notificationShown = true;
    };
    ErrorService.prototype.internalError = function (err) {
        this.notificationService.error(err.code);
        err.notificationShown = true;
    };
    ErrorService.prototype.externalError = function (err) {
        this.notificationService.error(err.code);
        err.notificationShown = true;
    };
    ErrorService.prototype.handleError = function (apiError, errorCode, callBack, form, errors) {
        if (errors === void 0) { errors = {}; }
        if (apiError && apiError.type === ExceptionTypeValues.VALIDATION_ERROR && form !== undefined) {
            this.validationService.addCustomValErrors(form, apiError.errors, errors);
        }
        else if (apiError && apiError.type === ExceptionTypeValues.GENERAL_ERROR) {
            if (!apiError.notificationShown) {
                this.notificationService.error(apiError.code, true, true);
            }
        }
        else if (errorCode) {
            this.notificationService.error(errorCode, true, true);
        }
        if (callBack !== undefined) {
            callBack();
        }
    };
    ErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(i0.ɵɵinject(i1.NotificationService), i0.ɵɵinject(i2.ValidationService)); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };
