import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {
  ApiError,
  Authenticate$Response,
  CompleteInvitation$Response,
  Lang,
  LangValues,
  PasswordRecovery,
  RecoverPassword$Response,
  SendRecoveryEmail$Response,
  UserData, UserProfile,
  ValidateInvitation$Response,
  WhoAmI$Response
} from '../api/api-types';
import {ApiService} from '../api/api-service';
import {Router} from '@angular/router';
import {OnClean} from '../custom-interfaces';
import {TranslateService} from '@ngx-translate/core';
import {Notification} from '../shared/notification/notification.model';
import {NotificationService} from '../shared/notification/notification.service';
import {ApiClient} from '../api/api-client';
import {share, take, tap} from 'rxjs/operators';
import {last} from 'rxjs/internal/operators/last';
import {requestParamsToUrl} from '../common/ApiUtil';

const flatten = require('flat');

@Injectable()
export class AuthService implements OnClean {

  private userData: UserData = this.createUserData();
  private userRoleSubject: Subject<UserData> = new ReplaySubject<UserData>(null);

  private expiresNotification: Notification;
  private expiredNotification: Notification;

  private expiresTimer;
  private expiredTimer;
  private setLang: Lang = LangValues.EN;
  private whoAmIInProgress = false;

  constructor(
      private router: Router,
      private api: ApiService,
      private apiClient: ApiClient,
      private translateService: TranslateService,
      private notificationService: NotificationService
  ) {

    this.translateService.setDefaultLang('ET');
    this.translateService.setDefaultLang('EN');
  }

  public get getUserData() {
    return this.userData;
  }

  public get userId() {
    return this.userData.userId;
  }

  public whoAmI(force: boolean = false): Observable<UserData> {
    if ((!this.userData || !this.userData.userId) && !this.whoAmIInProgress || force) {
      this.whoAmIInProgress = true;
      this.api.auth.whoAmI().pipe(take(1)).subscribe((response: WhoAmI$Response) => {
        this.userData = response.data;
        if (response && response.data && response.data.lang) {
          this.useLang(response.data.lang);
        }
        this.userRoleSubject.next(this.userData);

        if (response.data.sessionExpires) {
          this.setSessionNotifications(new Date(response.data.sessionExpires));
        }
        this.whoAmIInProgress = false;
      }, (error: ApiError) => {
        this.whoAmIInProgress = false;
      });
    } else {
      this.userRoleSubject.next(this.userData);
    }

    return this.userRoleSubject;
  }

  public authenticate(userName: string, password: string): Observable<Authenticate$Response> {
    this.apiClient.clean();
    return this.api.auth.authenticate({userName: userName, password: password})
    .pipe(
        take(1),
        tap((res) => {
          if (res.data && res.metadata && res.metadata.jwt) {
            localStorage.setItem('jwtToken', res.metadata.jwt);
            const sessionExpirationDate = new Date(((new Date).getTime() + 36000000));
            this.userData = {
              userId: res.data.userId,
              username: res.data.username,
              name: res.data.name,
              email: res.data.email,
              type: res.data.type,
              profile: res.data.profile,
              lang: res.data.lang,
              numberOfCustomers: res.data.numberOfCustomers,
              sessionExpires: sessionExpirationDate.toString()
            };
            this.userRoleSubject.next(this.userData);
            this.useLang(this.userData.lang);
            this.setSessionExpiresNotification(sessionExpirationDate);
          }
        }),
        share()
    );
  }

  public setPassword(username: string, token: string, password: string): Observable<CompleteInvitation$Response> {
    const request: PasswordRecovery = {
      username: username,
      hash: token,
      password: password
    };
    return this.api.auth.completeInvitation(request);
  }

  private setSessionNotifications(expires: Date) {
    if (this.expiresTimer || this.expiredTimer) {
      return;
    }
    this.setSessionExpiresNotification(expires);
  }

  private setSessionExpiresNotification(expires: Date) {
    let c = expires.getTime() - (new Date().getTime());
    c -= 300000; // 5 minutes

    if (c < 0) {
      c = 0;
    }
    this.expiresTimer = setTimeout(() => {
      this.expiresNotification =
          this.notificationService.info('notification.sessionExpires', false, false);
    }, c);
    this.setSessionExpiredNotification(expires);
  }

  public setSessionExpiredNotification(expires: Date) {
    const c = expires.getTime() - (new Date().getTime());
    this.expiredTimer = setTimeout(() => {
      this.expiresNotification.onHide.emit(true);
      this.expiredNotification =
          this.notificationService.error('notification.sessionExpired', false, false);
    }, c);
  }

  public logOut(navigateToHome: boolean = true): void {
    localStorage.clear();
    this.userData = this.createUserData();
    this.userRoleSubject.next(this.userData);

    if (this.expiresTimer) {
      clearTimeout(this.expiresTimer);
      this.expiresTimer = undefined;
    }
    if (this.expiredTimer) {
      clearTimeout(this.expiredTimer);
      this.expiredTimer = undefined;
    }
    this.expiresNotification = undefined;
    this.expiredNotification = undefined;

    if (navigateToHome) {
      this.router.navigate(['/login'], {});
    }
  }

  resetPassword(username: string, token: string, password: string): Observable<RecoverPassword$Response> {
    return this.api.auth.recoverPassword({username: username, hash: token, password: password});
  }

  sendResetPasswordEmail(email: string): Observable<SendRecoveryEmail$Response> {
    return this.api.auth.sendRecoveryEmail({email: email});
  }

  validateToken(token: string): Observable<ValidateInvitation$Response> {
    return this.api.auth.validateInvitation(token);
  }

  clean(): void {
    this.logOut();
  }

  public useDevTranslations(): void {
    const DEV_T_F = flatten(this.translateService.store.translations['EN']);
    const DEV_T = {};
    Object.keys(DEV_T_F).forEach(key => DEV_T[key] = key);
    this.translateService.addLangs(['DEV']);
    this.translateService.store.translations['DEV'] = DEV_T;
    this.translateService.use('DEV');
  }

  public useLang(lang: Lang) {
    this.setLang = lang;
    this.translateService.use(lang as string).pipe(last()).subscribe(() => {
      if (lang !== this.setLang) {
        this.useLang(this.setLang);
      }
    });
  }

  public updateLanguage(lang: Lang) {
    if (this.userData && !!this.userData.lang && this.userData.lang !== lang) {
      const requestParams = [{key: 'lang', value: lang}];
      this.api.users.changeUserLanguage(this.userData.userId, requestParamsToUrl(requestParams)).pipe(take(1)).subscribe(() => {
        location.reload();
      }); 
    } else {
      this.useLang(lang);
    }
  }

  private createUserData(): UserData {
    let userData = new UserData();
    userData.profile = new UserProfile();
    return userData;
  }
}
