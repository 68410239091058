/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/app-header/app-header.component.ngfactory";
import * as i3 from "./shared/app-header/app-header.component";
import * as i4 from "./services/auth.service";
import * as i5 from "./shared/notification/notification.component.ngfactory";
import * as i6 from "./shared/notification/notification.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./shared/notification/notification.service";
import * as i9 from "@angular/router";
import * as i10 from "./shared/app-footer/app-footer.component.ngfactory";
import * as i11 from "./shared/app-footer/app-footer.component";
import * as i12 from "./app.component";
import * as i13 from "./services/app.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "header", [["id", "app-header"]], null, null, null, i2.View_AppHeaderComponent_0, i2.RenderType_AppHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.AppHeaderComponent, [i4.AuthService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-notification", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NotificationComponent_0, i5.RenderType_NotificationComponent)), i1.ɵdid(3, 245760, null, 0, i6.NotificationComponent, [i1.ElementRef, i7.TranslateService, i8.NotificationService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "footer", [], null, null, null, i10.View_AppFooterComponent_0, i10.RenderType_AppFooterComponent)), i1.ɵdid(8, 49152, null, 0, i11.AppFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 6, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i12.AppComponent, [i13.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
