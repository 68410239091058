import {Injectable, OnDestroy} from '@angular/core';
import {OnClean} from '../custom-interfaces';
import {ApiService} from '../api/api-service';
import {ApiClient} from '../api/api-client';
import {UsersService} from './users.service';
import {ErrorService} from '../api/error-service';
import {CallOffService} from './call-off.service';
import {Router} from '@angular/router';
import {DesignsService} from './designs.service';
import {AddressService} from './address.service';
import {ProductService} from './product.service';
import {CustomerService} from './customer.service';
import {InventoryService} from './inventory.service';
import {ComplaintsService} from './complaints.service';
import {MailTemplateService} from './mail-template.service';
import {LocalStorageService} from '../common/localStorage.service';
import {NotificationService} from '../shared/notification/notification.service';
import {PurchaseOrderService} from './purchase-order.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {AuthService} from './auth.service';
import {BsLocaleService} from 'ngx-bootstrap';
import {defineLocale} from 'ngx-bootstrap/chronos';
import * as locales from 'ngx-bootstrap/locale';
import {StandardProductsService} from './standard-products.service';

@Injectable({providedIn: 'root'})
export class AppService implements OnClean, OnDestroy {

  private subscriptions = new Subscription;

  constructor(
      private router: Router,
      private api: ApiService,
      private apiClient: ApiClient,
      private authService: AuthService,
      private usersService: UsersService,
      private errorService: ErrorService,
      private translate: TranslateService,
      private localeService: BsLocaleService,
      private callOffService: CallOffService,
      private designsService: DesignsService,
      private addressService: AddressService,
      private productService: ProductService,
      private customerService: CustomerService,
      private inventoryService: InventoryService,
      private complaintService: ComplaintsService,
      private templatesService: MailTemplateService,
      private localStorageService: LocalStorageService,
      private notificationService: NotificationService,
      private purchaseOrderService: PurchaseOrderService,
      private standardProductsService: StandardProductsService
  ) {
    this.errorService.logOut.subscribe(() => {
      this.clean();
    });
    this.importLocales();
  }

  ngOnDestroy(): void {
    this.subscriptions ? this.subscriptions.unsubscribe() : null;
  }

  clean(): void {
    this.apiClient.clean();
    this.authService.clean();
    this.usersService.clean();
    this.callOffService.clean();
    this.designsService.clean();
    this.addressService.clean();
    this.productService.clean();
    this.customerService.clean();
    this.inventoryService.clean();
    this.complaintService.clean();
    this.templatesService.clean();
    this.localStorageService.clean();
    this.notificationService.clean();
    this.purchaseOrderService.clean();
    this.standardProductsService.clean();
  }

  private importLocales() {
    for (const locale in locales) {
      defineLocale(locales[locale].abbr, locales[locale]);
    }
  }
}
