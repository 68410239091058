import {AbstractControl} from '@angular/forms';

export class CustomValidator {

  public static minLengthArray(min: number) {
    return (c: AbstractControl): {[key: string]: any} => {
      if (c.value.length >= min)
        return null;

      return { 'minLengthArray': {valid: false }};
    }
  }

  public static greaterThan(min: number) {
    return (c: AbstractControl): {[key: string]: any} => {
      if (c.value === null || c.value === '') return null;
      return c.value > min ? null : { 'greaterThan': {valid: false, min: min}};
    }
  }

  public static pastDate() {
    return (c: AbstractControl): {[key: string]: any} => {
      const val = c.value;
      if (!val) {
        return null;
      }
      const date = new Date(val), currentDate = new Date();
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);
      currentDate.setMilliseconds(0);
      return date < currentDate ? { 'pastDate': {valid: false }} : null;
    }
  }

  public static passwordConfirm(control: AbstractControl) {
    if (!control) {
      return;
    }
    const password = control.get('password1').value;
    const confirmPassword = control.get('password2').value;

    if (password !== confirmPassword) {
      control.get('password2').setErrors({'validation.PasswordMismatch': true});
    } else {
      control.get('password2').setErrors(null);
      return null;
    }
  }
}
