import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { KeyVal } from '../custom-interfaces';
import { Paginator } from '../api/paginator';
import { FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subscription } from 'rxjs';
import { InventoryFilterData } from '../common/inventory';
import { ApiClient } from '../api/api-client';
import { CALL_OFF_STORAGE_NAME, LocalStorageService } from '../common/localStorage.service';
import { AuthService } from './auth.service';
import { ApiService } from '../api/api-service';
import { instantToDate, toDateString } from '../common/DateUtil';
import { requestParamsToUrl } from '../common/ApiUtil';
import { SortOrderValues } from '../api/api-types';
import { take } from 'rxjs/operators';
var InventoryService = /** @class */ (function () {
    function InventoryService(fb, injector, apiClient, apiService, authService, localStorageService) {
        this.fb = fb;
        this.injector = injector;
        this.apiClient = apiClient;
        this.apiService = apiService;
        this.authService = authService;
        this.localStorageService = localStorageService;
        this.paginator = new Paginator();
        this.inventoryFilterData = new InventoryFilterData();
        this.inventoryLoadedEvent = new ReplaySubject();
        this._inventoryList = [];
        this.subscriptions = new Subscription();
    }
    InventoryService.prototype.find = function () {
        var _this = this;
        var data = this.inventoryFilterData;
        var params = [
            new KeyVal('customer-id', data.customerId),
            new KeyVal('from-date', data.startDate ? toDateString(data.startDate) : null),
            new KeyVal('to-date', data.endDate ? toDateString(data.endDate) : null),
            new KeyVal('include-settled', data.includeClosed),
        ];
        this.paginator.reset();
        this.paginator.isLoading = true;
        var reqParams = params
            .concat(this.paginator.dataAsKeyVal)
            .concat(data.inventorySortOrder.toKeyVal());
        this.apiService.inventory.getInventory(requestParamsToUrl(reqParams)).pipe(take(1)).subscribe(function (res) {
            _this._inventoryList = res.data.map(function (r) {
                return tslib_1.__assign({}, r, { callOffQuantity: undefined, callOffDate: undefined });
            });
            _this.filterAndSort();
            _this.inventoryLoadedEvent.next();
        }, function (error) {
            // error
        });
    };
    InventoryService.prototype.download = function (type) {
        var data = this.inventoryFilterData;
        var params = [
            new KeyVal('customer-id', data.customerId),
            new KeyVal('from-date', data.startDate ? instantToDate(data.startDate) : null),
            new KeyVal('to-date', data.endDate ? instantToDate(data.endDate) : null),
            new KeyVal('include-settled', data.includeClosed),
            new KeyVal('file-type', type),
        ];
        return this.apiClient.exportInventory(requestParamsToUrl(params));
    };
    InventoryService.prototype.filterAndSort = function () {
        var order = this.inventoryFilterData.inventorySortOrder._order;
        var direction = this.inventoryFilterData.inventorySortOrder._direction;
        var list = this._inventoryList.slice();
        list = this.filter(list);
        if (!order || !direction) {
            // maintain natural order
        }
        else if (SortOrderValues.ASC === direction) {
            list = list.sort(function (n1, n2) {
                if (n1[order] > n2[order]) {
                    return 1;
                }
                else if (n1[order] < n2[order]) {
                    return -1;
                }
                return 0;
            });
        }
        else if (SortOrderValues.DESC === direction) {
            list = list.sort(function (n1, n2) {
                if (n1[order] < n2[order]) {
                    return 1;
                }
                else if (n1[order] > n2[order]) {
                    return -1;
                }
                return 0;
            });
        }
        this.paginator.addPages(list);
        this.paginator.isLoading = false;
    };
    InventoryService.prototype.filter = function (list) {
        var _this = this;
        var filter = !this.inventoryFilterData.filter ? '' : this.inventoryFilterData.filter;
        return list.filter(function (row) {
            return _this.includes(row.productName, filter) || _this.includes(row.productNumber, filter) || _this.includes(row.salesOrderId, filter)
                || _this.includes(row.externalItemId, filter) || _this.includes(row.purchOrderFormNum, filter);
        });
    };
    InventoryService.prototype.initializeAndFillCallOffForm = function () {
        var _this = this;
        this.callOffForm = this.fb.group({
            products: this.fb.array([]),
            orderNumber: ['', Validators.maxLength(25)],
            comment: ['', Validators.maxLength(1000)]
        });
        var storageCallOff = this.localStorageService.getJsonFromStorage(CALL_OFF_STORAGE_NAME);
        if (!!storageCallOff) {
            try {
                var callOffObj = storageCallOff;
                if (callOffObj.userId != this.authService.userId) {
                    this.localStorageService.putIntoStorage(CALL_OFF_STORAGE_NAME, undefined);
                    return;
                }
                this.preFillForm(callOffObj);
            }
            catch (e) {
                console.error(e);
            }
        }
        var subs = this.callOffForm.valueChanges.subscribe(function () {
            var callOff = _this.callOffForm.getRawValue();
            callOff.userId = _this.authService.userId;
            _this.localStorageService.putJsonIntoStorage(CALL_OFF_STORAGE_NAME, callOff);
        });
        this.subscriptions.add(subs);
    };
    Object.defineProperty(InventoryService.prototype, "productsArray", {
        get: function () {
            return this.callOffForm.controls.products;
        },
        enumerable: true,
        configurable: true
    });
    InventoryService.prototype.removeAllProducts = function () {
        while (this.productsArray.length !== 0) {
            this.productsArray.removeAt(0);
        }
    };
    InventoryService.prototype.getCallOffProductRowToFormGroup = function (row) {
        // const prevCalloff = row.callOffRows === undefined || row.callOffRows.length === 0 ?
        //     0 : row.callOffRows.reduce((a, b) => a + b.orderedQuantity, 0);
        return this.fb.group({
            index: [row.index],
            salesOrderId: [row.salesOrderId],
            purchOrderFormNum: [row.purchOrderFormNum],
            productName: [row.productName],
            productNumber: [row.productNumber],
            externalItemId: [row.externalItemId],
            deliveryDate: [row.deliveryDate],
            orderedQuantity: [row.orderedQuantity],
            deliveredQuantity: [row.deliveredQuantity],
            quantityInWarehouse: [row.quantityInWarehouse],
            unit: [row.unit],
            inventTransId: [row.inventTransId],
            lineNum: [row.lineNum],
            callOffDate: [row.callOffDate ? new Date(row.callOffDate) : undefined, [Validators.required]],
            callOffQuantity: [row.callOffQuantity, [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(Math.max(row.orderedQuantity, row.quantityInWarehouse))
                ]]
        });
    };
    InventoryService.prototype.preFillForm = function (callOff) {
        this.callOffForm.controls.orderNumber.setValue(callOff.orderNumber, { onlySelf: true });
        this.callOffForm.controls.comment.setValue(callOff.comment, { onlySelf: true });
        this.addProductsToForm(callOff.products);
    };
    InventoryService.prototype.addProductsToForm = function (products) {
        var _this = this;
        products.forEach(function (r) {
            _this.productsArray.push(_this.getCallOffProductRowToFormGroup(r));
        });
    };
    InventoryService.prototype.includes = function (x, filter) {
        return (x ? x.toLocaleLowerCase() : '').includes((filter ? filter.toLocaleLowerCase() : ''));
    };
    InventoryService.prototype.clean = function () {
        this.subscriptions ? this.subscriptions.unsubscribe() : null;
        this.paginator.reset();
        this.inventoryFilterData = new InventoryFilterData();
    };
    return InventoryService;
}());
export { InventoryService };
