import {Injectable} from '@angular/core';
import {ApiService} from '../api/api-service';
import {
  AddNewComment$Response,
  AddNewImageComment$Response,
  ApiResponseMetadata,
  Confirm$Response,
  ConfirmDigiproof$Response,
  Decline$Response,
  DeclineDigiproof$Response,
  DeleteDesignDigiproofRow$Response,
  DesignConfirmRequest,
  DesignDigiproofSaveRow,
  DesignListRow,
  DesignOrderBy,
  DesignOrderByValues,
  GetDesign$Response,
  GetDesignComments$Response,
  GetDesignDigiproofs$Response,
  GetDesignListRequest,
  GetDesigns$Response,
  MarkArchived$Response,
  MarkDigiproofAsSent$Response,
  NewComment,
  NewImageComment,
  ReopenDesign$Response,
  SaveDesignDigiproofRow$Response,
  SendToCustomer$Response,
  SortOrder,
  SortOrderValues,
  StopDesign$Response,
  UpdateDesignDigiproofTrackingNumber$Response,
  UpdateTrackingNumberRequest
} from '../api/api-types';
import {DesignServiceData, OnClean} from '../custom-interfaces';
import {combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {ApiClient} from '../api/api-client';
import {HttpResponse} from '@angular/common/http';


@Injectable()
export class DesignsService implements OnClean {

  private pages: { [key: number]: DesignListRow[] };
  private metadata: ApiResponseMetadata = new ApiResponseMetadata;
  private designListSubject: Subject<GetDesigns$Response> = new ReplaySubject<GetDesigns$Response>(null);

  private _serviceData: DesignServiceData = new DesignServiceData;

  constructor(private api: ApiService, private apiClient: ApiClient) {
    this.pages = {};
    this.initServiceData();
  }

  private doCount(designName: string, designEan: string, designId: string, status, customerId: number, showAll: boolean): boolean {
    return this._serviceData.totalCount === 0
        || this._serviceData.designName !== designName
        || this._serviceData.designEan !== designEan
        || this._serviceData.designId !== designId
        || this._serviceData.customerId !== customerId
        || this._serviceData.status !== status
        || this._serviceData.showAll !== showAll;
  }

  getDesigns(designName: string = null, designEan: string = null, designId: string = null, status = null, customerId: number = null,
             orderBy: DesignOrderBy, order: SortOrder, pageNum: number, showAll: boolean): Observable<GetDesigns$Response> {

    let forceFetch = false;
    if (this._serviceData.designName !== designName
        || this._serviceData.designEan !== designEan
        || this._serviceData.designId !== designId
        || this._serviceData.status !== status
        || this._serviceData.customerId !== customerId
        || this._serviceData.listOrderBy !== orderBy
        || this._serviceData.listSortOrder !== order
        || this._serviceData.showAll !== showAll) {
      forceFetch = true;
      this.pages = {};
    } else if (!this.pages[pageNum] || this.pages[pageNum].length === 0) {
      forceFetch = true;
    }

    if (forceFetch) {
      const cal = this.calculateOffsetAndLimit(pageNum);
      this.designListSubject.unsubscribe();
      this.designListSubject = new ReplaySubject<GetDesigns$Response>(null);

      const request: GetDesignListRequest = {
        customerId: customerId ? customerId : undefined,
        designName: designName ? designName : undefined,
        ean: designEan ? designEan : undefined,
        designId: designId ? designId : undefined,
        status: status ? status : undefined,
        orderBy: orderBy ? orderBy : undefined,
        order: order ? order : undefined,
        offset: cal.offset,
        limit: cal.limit,
        showAll: showAll,
        count: this.doCount(designName, designEan, designId, status, customerId, showAll)
      };

      this.api.design.getDesigns(request).subscribe(res => {
        if (res && res.data) {
          this.pages[pageNum] = res.data
        }
        if (res && res.metadata) {
          this.metadata.limit = res.metadata.limit;
          this.metadata.offset = res.metadata.offset;
          if (res.metadata.totalCount != null) {
            this.metadata.totalCount = res.metadata.totalCount;
            this._serviceData.totalCount = res.metadata.totalCount;
          }
        }

        this.designListSubject.next({
          data: this.pages[pageNum],
          metadata: this.metadata
        });
        this._serviceData.currentPage = pageNum;

      }, (err) => {
        this.designListSubject.error(err);
      });

    } else {
      this.designListSubject.next({
        data: this.pages[pageNum],
        metadata: this.metadata
      });
      this._serviceData.currentPage = pageNum;
    }

    this._serviceData.customerId = customerId;
    this._serviceData.listOrderBy = orderBy;
    this._serviceData.listSortOrder = order;
    this._serviceData.designName = designName;
    this._serviceData.designEan = designEan;
    this._serviceData.designId = designId;
    this._serviceData.status = status;
    this._serviceData.showAll = showAll;

    return this.designListSubject;
  }

  private calculateOffsetAndLimit(page: number): any {
    const offset = (page - 1) * this._serviceData.rowsPerPage;
    const limit = offset + this._serviceData.rowsPerPage;
    return {offset: offset, limit: limit}
  }

  getDesign(id: number): Observable<GetDesign$Response> {
    return this.api.design.getDesign(id);
  }

  getDesignComments(designId: number): Observable<GetDesignComments$Response> {
    return this.api.design.getDesignComments(designId);
  }

  clean(): void {
    this.pages = {};
    this.metadata.offset = undefined;
    this.metadata.limit = undefined;
    this.metadata.totalCount = undefined;
    this.initServiceData();
  }

  initServiceData(): void {
    this._serviceData.listOrderBy = DesignOrderByValues.upload_date;
    this._serviceData.listSortOrder = SortOrderValues.DESC;
    this._serviceData.designName = null;
    this._serviceData.designEan = null;
    this._serviceData.customerId = null;
    this._serviceData.designId = null;
    this._serviceData.status = '';
    this._serviceData.showAll = true;
    this._serviceData.totalCount = 0;
    this._serviceData.rowsPerPage = 25;
    this._serviceData.currentPage = 1;
  }

  public refreshCurrentPage(): void {
    this.pages[this._serviceData.currentPage] = [];
  }

  getServiceData(): DesignServiceData {
    return this._serviceData;
  }

  addNewComment(request: NewComment): Observable<AddNewComment$Response> {
    return this.api.design.addNewComment(request);
  }

  addNewImageComment(request: NewImageComment): Observable<AddNewImageComment$Response> {
    return this.api.design.addNewImageComment(request);
  }

  getCommentImage(designId: number, commentId: number): Observable<any> {
    return this.apiClient.getTextFile('api/designs/' + designId + '/getCommentImage/' + commentId, true);
  }

  getCommentImageDataLayer(designId: number, commentId: number): Observable<any> {
    return this.apiClient.getTextFile('api/designs/' + designId + '/getCommentData/' + commentId, true);
  }

  getCommentImages(designId: number, commentId: number): Observable<any> {
    return combineLatest(
        this.getCommentImage(designId, commentId),
        this.getCommentImageDataLayer(designId, commentId)
    );
  }

  confirmDesign(request: DesignConfirmRequest): Observable<Confirm$Response> {
    this.refreshCurrentPage();
    return this.api.design.confirm(request);
  }

  confirmDigiproof(designId: number): Observable<ConfirmDigiproof$Response> {
    this.refreshCurrentPage();
    return this.api.design.confirmDigiproof(designId);
  }

  decline(designId: number): Observable<Decline$Response> {
    this.refreshCurrentPage();
    return this.api.design.decline(designId);
  }

  declineDigiproof(request: NewComment): Observable<DeclineDigiproof$Response> {
    this.refreshCurrentPage();
    return this.api.design.declineDigiproof(request)
  }

  stop(designId: number): Observable<StopDesign$Response> {
    this.refreshCurrentPage();
    return this.api.design.stopDesign(designId);
  }

  reopen(designId: number): Observable<ReopenDesign$Response> {
    this.refreshCurrentPage();
    return this.api.design.reopenDesign(designId);
  }

  sendToCustomer(designId: number): Observable<SendToCustomer$Response> {
    this.refreshCurrentPage();
    return this.api.design.sendToCustomer(designId);
  }

  getFileData(designId: number): Observable<HttpResponse<Blob>> {
    return this.apiClient.getFile('api/designs/' + designId + '/pdf');
  }

  getDesignDigiproofs(designId: number): Observable<GetDesignDigiproofs$Response> {
    return this.api.design.getDesignDigiproofs(designId);
  }

  saveDesignDigiproof(row: DesignDigiproofSaveRow): Observable<SaveDesignDigiproofRow$Response> {
    return this.api.design.saveDesignDigiproofRow(row);
  }

  updateTrackingNumber(row: UpdateTrackingNumberRequest): Observable<UpdateDesignDigiproofTrackingNumber$Response> {
    return this.api.design.updateDesignDigiproofTrackingNumber(row);
  }

  deleteDigiproofRow(designId: number, digiproofId: number): Observable<DeleteDesignDigiproofRow$Response> {
    return this.api.design.deleteDesignDigiproofRow(designId, digiproofId);
  }

  markDigiproofSent(designId: number): Observable<MarkDigiproofAsSent$Response> {
    return this.api.design.markDigiproofAsSent(designId);
  }

  archiveDesign(designId: number): Observable<MarkArchived$Response> {
    return this.api.design.markArchived(designId);
  }
}
