/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./control-messages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./control-messages.component";
var styles_ControlMessagesComponent = [i0.styles];
var RenderType_ControlMessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ControlMessagesComponent, data: {} });
export { RenderType_ControlMessagesComponent as RenderType_ControlMessagesComponent };
function View_ControlMessagesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(2, { value: 0 })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.errorCode; var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit.parameter); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ControlMessagesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.errorCode; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ControlMessagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlMessagesComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlMessagesComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.parameter != undefined); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.parameter == undefined); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ControlMessagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["role", "tooltip"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "tooltip-arrow arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "tooltip-inner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlMessagesComponent_2)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.fieldErrorCodes; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "tooltip in ", _co.cssClass, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_ControlMessagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlMessagesComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasErrors; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ControlMessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "control-message", [], null, null, null, View_ControlMessagesComponent_0, RenderType_ControlMessagesComponent)), i1.ɵdid(1, 114688, null, 0, i4.ControlMessagesComponent, [i1.ElementRef, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ControlMessagesComponentNgFactory = i1.ɵccf("control-message", i4.ControlMessagesComponent, View_ControlMessagesComponent_Host_0, { errors: "errors", fieldName: "fieldName", cssClass: "cssClass", position: "position", color: "color" }, {}, []);
export { ControlMessagesComponentNgFactory as ControlMessagesComponentNgFactory };
