import {Injectable} from '@angular/core';
import {ValidationError} from '../../api/api-types';
import {AbstractControl, FormArray, FormGroup, ValidationErrors} from '@angular/forms';
import {FieldErrors, FormValidationErrors} from '../../custom-interfaces';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ToDatePipe} from '../pipes/date.pipe';

@Injectable()
export class ValidationService {

  constructor(private toDatePipe: ToDatePipe) {}

  public addCustomValErrors(form: FormGroup, responseErrors: ValidationError[], formErrors: FormValidationErrors): void {
    if (!responseErrors) {
      return;
    }
    for (const err of responseErrors) {
      const control = form.controls[err.field];
      if (control) {
        let errors: ValidationErrors = control.errors;
        if (!errors) {
          errors = {};
        }
        errors[err.errorCode] = err.errorCode;
        control.setErrors(errors);
      }
    }
    this.parseFormErrors(form, formErrors);
  }

  public addFormErrors(form: FormGroup, errors: FormValidationErrors): void {
    form.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.parseFormErrors(form, errors);
    });
    form.statusChanges.pipe(debounceTime(10)).subscribe(() => {
      this.parseFormErrors(form, errors);
    });
  }

  private parseFormErrors(form: FormGroup, errors: FormValidationErrors) {
    for (const control in form.controls) {
      const ac = form.controls[control];
      errors[control] = {} as FieldErrors;
      if (ac instanceof FormArray || ac instanceof FormGroup) {
        for (const nControl in ac.controls) {
          const nAc = ac.controls[nControl] as FormGroup;
          if (nAc.controls) {
            for (const nnControl in nAc.controls) {
              const nnAc = nAc.controls[nnControl];
              errors[control + nControl + '.' + nnControl] = {}; // remove previous errors
              this.addFormControlErrors(nnAc, control + nControl + '.' + nnControl, errors);
            }
          } else {
            errors[control + '.' + nControl] = {}; // remove previous errors
            this.addFormControlErrors(nAc, control + '.' + nControl, errors);
          }
        }
      } else {
        this.addFormControlErrors(ac, control, errors);
      }
    }
  }

  private addFormControlErrors(ac: AbstractControl, control: string, errors: FormValidationErrors) {
    if (ac.dirty && !ac.valid) {
      const fieldErrors = {} as FieldErrors;
      for (let err in ac.errors) {
        const errObj: any = ac.errors[err];
        let param: any;
        if (err === 'maxlength' || err === 'minlength') {
          param = errObj.requiredLength;
        } else if (err === 'max') {
          param = errObj.max;
        } else if (err === 'min') {
          param = errObj.min;
        } else if (err === 'greaterThan') {
          param = errObj.min;
        } else if (err === 'bsDate') {
          const error = ac.errors[err];
          err = '';
          const minDate = error['minDate'];
          const maxDate = error['maxDate'];
          if (minDate) {
            err = 'minDate';
            param = this.toDatePipe.transform(minDate);
          } else if (maxDate) {
            err = 'maxDate';
            param = this.toDatePipe.transform(maxDate);
          } else {
            err = 'invalidDate';
          }
        }
        fieldErrors[err] = {
          errorCode: err,
          parameter: param
        };
      }
      errors[control] = fieldErrors;
    }
  }

  public markFormAsDirty(form: FormGroup) {
    if (!form || !form.controls) {
      return;
    }
    (<any>Object).keys(form.controls).map(e => form.controls[e]).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();

      if (control.controls) {
        if (control instanceof FormGroup) {
          Object.keys(control.controls).map(key => control.controls[key]).map(c  => {
            c.markAsTouched();
            c.markAsDirty();
            c.updateValueAndValidity({emitEvent: true});
          });
        } else {
          control.controls.forEach(c => this.markFormAsDirty(c));
        }
      } else {
        control.updateValueAndValidity({emitEvent: true});
      }
    });
  }

  public subscribeControlValueChanges(form: FormGroup, control: string, method: Function, debounceT?: number | 0): Subscription {
    return form.controls[control].valueChanges.pipe(
        debounceTime(debounceT),
        distinctUntilChanged()
    ).subscribe((res: any) => {
      method();
    });
  }

  public subscribeValueChanges(form: FormGroup, controls: string[], method: Function, debounceT?: number | 0): Subscription[] {
    const arr: Subscription[] = [];
    for (const control of controls) {
      arr.push(this.subscribeControlValueChanges(form, control, method, debounceT));
    }
    return arr;
  }

}
