import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorService } from './error-service';
import { environment } from '../../environments/environment';
import { environment as avi } from '../../environments/app-version';
import { NotificationService } from '../shared/notification/notification.service';
import { catchError, map, take, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./error-service";
import * as i3 from "../shared/notification/notification.service";
var ApiClient = /** @class */ (function () {
    function ApiClient(http, errorService, notificationService) {
        this.http = http;
        this.errorService = errorService;
        this.notificationService = notificationService;
        this.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Origin': '*',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
        };
    }
    ApiClient.prototype.execute = function (method, url, body) {
        var _this = this;
        var headers = tslib_1.__assign({}, this.headers);
        this.addAuthorizationHeader(headers);
        if (method === 'GET') {
            return this.http.get(environment.ABSOLUTE_PATH + url, { headers: headers, observe: 'response', params: this.createHttpParams(body) })
                .pipe(tap(function (res) { return _this.checkApiVersion(res); }), map(function (res) { return res.body; }), tap(function (res) { return _this.checkForEmptyResponse(res); }), catchError(function (err) { return _this.errorService.httpError(url, err); }));
        }
        else if (method === 'POST') {
            return this.http.post(environment.ABSOLUTE_PATH + url, body, { headers: headers, observe: 'response' })
                .pipe(tap(function (res) { return _this.checkApiVersion(res); }), map(function (res) { return res.body; }), tap(function (res) { return _this.checkForEmptyResponse(res); }), catchError(function (err) { return _this.errorService.httpError(url, err); }));
        }
        else if (method === 'PUT') {
            return this.http.put(environment.ABSOLUTE_PATH + url, body, { headers: headers, observe: 'response' })
                .pipe(tap(function (res) { return _this.checkApiVersion(res); }), map(function (res) { return res.body; }), tap(function (res) { return _this.checkForEmptyResponse(res); }), catchError(function (err) { return _this.errorService.httpError(url, err); }));
        }
    };
    ApiClient.prototype.getImage = function (url, reportProgress) {
        var headers = tslib_1.__assign({}, this.headers);
        this.addAuthorizationHeader(headers);
        headers['Content-Type'] = 'image';
        headers['Accept'] = '*';
        headers['Access-Control-Allow-Headers'] = 'Content-Length';
        headers['Access-Control-Expose-Headers'] = 'Content-Length';
        return this.http.get(environment.ABSOLUTE_PATH + url, { headers: headers, observe: 'events', responseType: 'blob', reportProgress: reportProgress });
    };
    ApiClient.prototype.getFile = function (url, reportProgress) {
        var headers = tslib_1.__assign({}, this.headers);
        this.addAuthorizationHeader(headers);
        headers['Content-Type'] = 'blob';
        headers['Accept'] = '*';
        headers['Access-Control-Allow-Headers'] = 'Content-Length';
        headers['Access-Control-Expose-Headers'] = 'Content-Length';
        if (reportProgress) {
            return this.http.get(environment.ABSOLUTE_PATH + url, { headers: headers, observe: 'events', responseType: 'blob', reportProgress: true });
        }
        else {
            return this.http.get(environment.ABSOLUTE_PATH + url, { headers: headers, observe: 'response', responseType: 'blob', reportProgress: false });
        }
    };
    ApiClient.prototype.getTextFile = function (url, reportProgress) {
        var headers = tslib_1.__assign({}, this.headers);
        this.addAuthorizationHeader(headers);
        headers['Content-Type'] = 'blob';
        headers['Accept'] = '*';
        headers['Access-Control-Allow-Headers'] = 'Content-Length';
        headers['Access-Control-Expose-Headers'] = 'Content-Length';
        return this.http.get(environment.ABSOLUTE_PATH + url, { headers: headers, observe: 'events', responseType: 'text', reportProgress: reportProgress });
    };
    ApiClient.prototype.exportInventory = function (url) {
        var headers = tslib_1.__assign({}, this.headers);
        this.addAuthorizationHeader(headers);
        headers['Accept'] = '*';
        headers['Access-Control-Allow-Headers'] = 'Content-Length';
        headers['Access-Control-Expose-Headers'] = 'Content-Length';
        return this.http.get(environment.ABSOLUTE_PATH + 'api/inventory/download' + url, { headers: headers, observe: 'response', responseType: 'blob' });
    };
    ApiClient.prototype.getTerms = function (lang) {
        var _this = this;
        var headers = tslib_1.__assign({}, this.headers);
        this.addAuthorizationHeader(headers);
        var url = 'api/order/terms/' + lang;
        return this.http.get(environment.ABSOLUTE_PATH + url, { headers: headers, observe: 'response', responseType: 'blob' }).pipe(catchError(function (err) { return _this.errorService.httpError(url, err); }));
    };
    ApiClient.prototype.uploadFile = function (file) {
        var _this = this;
        var url = 'api/file/upload';
        var headers = {
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Origin': '*',
            'Accept': '*',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
        };
        this.addAuthorizationHeader(headers);
        var formData = new FormData();
        formData.append('uploadFile', file, file.name);
        return this.http.post(environment.ABSOLUTE_PATH + url, formData, { headers: headers })
            .pipe(catchError(function (err) { return _this.errorService.httpError(url, err); }), map(function (r) { return r; }));
    };
    ApiClient.prototype.addAuthorizationHeader = function (headers) {
        var jwtToken = localStorage.getItem('jwtToken');
        if (jwtToken) {
            headers['Authorization'] = 'Bearer ' + jwtToken;
        }
        else {
            headers['Authorization'] = '';
        }
    };
    ApiClient.prototype.checkApiVersion = function (res) {
        var _this = this;
        if (res && res.headers) {
            var feVersion = avi.APP_VERSION;
            var appVersion = res.headers.get('app-version');
            if (!!feVersion && !!appVersion && feVersion !== appVersion && !this.versionNotification) {
                this.versionNotification = this.notificationService.newApiVersionNotification();
                this.versionNotification.onHide.pipe(take(1)).subscribe(function () {
                    _this.versionNotification = undefined;
                });
            }
        }
        this.checkUserId(res);
    };
    ApiClient.prototype.createHttpParams = function (paramsBody) {
        if (!paramsBody)
            return undefined;
        var params = new HttpParams;
        Object.keys(paramsBody)
            .filter(function (key) { return !!paramsBody[key]; })
            .forEach(function (key) { return (params = params.append(key, paramsBody[key])); });
        return params;
    };
    ApiClient.prototype.checkUserId = function (res) {
        if (res && res.headers) {
            var hash = res.headers.get('uid-hash');
            if (hash) {
                if (this.UID_HASH !== undefined) {
                    if (this.UID_HASH !== hash) {
                        window.location.reload();
                        return;
                    }
                }
                else {
                    this.UID_HASH = hash;
                }
            }
        }
    };
    ApiClient.prototype.checkForEmptyResponse = function (res) {
        if (!res.data || Object.keys(res.data).length === 0 && res.data.constructor === Object) {
            return;
        }
        if (typeof res.data === 'boolean' && res.data === false) {
            throw false;
        }
        else if (typeof res.data === 'object' && (res.data == null || res.data === undefined)) {
            throw false;
        }
    };
    ApiClient.prototype.clean = function () {
        this.UID_HASH = undefined;
    };
    ApiClient.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiClient_Factory() { return new ApiClient(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ErrorService), i0.ɵɵinject(i3.NotificationService)); }, token: ApiClient, providedIn: "root" });
    return ApiClient;
}());
export { ApiClient };
