import {UserType, UserTypeValues} from './api/api-types';

export interface RoutePermission {
  url: string,
  userType: UserType,
  admin: boolean,
  manager: boolean,
  designer: boolean
}

export const AppRoutePermissions: RoutePermission[] = [
  {url: 'templates', userType: UserTypeValues.ESTIKO, admin: true, manager: false, designer: false},
  {url: 'users', userType: UserTypeValues.ESTIKO, admin: true, manager: true, designer: false},
  {url: 'style', userType: UserTypeValues.ESTIKO, admin: true, manager: false, designer: false},
  {url: 'app-settings', userType: UserTypeValues.ESTIKO, admin: true, manager: false, designer: false},
  {url: 'complaints/new', userType: UserTypeValues.CUSTOMER, admin: false, manager: false,  designer: false},
];

export const AppRoutePermissionsDenied: RoutePermission[] = [
  {url: 'purchase-order', userType: UserTypeValues.ESTIKO, admin: false, manager: false, designer: true},
  {url: 'inventory', userType: UserTypeValues.ESTIKO, admin: false, manager: false, designer: true},
  {url: 'complaints', userType: UserTypeValues.ESTIKO, admin: false, manager: false, designer: true},
];
