import {
  AxSalesStatus,
  AxSalesStatusValues,
  CommonProductListRow,
  InventoryListRow,
  SalesOrderProductListResource,
  SortOrderValues
} from '../../api/api-types';
import {ListSortOrder} from '../../custom-interfaces';
import {localMoment} from '../DateUtil';

export class InventoryFilterData {
  customerId: number;
  startDate: Date = localMoment().subtract(1, 'year').toDate();
  endDate: Date = localMoment().add(1, 'year').toDate();
  includeClosed: boolean = false;
  filter: string;
  showAll: boolean = false;
  inventorySortOrder: ListSortOrder = new ListSortOrder('salesOrderId', SortOrderValues.DESC);
}

export class InventoryListCallOffRow extends InventoryListRow {
  callOffQuantity: number = undefined;
  callOffDate: Date = undefined;
}

export class CommonProductListOrderRow extends CommonProductListRow {
  orderDeliveryDate: Date = undefined;
  orderQuantity: number = undefined;
}

export class CallOffFilterData {
  customerId: number;
  startDate: Date = localMoment().subtract(1, 'year').toDate();
  endDate: Date = localMoment().add(1, 'year').toDate();
  status: AxSalesStatus = AxSalesStatusValues.ALL;
  filter: string;
  callOffSortOrder: ListSortOrder = new ListSortOrder('createdAt', SortOrderValues.DESC);
}

export interface CallOffStorageType {
  products?: InventoryListCallOffRow[];
  userId?: number;
  orderNumber?: string;
  comment?: string;
}

export class StandardProductsFilter {
  filter: string;
}

export class SalesOrderProductListResourceExtended extends SalesOrderProductListResource {
  lineAmount?: number;
}
