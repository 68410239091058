import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {FieldError, FormValidationErrors} from '../../../custom-interfaces';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'control-message',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss']
})
export class ControlMessagesComponent implements OnInit {

  @Input() errors: FormValidationErrors;
  @Input() fieldName: string;
  @Input() cssClass: string;
  @Input() position: string;
  @Input() color: string;

  private tooltipPosition: TooltipPosition;
  private tooltipColor: TooltipColor;

  constructor(private elementRef: ElementRef,
              private translate: TranslateService) {
  }

  ngOnInit(): void {

    if (TooltipPosition[this.position] === TooltipPosition.right) {
      this.tooltipPosition = TooltipPosition.right;
      const prevElement: any = this.elementRef.nativeElement.previousElementSibling;
      this.elementRef.nativeElement.style.left = prevElement.offsetLeft + prevElement.offsetWidth + 'px';
      this.elementRef.nativeElement.style.position = 'absolute';
      this.elementRef.nativeElement.style.top = 0;
      this.cssClass += ' tooltip-right right';
    } else if (TooltipPosition[this.position] === TooltipPosition.top) {
      this.tooltipPosition = TooltipPosition.top;
      this.cssClass += ' tooltip-top top';
    } else {
      this.tooltipPosition = TooltipPosition.bottom;
      this.cssClass += ' tooltip-bottom bottom';
    }

    if (TooltipColor[this.color] === TooltipColor.green) {
      this.tooltipColor = TooltipColor.green;
      this.cssClass += ' success';
    } else if (TooltipColor[this.color] === TooltipColor.yellow) {
      this.tooltipColor = TooltipColor.yellow;
      this.cssClass += ' warning';
    } else if (TooltipColor[this.color] === TooltipColor.red) {
      this.tooltipColor = TooltipColor.red;
      this.cssClass += ' error';
    } else {
      this.tooltipColor = TooltipColor.black;
    }

  }

  get hasErrors(): boolean {
    const fieldErrors = this.errors[this.fieldName];
    if (fieldErrors) {
      for (const code in fieldErrors) {
        if (fieldErrors[code] !== undefined) {
          return true;
        }
      }
    }
    return false;
  }

  get fieldErrorCodes(): FieldError[] {
    const codes: FieldError[] = [], errs = this.errors[this.fieldName];
    for (const code in errs) {
      codes.push(errs[code]);
    }
    return codes;
  }
}

enum TooltipColor {red, green, yellow, black}

enum TooltipPosition {right, top, bottom}
