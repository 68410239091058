import {EventEmitter} from '@angular/core';

export class Notification {
  type: NotificationType;
  message: string;
  autohide: boolean;
  visibilityTime: number;
  onHide: EventEmitter<any> = new EventEmitter();
}

export enum NotificationType {
  Success,
  Error,
  Info,
  Warning,
  NewApiVersion
}
