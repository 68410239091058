import { OnDestroy, OnInit, ElementRef } from '@angular/core';
import { LangValues } from '../../api/api-types';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
var LanguageSelectComponent = /** @class */ (function () {
    function LanguageSelectComponent(eRef, authService) {
        this.eRef = eRef;
        this.authService = authService;
        this.isOpen = false;
        this._languages = [{ code: 'gb', value: LangValues.EN, selected: true }, { code: 'ee', value: LangValues.ET }];
        this.subscriptions = new Subscription;
    }
    LanguageSelectComponent.prototype.clickout = function (event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.isOpen = false;
        }
    };
    Object.defineProperty(LanguageSelectComponent.prototype, "languages", {
        get: function () {
            return this._languages.sort(function (a, b) { return a.selected ? -1 : 1; });
        },
        enumerable: true,
        configurable: true
    });
    LanguageSelectComponent.prototype.chooseAndUpdate = function (lang) {
        this.choose(lang);
        this.authService.updateLanguage(lang);
    };
    LanguageSelectComponent.prototype.toggle = function () {
        this.isOpen = !this.isOpen;
    };
    LanguageSelectComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    };
    LanguageSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.authService.whoAmI().subscribe(function (res) {
            _this.userData = res;
            if (res && res.lang) {
                _this.choose(res.lang);
            }
        }));
    };
    LanguageSelectComponent.prototype.choose = function (lang) {
        this._languages.forEach(function (v) { return v.selected = false; });
        this._languages.find(function (v) { return v.value === lang; }).selected = true;
    };
    return LanguageSelectComponent;
}());
export { LanguageSelectComponent };
