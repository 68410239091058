import { AuthGuard } from './services/auth-guard.service';
import { LoginComponent } from './auth/login/login.component';
import { InvitationComponent } from './auth/invitation/invitation.component';
import { PasswordRecoveryComponent } from './auth/password-recovery/password-recovery.component';
var ɵ0 = function () { return import("./components/users/users.module.ngfactory").then(function (m) { return m.UsersModuleNgFactory; }); }, ɵ1 = function () { return import("./components/customer/customers.module.ngfactory").then(function (m) { return m.CustomersModuleNgFactory; }); }, ɵ2 = function () { return import("./components/designs/designs.module.ngfactory").then(function (m) { return m.DesignsModuleNgFactory; }); }, ɵ3 = function () { return import("./components/complaints/complaints.module.ngfactory").then(function (m) { return m.ComplaintsModuleNgFactory; }); }, ɵ4 = function () { return import("./components/inventory/inventory.module.ngfactory").then(function (m) { return m.InventoryModuleNgFactory; }); }, ɵ5 = function () { return import("./components/mail-template/mail-template.module.ngfactory").then(function (m) { return m.TemplatesModuleNgFactory; }); }, ɵ6 = function () { return import("./components/purchase-order/purchase-order.module.ngfactory").then(function (m) { return m.PurchaseOrderModuleNgFactory; }); }, ɵ7 = function () { return import("./components/products/products.module.ngfactory").then(function (m) { return m.ProductsModuleNgFactory; }); }, ɵ8 = function () { return import("./components/call-off/call-off.module.ngfactory").then(function (m) { return m.CallOffModuleNgFactory; }); }, ɵ9 = function () { return import("./components/standard-products/standard-products.module.ngfactory").then(function (m) { return m.StandardProductsModuleNgFactory; }); }, ɵ10 = function () { return import("./components/style-proto/style-proto.module.ngfactory").then(function (m) { return m.StyleProtoModuleNgFactory; }); }, ɵ11 = function () { return import("./components/app-settings/app-settings.module.ngfactory").then(function (m) { return m.AppSettingsModuleNgFactory; }); };
export var AppRoutes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'invitation/:token', component: InvitationComponent },
    { path: 'resetpassword', component: PasswordRecoveryComponent },
    { path: 'users', loadChildren: ɵ0, canActivate: [AuthGuard] },
    {
        path: 'customer',
        loadChildren: ɵ1,
        canActivate: [AuthGuard]
    },
    { path: 'designs', loadChildren: ɵ2, canActivate: [AuthGuard] },
    {
        path: 'complaints',
        loadChildren: ɵ3,
        canActivate: [AuthGuard]
    },
    {
        path: 'inventory',
        loadChildren: ɵ4,
        canActivate: [AuthGuard]
    },
    {
        path: 'templates',
        loadChildren: ɵ5,
        canActivate: [AuthGuard]
    },
    {
        path: 'purchase-order',
        loadChildren: ɵ6,
        canActivate: [AuthGuard]
    },
    {
        path: 'products',
        loadChildren: ɵ7,
        canActivate: [AuthGuard]
    },
    {
        path: 'call-off',
        loadChildren: ɵ8,
        canActivate: [AuthGuard]
    },
    {
        path: 'standard-products',
        loadChildren: ɵ9,
        canActivate: [AuthGuard]
    },
    { path: 'style', loadChildren: ɵ10 },
    {
        path: 'app-settings',
        loadChildren: ɵ11,
        canActivate: [AuthGuard]
    },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
