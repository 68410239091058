import {FormControl} from '@angular/forms';

export function requiredNoSpaceValidator(c: FormControl) {
  if (c.value === null || c.value === undefined || c.value.trim().length === 0) {
    return {
      validateNoSpace: {
        valid: false
      }
    }
  }
  return null;
}

export function optionalNoSpaceValidator(c: FormControl) {
  if (c.value !== null && c.value !== '' && c.value.trim().length === 0) {
    return {
      validateNoSpace: {
        valid: false
      }
    }
  }
  return null;
}
