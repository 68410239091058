<div class="row">
  <div *ngIf="login" class="col-xs-6 col-xs-offset-3 panel-login">
    <h3 translate>auth.logIn</h3>

    <form novalidate [formGroup]="loginForm">
      <ngx-loading [show]="loginLoading"></ngx-loading>
      <div class="form-group">
        <input formControlName="userName" name="userName" class="form-control" type="text"
               placeholder="{{ 'auth.login.username' | translate }}">
        <control-message [errors]="loginFormErrors"
                         fieldName="userName"
                         position="bottom"
                         color="red"
                         cssClass="">
        </control-message>
      </div>
      <div class="form-group">
        <input formControlName="password" id="pwd" class="form-control" type="password"
               placeholder="{{ 'auth.login.password' | translate }}">
        <control-message [errors]="loginFormErrors"
                         fieldName="password"
                         position="bottom"
                         color="red"
                         cssClass="">
        </control-message>
      </div>
      <div class="row form-group">
        <div class="col-xs-6">
          <!--auth.logIn must end with </button>,
              otherwise it searches for 'auth.logIn\n' object.
              Careful with auto formatters-->
          <button type="submit" class="btn btn-bd-primary"
                  (click)="authenticate()" translate>auth.logIn</button>
        </div>
        <div class="col-xs-6 text-right">
          <a href="#" (click)="recover()" class="btn-link" translate>auth.forgotPassword</a>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="!login" class="col-xs-6 col-xs-offset-3 panel-login">
    <h3 translate>auth.sendNewPassword</h3>

    <form novalidate [formGroup]="resetPasswordForm">
      <ngx-loading [show]="resetLoading"></ngx-loading>
      <div class="form-group">
        <input formControlName="userName" name="userName" class="form-control" type="text"
               placeholder="{{ 'auth.login.usernameOrEmail' | translate }}">
        <control-message [errors]="resetPasswordFormErrors"
                         fieldName="userName"
                         position="bottom"
                         color="red"
                         cssClass="">
        </control-message>
      </div>
      <div class="form-group">
        <button type="button" class="btn btn-bd-default" (click)="login = !login" translate>auth.back</button>
        <button type="submit" class="btn btn-bd-success"
                (click)="sendRecoveryEmail()"
                translate>auth.sendPassword</button>
      </div>
    </form>
  </div>
</div>
