import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

const FORMAT = 'DD/MM/YYYY';

@Pipe({
  name: 'toDate'
})
export class ToDatePipe implements PipeTransform {
  transform(timestamp: string): string {
    return timestamp ? moment(timestamp).format(FORMAT) : '';
  }
}
