import {Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from './notification.service';
import {Notification, NotificationType} from './notification.model';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  moduleId: module.id,
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  public notifications: Notification[] = [];
  private subscriptions = new Subscription();
  private header: any;
  private notif: any;

  @HostListener('window:scroll', [])
  onScroll() {
    if (window.scrollY > this.header.offsetHeight) {
      this.notif.classList.remove('header-visible');
    } else {
      this.notif.classList.add('header-visible');
    }
  }

  constructor(private elementRef: ElementRef,
              private translate: TranslateService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.header = document.getElementById('app-header');
    this.notif = this.elementRef.nativeElement;
    this.onScroll();

    this.subscriptions.add(this.notificationService.getNotification().subscribe((notification: Notification) => {
      if (!notification) {
        this.notifications = [];
        return;
      }
      if (notification.autohide) {
        setTimeout(() => {
          this.removeAlert(notification);
        }, notification.visibilityTime);
      }

      this.notifications.push(notification);

      while (this.notifications.length > 2) {
        this.removeAlert(this.notifications.shift());
      }

      const s: Subscription = notification.onHide.subscribe(() => {
        if (s) {
          s.unsubscribe();
          this.removeAlert(notification);
        }
      });
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  removeAlert(notification: Notification): void {
    const list: Notification[] = [];
    for (let c of this.notifications) {
      if (c === notification) {
        if (c.onHide) {
          c.onHide.emit(true);
          c.onHide.unsubscribe();
        }
        c = null;
      } else {
        list.push(c);
      }
    }
    this.notifications = list;
  }

  cssClass(notification: Notification): string {
    if (!notification) {
      return;
    }

    let cssclass = 'alert ';

    if (notification.autohide) {
      cssclass += 'autohide ';
    }

    switch (notification.type) {
      case NotificationType.Success:
        return cssclass + 'alert-success';
      case NotificationType.Error:
        return cssclass + 'alert-danger';
      case NotificationType.Info:
        return cssclass + 'alert-info';
      case NotificationType.Warning:
        return cssclass + 'alert-warning';
      case NotificationType.NewApiVersion:
        return cssclass + 'alert-warning';
    }
  }

  get NewApiVersionType(): NotificationType.NewApiVersion {
    return NotificationType.NewApiVersion;
  }

  refreshPage() {
    window.location.reload(true);
  }
}
