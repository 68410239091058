import { ApiService } from '../api/api-service';
import { ApiResponseMetadata, CommonProductListRow, CustomerStandardProductSalesUnit, GetAvailableStandardProductsWithUnits$Response, GetCommonProductsInGroups$Response, GetCustomerStandardProductsSalesUnits$Response, ProductAvailableSalesUnit, SaveStandardProductCustomerSalesUnit$Response, SaveStandardProductOrder$Response, StandardProductOrderSaveResource, StandardProductsWithUnits } from '../api/api-types';
import { forkJoin, ReplaySubject } from 'rxjs';
import { StandardProductsFilter } from '../common/inventory';
import { FormBuilder, Validators } from '@angular/forms';
import { ErrorService } from '../api/error-service';
import { StandardProductOrderDateValidator } from '../shared/validation/StandardProductOrderDateValidator';
var StandardProductsService = /** @class */ (function () {
    function StandardProductsService(api, fb, errorService) {
        this.api = api;
        this.fb = fb;
        this.errorService = errorService;
        this.standardProductFilter = new StandardProductsFilter();
        this.metadata = new ApiResponseMetadata();
        this.commonProductsInGroupsSubject = new ReplaySubject();
    }
    Object.defineProperty(StandardProductsService.prototype, "productsArray", {
        get: function () {
            return this.purchaseForm.controls.products;
        },
        enumerable: true,
        configurable: true
    });
    StandardProductsService.prototype.removeAllCommonProducts = function () {
        var _this = this;
        this.productsArray.controls.forEach(function (r, i) { return _this.productsArray.removeAt(i); });
    };
    StandardProductsService.prototype.clean = function () {
        this.metadata.totalCount = undefined;
        this.metadata.limit = undefined;
        this.metadata.offset = undefined;
        this.standardProductFilter = new StandardProductsFilter();
    };
    StandardProductsService.prototype.createStandardProductOrder = function (customerId, order) {
        return this.api.standardProducts.saveStandardProductOrder(customerId, order);
    };
    StandardProductsService.prototype.createInitialFormForPurchases = function (row) {
        return this.fb.group({
            productId: [row.productId],
            itemCode: [row.itemCode],
            itemName: [row.itemName],
            ean: [row.ean],
            itemGroupId: [row.itemGroupId],
            itemGroupName: [row.itemGroupName],
            availableQuantity: [row.availableQuantity],
            salesUnit: [row.salesUnit],
            inventUnit: [row.inventUnit],
            externalItemId: [row.externalItemId],
            orderDeliveryDate: [row.orderDeliveryDate ? new Date(row.orderDeliveryDate) : undefined,
                [Validators.required, StandardProductOrderDateValidator.dateValidator]],
            orderQuantity: [row.orderQuantity, [
                    Validators.required,
                    Validators.min(1),
                    Validators.max(row.availableQuantity)
                ]]
        });
    };
    StandardProductsService.prototype.getAvailableStandardProductUnits = function () {
        return this.api.standardProducts.getAvailableStandardProductsWithUnits();
    };
    StandardProductsService.prototype.saveCustomerSpecificSalesUnit = function (customerId, selectedUnit) {
        return this.api.standardProducts.saveStandardProductCustomerSalesUnit(customerId, selectedUnit);
    };
    StandardProductsService.prototype.getCustomerStandardProductSalesUnits = function (customerId) {
        return this.api.standardProducts.getCustomerStandardProductsSalesUnits(customerId);
    };
    StandardProductsService.prototype.getCommonProductsInGroupsWithCustomerSpecificSalesUnits = function (customerId) {
        var _this = this;
        this.commonProductsInGroupsSubject = new ReplaySubject(null);
        forkJoin([this.api.products.getCommonProductsInGroups(),
            this.api.standardProducts.getCustomerStandardProductsSalesUnits(customerId),
            this.api.standardProducts.getAvailableStandardProductsWithUnits()]).subscribe(function (results) {
            var commonProductsByGroups = results[0].data;
            var customerStandardProductSalesUnits = results[1].data;
            var availableUnits = results[2].data;
            var map = commonProductsByGroups.map(function (grp) {
                grp.products = grp.products.map(function (product) { return product = _this.changeToCustomerSpecificSalesUnit(product, customerStandardProductSalesUnits, availableUnits); });
                return grp;
            });
            _this.commonProductsInGroupsSubject.next({
                data: map,
                metadata: undefined
            });
        }, function (error) {
            _this.errorService.handleError(error, '');
        });
        return this.commonProductsInGroupsSubject;
    };
    StandardProductsService.prototype.changeToCustomerSpecificSalesUnit = function (productRow, customerStandardProductSalesUnits, availableUnits) {
        var productId = parseInt(productRow.productId, 10);
        var availableSalesUnits = availableUnits.find(function (product) { return product.id === productId; });
        var salesUnit = customerStandardProductSalesUnits.find(function (product) { return product.productId === productId; });
        if (salesUnit !== undefined && availableSalesUnits !== undefined) {
            var temp = availableSalesUnits.possibleConversions.find(function (unit) { return unit.toUnitId === salesUnit.customSalesUnitId; });
            productRow.salesUnit = temp.toUnit;
            productRow.availableQuantity = productRow.availableQuantity / temp.coefficient;
        }
        return productRow;
    };
    return StandardProductsService;
}());
export { StandardProductsService };
