export var CALL_OFF_STORAGE_NAME = 'CALL-OFF';
var LocalStorageService = /** @class */ (function () {
    function LocalStorageService() {
    }
    LocalStorageService.prototype.getFromStorage = function (key) {
        return this.localStorage.getItem(key);
    };
    LocalStorageService.prototype.getJsonFromStorage = function (key) {
        var val = this.getFromStorage(key);
        try {
            return !!val ? JSON.parse(val) : undefined;
        }
        catch (e) {
            console.log(e);
        }
        return undefined;
    };
    LocalStorageService.prototype.putIntoStorage = function (key, value) {
        this.localStorage.setItem(key, value);
    };
    LocalStorageService.prototype.putJsonIntoStorage = function (key, object) {
        this.putIntoStorage(key, !!object ? JSON.stringify(object) : "");
    };
    LocalStorageService.prototype.clean = function () {
        this.localStorage.removeItem(CALL_OFF_STORAGE_NAME);
    };
    Object.defineProperty(LocalStorageService.prototype, "localStorage", {
        get: function () {
            return window.localStorage;
        },
        enumerable: true,
        configurable: true
    });
    return LocalStorageService;
}());
export { LocalStorageService };
