import {KeyVal, OnClean} from '../custom-interfaces';
import {Injectable} from '@angular/core';
import {
  ApiResponseMetadata,
  AxSalesStatusValues,
  CallOffCombinedListResource,
  CallOffSaveResource,
  GetCallOff$Response,
  GetCallOffProducts$Response,
  SaveCallOff$Response,
  SortOrderValues
} from '../api/api-types';
import {ApiService} from '../api/api-service';
import {Paginator} from '../api/paginator';
import {CallOffFilterData} from '../common/inventory';
import {requestParamsToUrl} from '../common/ApiUtil';
import {Observable} from 'rxjs';
import {toDateString} from '../common/DateUtil';

@Injectable()
export class CallOffService implements OnClean {

  public paginator: Paginator = new Paginator();
  public callOffFilterData: CallOffFilterData;

  private _callOffList: CallOffCombinedListResource[] = [];
  private metadata: ApiResponseMetadata;


  constructor(private api: ApiService) {
    this.clean();
  }

  public createCallOff(customerId: number, request: CallOffSaveResource): Observable<SaveCallOff$Response> {
    return this.api.callOff.saveCallOff(customerId, request);
  }

  public find(force: boolean = false) {
    if (force || !this.paginator.hasPageData) {
      const data = this.callOffFilterData;
      const params = [
        new KeyVal('customer-id', data.customerId),
        // new KeyVal('order-number', data.orderNumber),
        new KeyVal('from-date', data.startDate ? toDateString(data.startDate) : null),
        new KeyVal('to-date', data.endDate ? toDateString(data.endDate) : null),
        new KeyVal('_count', force),
      ];

      if (force) {
        this.paginator.reset();
      }
      this.paginator.isLoading = true;
      const reqParams = params.concat(this.paginator.dataAsKeyVal).concat(this.callOffFilterData.callOffSortOrder.toKeyVal());

      this.getAllCallOffs(reqParams);

    }
  }

  private findAllCalloffs(reqParams: any[]) {
    return this.api.callOff.findAllCallOffs(requestParamsToUrl(reqParams)).toPromise();
  }

  public getAllCallOffs(reqParams: any[]) {


    this.findAllCalloffs(reqParams).then(res => {
      this._callOffList = res.data;
    }).then(() => {
      this.filterAndSort();
    });
  }

  private sortCallOffsByStatus(callOffs: CallOffCombinedListResource[]): CallOffCombinedListResource[] {
    const status = !this.callOffFilterData.status ? AxSalesStatusValues.ALL : this.callOffFilterData.status;
    if (status === AxSalesStatusValues.ALL) {
      return callOffs;
    }
    return callOffs.filter(c => c.calculatedStatus === status);
  }

  public save(customerId: number, request: CallOffSaveResource): Observable<SaveCallOff$Response> {
    return this.api.callOff.saveCallOff(customerId, request);
  }

  public getOne(callOffId: number): Observable<GetCallOff$Response> {
    return this.api.callOff.getCallOff(callOffId);
  }

  public getProducts(callOffId: number): Observable<GetCallOffProducts$Response> {
    return this.api.callOff.getCallOffProducts(callOffId);
  }

  public filterAndSort(): void {
    const order = this.callOffFilterData.callOffSortOrder._order;
    const direction = this.callOffFilterData.callOffSortOrder._direction;
    let list = this._callOffList.slice();
    list = this.sortCallOffsByStatus(list);
    list = this.filter(list);
    console.log(this.callOffFilterData);

    if (!order || !direction) {
      // maintain natural order
    } else if (SortOrderValues.ASC === direction) {
      list = list.sort((n1, n2) => {
        if (n1[order] > n2[order]) {
          return 1;
        } else if (n1[order] < n2[order]) {
          return -1;
        }
        return 0;
      });
    } else if (SortOrderValues.DESC === direction) {
      list = list.sort((n1, n2) => {
        if (n1[order] < n2[order]) {
          return 1;
        } else if (n1[order] > n2[order]) {
          return -1;
        }
        return 0;
      });
    }
    this.paginator.addPages(list);
    this.paginator.isLoading = false;
  }

  public filter(list: CallOffCombinedListResource[]): CallOffCombinedListResource[] {
    const filter = !this.callOffFilterData.filter ? '' : this.callOffFilterData.filter;
    return list.filter((row) => {
      return this.includes(row.callOffOrderNumb, filter) || this.includes(row.comment, filter);
    });
  }

  private includes(x: string, filter: string): boolean {
    return (x ? x.toLocaleLowerCase() : '').includes((filter ? filter.toLocaleLowerCase() : ''));
  }

  clean(): void {
    this.paginator.reset();
    this.metadata = new ApiResponseMetadata;
    this.callOffFilterData = new CallOffFilterData();
  }

}
