import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from './notification.service';
import { NotificationType } from './notification.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
var NotificationComponent = /** @class */ (function () {
    function NotificationComponent(elementRef, translate, notificationService) {
        this.elementRef = elementRef;
        this.translate = translate;
        this.notificationService = notificationService;
        this.notifications = [];
        this.subscriptions = new Subscription();
    }
    NotificationComponent.prototype.onScroll = function () {
        if (window.scrollY > this.header.offsetHeight) {
            this.notif.classList.remove('header-visible');
        }
        else {
            this.notif.classList.add('header-visible');
        }
    };
    NotificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.header = document.getElementById('app-header');
        this.notif = this.elementRef.nativeElement;
        this.onScroll();
        this.subscriptions.add(this.notificationService.getNotification().subscribe(function (notification) {
            if (!notification) {
                _this.notifications = [];
                return;
            }
            if (notification.autohide) {
                setTimeout(function () {
                    _this.removeAlert(notification);
                }, notification.visibilityTime);
            }
            _this.notifications.push(notification);
            while (_this.notifications.length > 2) {
                _this.removeAlert(_this.notifications.shift());
            }
            var s = notification.onHide.subscribe(function () {
                if (s) {
                    s.unsubscribe();
                    _this.removeAlert(notification);
                }
            });
        }));
    };
    NotificationComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    };
    NotificationComponent.prototype.removeAlert = function (notification) {
        var e_1, _a;
        var list = [];
        try {
            for (var _b = tslib_1.__values(this.notifications), _c = _b.next(); !_c.done; _c = _b.next()) {
                var c = _c.value;
                if (c === notification) {
                    if (c.onHide) {
                        c.onHide.emit(true);
                        c.onHide.unsubscribe();
                    }
                    c = null;
                }
                else {
                    list.push(c);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.notifications = list;
    };
    NotificationComponent.prototype.cssClass = function (notification) {
        if (!notification) {
            return;
        }
        var cssclass = 'alert ';
        if (notification.autohide) {
            cssclass += 'autohide ';
        }
        switch (notification.type) {
            case NotificationType.Success:
                return cssclass + 'alert-success';
            case NotificationType.Error:
                return cssclass + 'alert-danger';
            case NotificationType.Info:
                return cssclass + 'alert-info';
            case NotificationType.Warning:
                return cssclass + 'alert-warning';
            case NotificationType.NewApiVersion:
                return cssclass + 'alert-warning';
        }
    };
    Object.defineProperty(NotificationComponent.prototype, "NewApiVersionType", {
        get: function () {
            return NotificationType.NewApiVersion;
        },
        enumerable: true,
        configurable: true
    });
    NotificationComponent.prototype.refreshPage = function () {
        window.location.reload(true);
    };
    return NotificationComponent;
}());
export { NotificationComponent };
