import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ApiError, ExceptionTypeValues} from './api-types';
import {NotificationService} from '../shared/notification/notification.service';
import {FormGroup} from '@angular/forms';
import {ValidationService} from '../shared/validation/validation.service';
import {FormValidationErrors} from '../custom-interfaces';

@Injectable({providedIn: 'root'})
export class ErrorService {

  public logOut: EventEmitter<any> = new EventEmitter();

  constructor(
      private notificationService: NotificationService,
      private validationService: ValidationService
  ) {
  }

  httpError(url: string, err: HttpErrorResponse| any): Observable<any> {
    if (err.status === 504) {
      this.notificationService.error('notification.requestTimeout', true, false);
    } else if (err.status === 502) {
      this.notificationService.error('notification.badGateway', true, false);
    } else if (err.status === 401) {
      if (url !== 'api/auth/authenticate' && url !== 'api/auth/whoami') {
        this.notificationService.error('notification.sessionExpired', true, false);
      }
      this.logOut.emit(true);
    } else if (err.status === 404) {
      this.notificationService.error('notification.noInternetConnection', false, false);
    }
    const apiError = err.error as ApiError;
    if (apiError) {
      switch (apiError.type) {
        case ExceptionTypeValues.ACCESS_DENIED:
          this.accessDenied(apiError);
          break;
        case ExceptionTypeValues.GENERAL_ERROR:
          this.generalError(apiError);
          break;
        case ExceptionTypeValues.INTERNAL_ERROR:
          this.internalError(apiError);
          break;
      }
      return throwError(apiError);
    }
    return throwError(err);
  }

  accessDenied(err: ApiError): void {
    this.notificationService.error(err.code);
    err.notificationShown = true;
  }

  generalError(err: ApiError): void {
    this.notificationService.error(err.code);
    err.notificationShown = true;
  }

  internalError(err: ApiError): void {
    this.notificationService.error(err.code);
    err.notificationShown = true;
  }

  externalError(err: ApiError): void {
    this.notificationService.error(err.code);
    err.notificationShown = true;
  }

  handleError(apiError: ApiError, errorCode: string, callBack?: Function, form?: FormGroup, errors: FormValidationErrors = {}): void {
    if (apiError && apiError.type === ExceptionTypeValues.VALIDATION_ERROR && form !== undefined) {
      this.validationService.addCustomValErrors(form, apiError.errors, errors);
    } else if (apiError && apiError.type === ExceptionTypeValues.GENERAL_ERROR) {
      if (!apiError.notificationShown) {
        this.notificationService.error(apiError.code, true, true);
      }
    } else if (errorCode) {
      this.notificationService.error(errorCode, true, true);
    }
    if (callBack !== undefined) {
      callBack();
    }
  }

}
