import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {ApiService} from './api/api-service';
import {take} from 'rxjs/internal/operators/take';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      return;
    }
    console.error(error);
    const api: ApiService = this.injector.get(ApiService);
    api.application.logFrontendError({stackTrace: error.stack}).pipe(take(1)).subscribe(() => {
    }, () => {
    });
  }

}