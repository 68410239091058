import moment from 'moment';
import 'moment-timezone';

const TIME_ZONE = 'Europe/Tallinn';

export function toTimeZone(date: moment.Moment): moment.Moment {
  return date.tz(TIME_ZONE);
}

export function localMoment(): moment.Moment {
  return toTimeZone(moment());
}

export function instantToDate(date: Date): string {
  return date.toISOString().substr(0, 10);
}

export function toDateString(date: Date): string {
  return moment(date).tz('Europe/Tallinn').format('YYYY-MM-DD');
}
