import {Injectable} from '@angular/core';
import {ApiService} from '../api/api-service';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {
  ApiResponseMetadata,
  ComplaintDecisionRequest,
  ComplaintFile,
  ComplaintListRow,
  ComplaintOrderBy,
  ComplaintOrderByValues,
  ComplaintsRequest,
  DeleteComplaint$Response,
  DeleteComplaintFile$Response,
  GetComplaint$Response,
  GetComplaintFiles$Response,
  GetComplaints$Response,
  MarkComplaintAsSolved$Response,
  RejectComplaint$Response,
  SaveComplaint$Response,
  SaveComplaintFile$Response,
  SaveComplaintRequest,
  SortOrder,
  SortOrderValues,
  SubmitComplaint$Response
} from '../api/api-types';
import {ComplaintsServiceData, OnClean} from '../custom-interfaces';
import {ApiClient} from '../api/api-client';

@Injectable()
export class ComplaintsService implements OnClean {

  private pages: { [key: number]: ComplaintListRow[] };
  private metadata: ApiResponseMetadata = new ApiResponseMetadata;
  private complaintListSubject: Subject<GetComplaints$Response> = new ReplaySubject<GetComplaints$Response>(null);

  private _serviceData: ComplaintsServiceData = new ComplaintsServiceData;

  constructor(private api: ApiService, private apiClient: ApiClient) {
    this.pages = {};
    this.initServiceData();
  }

  private doCount(complaintNumber: string, purchaseOrderNumber: string, customerId: number, managerId: number): boolean {
    return this._serviceData.totalCount === 0
        || this._serviceData.customerId !== customerId
        || this._serviceData.complaintNumber !== complaintNumber
        || this._serviceData.purchaseOrderNumber !== purchaseOrderNumber
        || this._serviceData.managerId !== managerId
        || this._serviceData.status !== status;
  }

  public getComplaints(complaintNumber: string = null, purchaseOrderNumber: string = null, customerId: number = null,
                       managerId: number = null, status = null, orderBy: ComplaintOrderBy,
                       order: SortOrder, pageNum: number): Observable<GetComplaints$Response> {

    let forceFetch = false;
    if (this._serviceData.complaintNumber !== complaintNumber
        || this._serviceData.purchaseOrderNumber !== purchaseOrderNumber
        || this._serviceData.status !== status
        || this._serviceData.customerId !== customerId
        || this._serviceData.managerId !== managerId
        || this._serviceData.listOrderBy !== orderBy
        || this._serviceData.listSortOrder !== order) {
      forceFetch = true;
      this.pages = {};
    } else if (!this.pages[pageNum] || this.pages[pageNum].length === 0) {
      forceFetch = true;
    }

    if (forceFetch) {
      const cal = this.calculateOffsetAndLimit(pageNum);
      this.complaintListSubject.unsubscribe();
      this.complaintListSubject = new ReplaySubject<GetComplaints$Response>(null);

      const request: ComplaintsRequest = {
        customerId: customerId ? customerId : undefined,
        salesManagerId: managerId ? managerId : undefined,
        complaintId: complaintNumber ? complaintNumber : undefined,
        purchaseOrderNumber: purchaseOrderNumber ? purchaseOrderNumber : undefined,
        status: status ? status : undefined,
        orderBy: orderBy ? orderBy : undefined,
        order: order ? order : undefined,
        offset: cal.offset,
        limit: cal.limit,
        count: this.doCount(complaintNumber, purchaseOrderNumber, customerId, managerId)
      };

      this.api.complaint.getComplaints(request).subscribe(res => {
        if (res && res.data) {
          this.pages[pageNum] = res.data
        }
        if (res && res.metadata) {
          this.metadata.limit = res.metadata.limit;
          this.metadata.offset = res.metadata.offset;
          if (res.metadata.totalCount) {
            this.metadata.totalCount = res.metadata.totalCount;
            this._serviceData.totalCount = res.metadata.totalCount;
          }
        }

        this.complaintListSubject.next({
          data: this.pages[pageNum],
          metadata: this.metadata
        });
        this._serviceData.currentPage = pageNum;

      }, (err) => {
        this.complaintListSubject.error(err);
      });


    } else {
      this.complaintListSubject.next({
        data: this.pages[pageNum],
        metadata: this.metadata
      });
      this._serviceData.currentPage = pageNum;
    }

    this._serviceData.complaintNumber = complaintNumber;
    this._serviceData.purchaseOrderNumber = purchaseOrderNumber;
    this._serviceData.customerId = customerId;
    this._serviceData.managerId = managerId;
    this._serviceData.status = status;
    this._serviceData.listOrderBy = orderBy;
    this._serviceData.listSortOrder = order;

    return this.complaintListSubject;
  }

  private calculateOffsetAndLimit(page: number): any {
    const offset = (page - 1) * this._serviceData.rowsPerPage;
    const limit = offset + this._serviceData.rowsPerPage;
    return {offset: offset, limit: limit}
  }


  public saveComplaint(request: SaveComplaintRequest): Observable<SaveComplaint$Response> {
    return this.api.complaint.saveComplaint(request);
  }

  public getComplaint(id: number): Observable<GetComplaint$Response> {
    return this.api.complaint.getComplaint(id);
  }

  public getComplaintFiles(id: number): Observable<GetComplaintFiles$Response> {
    return this.api.complaint.getComplaintFiles(id);
  }

  clean(): void {
    this.pages = {};
    this.metadata.offset = undefined;
    this.metadata.limit = undefined;
    this.metadata.totalCount = undefined;
    this.initServiceData();
  }

  initServiceData(): void {
    this._serviceData.listOrderBy = ComplaintOrderByValues.modified_at;
    this._serviceData.listSortOrder = SortOrderValues.DESC;
    this._serviceData.customerId = null;
    this._serviceData.managerId = null;
    this._serviceData.complaintNumber = null;
    this._serviceData.purchaseOrderNumber = null;
    this._serviceData.status = '';
    this._serviceData.totalCount = 0;
    this._serviceData.rowsPerPage = 25;
    this._serviceData.currentPage = 1;
    this._serviceData.showAll = false;
  }

  get serviceData() {
    return this._serviceData;
  }

  getFileData(complaintId: number, fileId: number): Observable<any> {
    return this.apiClient.getFile('api/complaint/' + complaintId + '/file/' + fileId, true);
  }

  getComplaintDecisionFileData(complaintId: number): Observable<any> {
    return this.apiClient.getFile('api/complaint/' + complaintId + '/decision/download', true);
  }

  saveFile(request: ComplaintFile): Observable<SaveComplaintFile$Response> {
    return this.api.complaint.saveComplaintFile(request);
  }

  submit(id: number): Observable<SubmitComplaint$Response> {
    return this.api.complaint.submitComplaint(id);
  }

  solve(complaintDecisionRequest: ComplaintDecisionRequest): Observable<MarkComplaintAsSolved$Response> {
    return this.api.complaint.markComplaintAsSolved(complaintDecisionRequest);
  }

  delete(id: number): Observable<DeleteComplaint$Response> {
    return this.api.complaint.deleteComplaint(id);
  }

  refreshCurrentPage() {
    this.pages[this._serviceData.currentPage] = [];
  }

  deleteFile(complaintId: number, id: number): Observable<DeleteComplaintFile$Response> {
    return this.api.complaint.deleteComplaintFile(complaintId, id);
  }

  reject(complaintDecisionRequest: ComplaintDecisionRequest): Observable<RejectComplaint$Response> {
    return this.api.complaint.rejectComplaint(complaintDecisionRequest);
  }

  deleteByEstiko(complaintId: number): Observable<any> {
    return this.api.complaint.deleteComplaintEstiko(complaintId);
  }
}
