import {Routes} from '@angular/router';

import {AuthGuard} from './services/auth-guard.service';
import {LoginComponent} from './auth/login/login.component';
import {InvitationComponent} from './auth/invitation/invitation.component';
import {PasswordRecoveryComponent} from './auth/password-recovery/password-recovery.component';

export const AppRoutes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'invitation/:token', component: InvitationComponent},
  {path: 'resetpassword', component: PasswordRecoveryComponent},
  {path: 'users', loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard]},
  {
    path: 'customer',
    loadChildren: () => import('./components/customer/customers.module').then(m => m.CustomersModule),
    canActivate: [AuthGuard]
  },
  {path: 'designs', loadChildren: () => import('./components/designs/designs.module').then(m => m.DesignsModule), canActivate: [AuthGuard]},
  {
    path: 'complaints',
    loadChildren: () => import('./components/complaints/complaints.module').then(m => m.ComplaintsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inventory',
    loadChildren: () => import('./components/inventory/inventory.module').then(m => m.InventoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'templates',
    loadChildren: () => import('./components/mail-template/mail-template.module').then(m => m.TemplatesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase-order',
    loadChildren: () => import('./components/purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'products',
    loadChildren: () => import('./components/products/products.module').then(m => m.ProductsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'call-off',
    loadChildren: () => import('./components/call-off/call-off.module').then(m => m.CallOffModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'standard-products',
    loadChildren: () => import('./components/standard-products/standard-products.module').then(m => m.StandardProductsModule),
    canActivate: [AuthGuard]
  },
  {path: 'style', loadChildren: () => import('./components/style-proto/style-proto.module').then(m => m.StyleProtoModule)},
  {
    path: 'app-settings',
    loadChildren: () => import('./components/app-settings/app-settings.module').then(m => m.AppSettingsModule),
    canActivate: [AuthGuard]
  },
  {path: '**', redirectTo: '', pathMatch: 'full'}

];
