import * as tslib_1 from "tslib";
import { FormArray, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToDatePipe } from '../pipes/date.pipe';
var ValidationService = /** @class */ (function () {
    function ValidationService(toDatePipe) {
        this.toDatePipe = toDatePipe;
    }
    ValidationService.prototype.addCustomValErrors = function (form, responseErrors, formErrors) {
        var e_1, _a;
        if (!responseErrors) {
            return;
        }
        try {
            for (var responseErrors_1 = tslib_1.__values(responseErrors), responseErrors_1_1 = responseErrors_1.next(); !responseErrors_1_1.done; responseErrors_1_1 = responseErrors_1.next()) {
                var err = responseErrors_1_1.value;
                var control = form.controls[err.field];
                if (control) {
                    var errors = control.errors;
                    if (!errors) {
                        errors = {};
                    }
                    errors[err.errorCode] = err.errorCode;
                    control.setErrors(errors);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (responseErrors_1_1 && !responseErrors_1_1.done && (_a = responseErrors_1.return)) _a.call(responseErrors_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.parseFormErrors(form, formErrors);
    };
    ValidationService.prototype.addFormErrors = function (form, errors) {
        var _this = this;
        form.valueChanges.pipe(debounceTime(10)).subscribe(function () {
            _this.parseFormErrors(form, errors);
        });
        form.statusChanges.pipe(debounceTime(10)).subscribe(function () {
            _this.parseFormErrors(form, errors);
        });
    };
    ValidationService.prototype.parseFormErrors = function (form, errors) {
        for (var control in form.controls) {
            var ac = form.controls[control];
            errors[control] = {};
            if (ac instanceof FormArray || ac instanceof FormGroup) {
                for (var nControl in ac.controls) {
                    var nAc = ac.controls[nControl];
                    if (nAc.controls) {
                        for (var nnControl in nAc.controls) {
                            var nnAc = nAc.controls[nnControl];
                            errors[control + nControl + '.' + nnControl] = {}; // remove previous errors
                            this.addFormControlErrors(nnAc, control + nControl + '.' + nnControl, errors);
                        }
                    }
                    else {
                        errors[control + '.' + nControl] = {}; // remove previous errors
                        this.addFormControlErrors(nAc, control + '.' + nControl, errors);
                    }
                }
            }
            else {
                this.addFormControlErrors(ac, control, errors);
            }
        }
    };
    ValidationService.prototype.addFormControlErrors = function (ac, control, errors) {
        if (ac.dirty && !ac.valid) {
            var fieldErrors = {};
            for (var err in ac.errors) {
                var errObj = ac.errors[err];
                var param = void 0;
                if (err === 'maxlength' || err === 'minlength') {
                    param = errObj.requiredLength;
                }
                else if (err === 'max') {
                    param = errObj.max;
                }
                else if (err === 'min') {
                    param = errObj.min;
                }
                else if (err === 'greaterThan') {
                    param = errObj.min;
                }
                else if (err === 'bsDate') {
                    var error = ac.errors[err];
                    err = '';
                    var minDate = error['minDate'];
                    var maxDate = error['maxDate'];
                    if (minDate) {
                        err = 'minDate';
                        param = this.toDatePipe.transform(minDate);
                    }
                    else if (maxDate) {
                        err = 'maxDate';
                        param = this.toDatePipe.transform(maxDate);
                    }
                    else {
                        err = 'invalidDate';
                    }
                }
                fieldErrors[err] = {
                    errorCode: err,
                    parameter: param
                };
            }
            errors[control] = fieldErrors;
        }
    };
    ValidationService.prototype.markFormAsDirty = function (form) {
        var _this = this;
        if (!form || !form.controls) {
            return;
        }
        Object.keys(form.controls).map(function (e) { return form.controls[e]; }).forEach(function (control) {
            control.markAsTouched();
            control.markAsDirty();
            if (control.controls) {
                if (control instanceof FormGroup) {
                    Object.keys(control.controls).map(function (key) { return control.controls[key]; }).map(function (c) {
                        c.markAsTouched();
                        c.markAsDirty();
                        c.updateValueAndValidity({ emitEvent: true });
                    });
                }
                else {
                    control.controls.forEach(function (c) { return _this.markFormAsDirty(c); });
                }
            }
            else {
                control.updateValueAndValidity({ emitEvent: true });
            }
        });
    };
    ValidationService.prototype.subscribeControlValueChanges = function (form, control, method, debounceT) {
        return form.controls[control].valueChanges.pipe(debounceTime(debounceT), distinctUntilChanged()).subscribe(function (res) {
            method();
        });
    };
    ValidationService.prototype.subscribeValueChanges = function (form, controls, method, debounceT) {
        var e_2, _a;
        var arr = [];
        try {
            for (var controls_1 = tslib_1.__values(controls), controls_1_1 = controls_1.next(); !controls_1_1.done; controls_1_1 = controls_1.next()) {
                var control = controls_1_1.value;
                arr.push(this.subscribeControlValueChanges(form, control, method, debounceT));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (controls_1_1 && !controls_1_1.done && (_a = controls_1.return)) _a.call(controls_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return arr;
    };
    return ValidationService;
}());
export { ValidationService };
