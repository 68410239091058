import {Pipe, PipeTransform} from '@angular/core';
import {Lang, LangValues} from '../../api/api-types';

@Pipe({
  name: 'unitPipe'
})
export class UnitPipe implements PipeTransform {
  transform(unit: string, lang: Lang = LangValues.EN): string {
    if (lang === LangValues.EN) {
      switch (unit) {
        case 'kg':
          return 'units.kg';
        case 'jm':
          return 'units.jm';
        case 'tk':
          return 'units.tk';
        case 'rull':
          return 'units.rull'
      }
    }
    return unit;
  }
}
