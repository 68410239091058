import {Injectable} from '@angular/core';
import {KeyVal, OnClean, PurchasesServiceData} from '../custom-interfaces';
import {
  AddPurchaseOrderProduct$Response,
  AddPurchaseOrderProductRequest,
  ApiResponseMetadata,
  ChangePurchaseOrderStatusToConfirmed$Response,
  CreatePurchaseOrder$Response,
  DeclineSalesOrder$Response,
  DeletePurchaseOrder$Response,
  DeletePurchaseOrderEstiko$Response,
  DeletePurchaseOrderProduct$Response,
  GetComments$Response,
  GetProductList$Response,
  GetPurchaseOrder$Response,
  GetPurchaseOrderListRequest,
  GetPurchaseOrderProductsList$Response,
  GetPurchaseOrders$Response,
  GetSalesOrderProductsList$Response,
  GetSalesOrderProductsLiveList$Response, GetSalesOrderProductsQuoteList$Response,
  GetStandardProductsList$Response,
  Lang,
  ProductListRow,
  PurchaseOrderListResource,
  PurchaseOrderOrderBy,
  PurchaseOrderOrderByValues,
  RequestPurchaseOrder$Response,
  SavePurchaseOrderCommentRequest,
  SortOrder,
  SortOrderValues,
  UpdatePurchaseOrder$Response,
  UpdatePurchaseOrderProduct$Response,
  UpdatePurchaseOrderProductRequest,
  UpdatePurchaseOrderRequest
} from '../api/api-types';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {ApiService} from '../api/api-service';
import {ApiClient} from '../api/api-client';
import {HttpResponse} from '@angular/common/http';
import moment from 'moment';
import {toTimeZone} from '../common/DateUtil';
import {requestParamsToUrl} from '../common/ApiUtil';

@Injectable()
export class PurchaseOrderService implements OnClean {

  private pages: { [key: number]: PurchaseOrderListResource[] };
  private metadata: ApiResponseMetadata = new ApiResponseMetadata;
  private purchaseListSubject: Subject<GetPurchaseOrders$Response> = new ReplaySubject<GetPurchaseOrders$Response>(null);

  private customerProducts: { [customerId: number]: ProductListRow[] };
  private standardProducts: ProductListRow[] = [];
  private customerProductsListSubject: Subject<GetProductList$Response> = new ReplaySubject<GetProductList$Response>();
  private standardProductsListSubject: Subject<GetStandardProductsList$Response> = new ReplaySubject<GetStandardProductsList$Response>();
  private customerProductsMetadata: ApiResponseMetadata = new ApiResponseMetadata;


  private _serviceData: PurchasesServiceData;

  constructor(private api: ApiService, private apiClient: ApiClient) {
    this.pages = {};
    this.customerProducts = {};
    this.initServiceData();
  }

  private doCount(purchaseOrderNum: string, status, customerId: number, showAll: boolean, myOrders: boolean): boolean {
    return this._serviceData.totalCount === 0
        || this._serviceData.purchaseOrderNum !== purchaseOrderNum
        || this._serviceData.customerId !== customerId
        || this._serviceData.status !== status
        || this._serviceData.showAll !== showAll
        || this._serviceData.myOrders !== myOrders;
  }

  getCustomerProducts(customerId: number): Observable<GetProductList$Response> {
    let forceFetch = false;
    if (!this.customerProducts[customerId] || this.customerProducts[customerId].length === 0) {
      forceFetch = true;
    }
    if (forceFetch) {
      this.customerProductsListSubject = new ReplaySubject<GetProductList$Response>(null);

      this.api.products.getActiveProducts(requestParamsToUrl([new KeyVal('customerId', customerId)])).subscribe(res => {
        if (res && res.data) {
          this.customerProducts[customerId] = res.data;
        }
        if (res && res.metadata) {
          this.customerProductsMetadata = res.metadata;
        }
        this.customerProductsListSubject.next({
          data: this.customerProducts[customerId],
          metadata: this.customerProductsMetadata
        });
        this._serviceData.selectedCustomerId = customerId;
      }, (err) => {
        this.customerProductsListSubject.error(err);
      });
    } else {
      this.customerProductsListSubject.next({
        data: this.customerProducts[customerId],
        metadata: this.customerProductsMetadata
      });
      this._serviceData.selectedCustomerId = customerId;
    }
    this._serviceData.selectedCustomerId = customerId;
    return this.customerProductsListSubject;
  }

  getStandardProducts(customerId: number): Observable<GetStandardProductsList$Response> {
    let forceFetch = false;
    if (this.standardProducts.length === 0 || customerId !== this._serviceData.selectedCustomerId) {
      forceFetch = true;
    }
    if (forceFetch) {
      this.standardProductsListSubject = new ReplaySubject<GetProductList$Response>(null);

      this.api.products.getStandardProductsList().subscribe(res => {
        if (res && res.data) {
          this.standardProducts = res.data;
        }
        if (res && res.metadata) {
          this.customerProductsMetadata = res.metadata;
        }
        this.standardProductsListSubject.next({
          data: this.standardProducts,
          metadata: this.customerProductsMetadata
        });
      }, (err) => {
        this.standardProductsListSubject.error(err);
      });
    } else {
      this.standardProductsListSubject.next({
        data: this.standardProducts,
        metadata: this.customerProductsMetadata
      });
    }
    return this.standardProductsListSubject;
  }

  getPurchases(purchaseOrderNum: string = null, status = null, customerId: number = null, orderBy: PurchaseOrderOrderBy,
               order: SortOrder, pageNum: number, showAll: boolean, myOrders: boolean): Observable<GetPurchaseOrders$Response> {

    let forceFetch = false;
    if (this._serviceData.purchaseOrderNum !== purchaseOrderNum
        || this._serviceData.status !== status
        || this._serviceData.customerId !== customerId
        || this._serviceData.listOrderBy !== orderBy
        || this._serviceData.listSortOrder !== order
        || this._serviceData.showAll !== showAll
        || this._serviceData.myOrders !== myOrders) {
      forceFetch = true;
      this.pages = {};
    } else if (!this.pages[pageNum] || this.pages[pageNum].length === 0) {
      forceFetch = true;
    }

    if (forceFetch) {
      const cal = this.calculateOffsetAndLimit(pageNum);
      this.purchaseListSubject.unsubscribe();
      this.purchaseListSubject = new ReplaySubject<GetPurchaseOrders$Response>(null);
      const request: GetPurchaseOrderListRequest = {
        customerId: customerId ? customerId : undefined,
        purchaseOrderNum: purchaseOrderNum ? purchaseOrderNum : undefined,
        status: status ? status : undefined,
        orderBy: orderBy ? orderBy : undefined,
        order: order ? order : undefined,
        showAll: showAll ? showAll : false,
        myOrders: myOrders ? myOrders : false,
        offset: cal.offset,
        limit: cal.limit,
        count: this.doCount(purchaseOrderNum, status, customerId, showAll, myOrders)
      };

      this.api.purchaseOrder.getPurchaseOrders(request).subscribe(res => {
        if (res && res.data) {
          this.pages[pageNum] = res.data;
        }
        if (res && res.metadata) {
          this.metadata.limit = res.metadata.limit;
          this.metadata.offset = res.metadata.offset;
          if (res.metadata.totalCount != null) {
            this.metadata.totalCount = res.metadata.totalCount;
            this._serviceData.totalCount = res.metadata.totalCount;
          }
        }

        this.purchaseListSubject.next({data: this.pages[pageNum], metadata: this.metadata});
        this._serviceData.currentPage = pageNum;
      });

    } else {
      this.purchaseListSubject.next({data: this.pages[pageNum], metadata: this.metadata});
      this._serviceData.currentPage = pageNum;
    }

    this._serviceData.customerId = customerId;
    this._serviceData.listOrderBy = orderBy;
    this._serviceData.listSortOrder = order;
    this._serviceData.purchaseOrderNum = purchaseOrderNum;
    this._serviceData.status = status;
    this._serviceData.showAll = showAll;
    this._serviceData.myOrders = myOrders;

    return this.purchaseListSubject;
  }

  private calculateOffsetAndLimit(page: number): any {
    const offset = (page - 1) * this._serviceData.rowsPerPage;
    const limit = offset + this._serviceData.rowsPerPage;
    return {offset, limit};
  }

  createPurchaseOrder(customerId: number): Observable<CreatePurchaseOrder$Response> {
    return this.api.purchaseOrder.createPurchaseOrder(customerId);
  }

  addPurchaseOrderProduct(purchaseOrderId: number, productId: number, quantity: number, unit: string, requestedDelivery: string, keepInStock: boolean):
      Observable<AddPurchaseOrderProduct$Response> {

    const request: AddPurchaseOrderProductRequest = {
      productId: productId ? productId : null,
      quantity: quantity ? quantity : null,
      unit: unit ? unit : null,
      keepInStock: keepInStock ? keepInStock : null,
      date: requestedDelivery ? toTimeZone(moment(requestedDelivery, 'DD/MM/YYYY').add(4, 'hours')).toISOString() : null
    };

    return this.api.purchaseOrder.addPurchaseOrderProduct(purchaseOrderId, request);
  }

  getPurchaseOrder(id: number): Observable<GetPurchaseOrder$Response> {
    return this.api.purchaseOrder.getPurchaseOrder(id);
  }

  getPurchaseOrderProductsList(id: number): Observable<GetPurchaseOrderProductsList$Response> {
    return this.api.purchaseOrder.getPurchaseOrderProductsList(id);
  }

  getServiceData(): PurchasesServiceData {
    return this._serviceData;
  }


  updatePurchaseOrder(purchaseOrderId: number, addressId: number, purchaseOrderNum: string): Observable<UpdatePurchaseOrder$Response> {
    const request: UpdatePurchaseOrderRequest = {
      addressId,
      purchaseOrderNum
    };

    return this.api.purchaseOrder.updatePurchaseOrder(purchaseOrderId, request);
  }

  updatePurchaseOrderProduct(orderId: number, orderProductId: number, quantity: number, unit: string, date: string, keepInStock: boolean):
      Observable<UpdatePurchaseOrderProduct$Response> {
    return this.api.purchaseOrder.updatePurchaseOrderProduct(orderId, orderProductId, {
      quantity,
      unit,
      date,
      keepInStock
    } as UpdatePurchaseOrderProductRequest);
  }

  deletePurchaseOrderProduct(orderId: number, purchaseOrderProductId: number): Observable<DeletePurchaseOrderProduct$Response> {
    return this.api.purchaseOrder.deletePurchaseOrderProduct(orderId, purchaseOrderProductId);
  }

  clean(): void {
    this.pages = {};
    this.metadata.offset = undefined;
    this.metadata.limit = undefined;
    this.metadata.totalCount = undefined;
    this.initServiceData();
  }

  refreshCurrentPage() {
    this.pages[this._serviceData.currentPage] = [];
  }

  private initServiceData() {
    this._serviceData = {
      listOrderBy: PurchaseOrderOrderByValues.created_at,
      listSortOrder: SortOrderValues.DESC,
      purchaseOrderNum: null,
      customerId: null,
      status: '',
      showAll: false,
      myOrders: false,
      totalCount: 0,
      rowsPerPage: 25,
      currentPage: 1,
      selectedCustomerId: null
    };
  }

  clearPages(): void {
    this.pages = {};
    this.metadata.totalCount = undefined;
  }

  decline(purchaseOrderId: number, request: SavePurchaseOrderCommentRequest): Observable<DeclineSalesOrder$Response> {
    return this.api.purchaseOrder.declineSalesOrder(purchaseOrderId, request);
  }

  changePurchaseOrderStatusToConfirmed(purchaseOrderId: number): Observable<ChangePurchaseOrderStatusToConfirmed$Response> {
    return this.api.purchaseOrder.changePurchaseOrderStatusToConfirmed(purchaseOrderId);
  }

  getSalesProductsLiveList(purchaseOrderId: number): Observable<GetSalesOrderProductsLiveList$Response> {
    return this.api.purchaseOrder.getSalesOrderProductsLiveList(purchaseOrderId);
  }

  getSalesOrderProductList(purchaseOrderId: number): Observable<GetSalesOrderProductsList$Response> {
    return this.api.purchaseOrder.getSalesOrderProductsList(purchaseOrderId);
  }

  getSalesOrderProductsQuoteList(purchaseOrderId: number): Observable<GetSalesOrderProductsQuoteList$Response> {
    return this.api.purchaseOrder.getSalesOrderProductsQuoteList(purchaseOrderId);
  }

  sendOrder(purchaseOrderId: number, comment: string): Observable<RequestPurchaseOrder$Response> {
    return this.api.purchaseOrder.requestPurchaseOrder(purchaseOrderId, {content: comment});
  }

  deletePurchaseOrder(purchaseOrderId: number): Observable<DeletePurchaseOrder$Response> {
    return this.api.purchaseOrder.deletePurchaseOrder(purchaseOrderId);
  }

  getTerms(lang: Lang): Observable<HttpResponse<Blob>> {
    return this.apiClient.getTerms(lang);
  }

  deletePurchaseOrderEstiko(purchaseOrderId: number): Observable<DeletePurchaseOrderEstiko$Response> {
    return this.api.purchaseOrder.deletePurchaseOrderEstiko(purchaseOrderId);
  }

  getComments(purchaseOrderId: number): Observable<GetComments$Response> {
    return this.api.purchaseOrder.getComments(purchaseOrderId);
  }

}
