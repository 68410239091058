import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/notification/notification.service';
import {requiredNoSpaceValidator} from '../../shared/validation/required-no-space.validator';
import {ApiError, LangValues} from '../../api/api-types';
import {ErrorService} from '../../api/error-service';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {FormValidationErrors} from '../../custom-interfaces';
import {ValidationService} from '../../shared/validation/validation.service';
import {BsModalService, BsModalRef} from 'ngx-bootstrap';
import {CustomValidator} from '../../shared/validation/custom.validator';
import {AuthService} from '../../services/auth.service';

@Component({
  templateUrl: './invitation.component.html'
})
export class InvitationComponent implements OnInit, OnDestroy {

  token: string;
  loading = false;
  username: string;
  part1Params = {
    regNumber: '____',
    customerName: '____',
    customerUserName: '____',
    customerAddress: '____',
    managerUserName: '____'
  };
  modalRef: BsModalRef;
  invitationForm: FormGroup;
  formErrors: FormValidationErrors = {};
  private subscriptions = new Subscription();
  @ViewChild('successModal', {static: true}) successModal: TemplateRef<any>;

  constructor(private router: Router,
              private fb: FormBuilder,
              private authService: AuthService,
              private errorService: ErrorService,
              private modalService: BsModalService,
              private activatedRoute: ActivatedRoute,
              private validationService: ValidationService,
              private notificationService: NotificationService) {

    this.invitationForm = this.fb.group({
      password1: ['', [requiredNoSpaceValidator, Validators.minLength(6), Validators.maxLength(20)]],
      password2: ['', [requiredNoSpaceValidator, Validators.minLength(6), Validators.maxLength(20)]],
      agreement: [false, [Validators.requiredTrue]]
    }, {
      validator: CustomValidator.passwordConfirm
  });
  }

  ngOnInit(): void {
    this.authService.useLang(LangValues.EN);
    this.validationService.addFormErrors(this.invitationForm, this.formErrors);
    this.subscriptions.add(this.activatedRoute.paramMap.subscribe((params: any) => {
      const token: string = params.params['token'];
      if (token) {
        this.token = token;
        this.validateToken(this.token);
      } else {
        this.notificationService.error('auth.notifications.wrongParameters', true, true);
        this.router.navigate(['/login'], {});
      }
    }));
  }

  private parseToken(): void {
    try {
      const data = JSON.parse(window.atob(this.token));
      if (data && data['lang']) {
        const lang: string = data['lang'];
        this.authService.useLang(lang === LangValues.ET ? LangValues.ET : LangValues.EN);
      }
    } catch (e) {}
  }

  public setPassword(): void {
    if (this.invitationForm.valid) {
      this.authService.setPassword(this.username, this.token, this.invitationForm.controls['password1'].value)
      .pipe(take(1)).subscribe(() => {
        this.openModal(this.successModal);
      }, (error: ApiError) => {
        this.errorService.handleError(error, 'auth.notifications.passwordSetFailed');
      });
    } else {
      this.validationService.markFormAsDirty(this.invitationForm);
    }
  }
  
  private validateToken(token: string): void {
    this.loading = true;
    this.authService.validateToken(token).pipe(take(1)).subscribe(res => {
      this.authService.logOut(false);
      this.parseToken();
      this.username = res.data.userEmail;
      this.loading = false;
      this.part1Params = {
        regNumber: res.data.regCode,
        customerName: res.data.customerName,
        customerUserName: res.data.userName,
        customerAddress: res.data.customerAddress,
        managerUserName: res.data.managerUserName
      };
    }, (error: ApiError) => {
      this.loading = false;
      this.errorService.handleError(error, 'auth.notifications.passwordSetFailed', undefined, this.invitationForm, this.formErrors);
      setTimeout(() => {
        this.router.navigate(['/login'], {});
      }, 2000);
    });
  }

  public openModal(template, large?: boolean | false): void {
    this.modalRef = this.modalService.show(template, {class: large ? 'modal-lg' : 'modal-md'});
  }

  public closeModal(): void {
    this.modalRef ? this.modalRef.hide() : null;
  }

  public closeAndNavigate(): void {
    this.closeModal();
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.closeModal();
  }

}
