import {KeyVal} from '../custom-interfaces';
import {HttpParams} from '@angular/common/http';

export function requestParamsToUrl(params: KeyVal[]): string {
  let httpParams = new HttpParams();
  params.filter(r => !!r.key && !!r.value).forEach(r => {
    httpParams = httpParams.append(r.key, r.value)
  });
  return '?' + httpParams.toString();
}
