import {AbstractControl} from '@angular/forms';
import moment from 'moment';
import {localMoment} from '../../common/DateUtil';

export class StandardProductOrderDateValidator {
  static dateValidator(AC: AbstractControl) {
    const minDate = localMoment().hour() >= 10 ?
        localMoment().add(2 + Math.floor((Math.min(moment().day(), 5) + 2) / 6) * 2, 'd').toDate() :
        localMoment().add(1 + Math.floor((Math.min(moment().day(), 5) + 1) / 6) * 2, 'd').toDate();


    if (AC && AC.value && !moment(moment(AC.value).format('l')).isSameOrAfter(moment(minDate).format('l'))) {
      return {'dateValidator': true};
    }
    return null;
  }

}
