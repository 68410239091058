import {Injectable} from '@angular/core';
import {ApiService} from '../api/api-service';
import {Observable} from 'rxjs';
import {GetApplicationSettings$Response, GetLatestEmails$Response, ToggleEmail$Response} from '../api/api-types';
import {OnClean} from '../custom-interfaces';

@Injectable()
export class AppSettingsService implements OnClean {

  constructor(private apiService: ApiService) {
  }

  clean(): void {
  }

  public toggleEmails(status: boolean): Observable<ToggleEmail$Response> {
    return this.apiService.application.toggleEmail(status);
  }

  public getApplicationSettings(): Observable<GetApplicationSettings$Response> {
    return this.apiService.application.getApplicationSettings();
  }

  public getEmails(): Observable<GetLatestEmails$Response> {
    return this.apiService.application.getLatestEmails();
  }

  public deleteLog(): Observable<GetLatestEmails$Response> {
    return this.apiService.application.getLatestEmails();
  }
}
