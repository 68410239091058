import * as tslib_1 from "tslib";
import { AxSalesStatusValues, CommonProductListRow, InventoryListRow, SalesOrderProductListResource, SortOrderValues } from '../../api/api-types';
import { ListSortOrder } from '../../custom-interfaces';
import { localMoment } from '../DateUtil';
var InventoryFilterData = /** @class */ (function () {
    function InventoryFilterData() {
        this.startDate = localMoment().subtract(1, 'year').toDate();
        this.endDate = localMoment().add(1, 'year').toDate();
        this.includeClosed = false;
        this.showAll = false;
        this.inventorySortOrder = new ListSortOrder('salesOrderId', SortOrderValues.DESC);
    }
    return InventoryFilterData;
}());
export { InventoryFilterData };
var InventoryListCallOffRow = /** @class */ (function (_super) {
    tslib_1.__extends(InventoryListCallOffRow, _super);
    function InventoryListCallOffRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.callOffQuantity = undefined;
        _this.callOffDate = undefined;
        return _this;
    }
    return InventoryListCallOffRow;
}(InventoryListRow));
export { InventoryListCallOffRow };
var CommonProductListOrderRow = /** @class */ (function (_super) {
    tslib_1.__extends(CommonProductListOrderRow, _super);
    function CommonProductListOrderRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.orderDeliveryDate = undefined;
        _this.orderQuantity = undefined;
        return _this;
    }
    return CommonProductListOrderRow;
}(CommonProductListRow));
export { CommonProductListOrderRow };
var CallOffFilterData = /** @class */ (function () {
    function CallOffFilterData() {
        this.startDate = localMoment().subtract(1, 'year').toDate();
        this.endDate = localMoment().add(1, 'year').toDate();
        this.status = AxSalesStatusValues.ALL;
        this.callOffSortOrder = new ListSortOrder('createdAt', SortOrderValues.DESC);
    }
    return CallOffFilterData;
}());
export { CallOffFilterData };
var StandardProductsFilter = /** @class */ (function () {
    function StandardProductsFilter() {
    }
    return StandardProductsFilter;
}());
export { StandardProductsFilter };
var SalesOrderProductListResourceExtended = /** @class */ (function (_super) {
    tslib_1.__extends(SalesOrderProductListResourceExtended, _super);
    function SalesOrderProductListResourceExtended() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SalesOrderProductListResourceExtended;
}(SalesOrderProductListResource));
export { SalesOrderProductListResourceExtended };
