<div id="notifications" class="notifications">
  <div *ngFor="let notification of notifications.reverse()" class="{{ cssClass(notification) }}">
    {{notification.message | translate}}
    <a *ngIf="notification.type !== NewApiVersionType"
       aria-label="close"
       data-dismiss="alert"
       class="close"
       (click)="removeAlert(notification)">&times;</a>
    <button *ngIf="notification.type === NewApiVersionType"
            style="margin-left: 20px;"
            class="btn btn-bd-warning"
            (click)="refreshPage()"
            translate>api.refreshButton</button>
  </div>
</div>
