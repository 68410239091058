import {Injectable} from '@angular/core';
import {ApiService} from '../api/api-service';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {
  ApiResponseMetadata,
  EvaluateTemplate$Response,
  GetMailTemplatesRequest,
  GetTemplate$Response,
  GetTemplates$Response,
  MailTemplate,
  MailTemplateOrderBy,
  MailTemplateOrderByValues,
  SortOrder,
  SortOrderValues,
  TemplateListRow
} from '../api/api-types';
import {MailTemplateServiceData, OnClean} from '../custom-interfaces';

@Injectable()
export class MailTemplateService implements OnClean {

  private pages: { [key: number]: TemplateListRow[] };
  private metadata: ApiResponseMetadata = new ApiResponseMetadata;
  private templatesListSubject: Subject<GetTemplates$Response> = new ReplaySubject<GetTemplates$Response>(null);

  private _serviceData: MailTemplateServiceData;

  constructor(private api: ApiService) {
    this.pages = {};
    this.initServiceData();
  }

  getTemplates(orderBy: MailTemplateOrderBy, order: SortOrder, pageNum: number): Observable<GetTemplates$Response> {

    let forceFetch = false;
    if (this._serviceData.listOrderBy !== orderBy || this._serviceData.listSortOrder !== order) {
      forceFetch = true;
      this.pages = {};
    } else if (!this.pages[pageNum] || this.pages[pageNum].length === 0) {
      forceFetch = true;
    }

    if (forceFetch) {
      const cal = this.calculateOffsetAndLimit(pageNum);
      const request: GetMailTemplatesRequest = {
        orderBy: orderBy ? orderBy : undefined,
        order: order ? order : undefined,
        offset: cal.offset,
        limit: cal.limit,
        count: this._serviceData.totalCount === 0
      };

      this.templatesListSubject.unsubscribe();
      this.templatesListSubject = new ReplaySubject<GetTemplates$Response>(null);
      this.api.template.getTemplates(request).subscribe(res => {
        if (res && res.data) {
          this.pages[pageNum] = res.data;
        }
        if (res && res.metadata) {
          this.metadata.limit = res.metadata.limit;
          this.metadata.offset = res.metadata.offset;
          if (res.metadata.totalCount) {
            this.metadata.totalCount = res.metadata.totalCount;
            this._serviceData.totalCount = res.metadata.totalCount;
          }
        }

        this.templatesListSubject.next({
          data: this.pages[pageNum],
          metadata: this.metadata
        });
        this._serviceData.currentPage = pageNum;

      });

    } else {
      this.templatesListSubject.next({
        data: this.pages[pageNum],
        metadata: this.metadata
      });
      this._serviceData.currentPage = pageNum;
    }

    this._serviceData.listOrderBy = orderBy;
    this._serviceData.listSortOrder = order;
    return this.templatesListSubject;
  }

  private calculateOffsetAndLimit(page: number): any {
    const offset = (page - 1) * this._serviceData.rowsPerPage;
    const limit = offset + this._serviceData.rowsPerPage;
    return {offset: offset, limit: limit}
  }

  getTemplate(id: number): Observable<GetTemplate$Response> {
    return this.api.template.getTemplate(id);
  }

  evaluateTemplate(mailTemplate: MailTemplate): Observable<EvaluateTemplate$Response> {
    return this.api.template.evaluateTemplate(mailTemplate);
  }

  saveTemplate(mailTemplate: MailTemplate) {
    return this.api.template.saveTemplate(mailTemplate);
  }

  clean(): void {
    this.pages = {};
    this.metadata = new ApiResponseMetadata;
    this.initServiceData();
  }

  initServiceData() {
    this._serviceData = {
      listOrderBy: MailTemplateOrderByValues.type,
      listSortOrder: SortOrderValues.ASC,
      totalCount: 0,
      rowsPerPage: 25,
      currentPage: 1
    }
  }

  getServiceData(): MailTemplateServiceData {
    return this._serviceData;
  }

  invalidateCache() {
    this.pages = {};
    this.metadata.totalCount = 0;
  }
}

