import { KeyVal } from '../custom-interfaces';
var Paginator = /** @class */ (function () {
    function Paginator(rowsPerPage) {
        if (rowsPerPage === void 0) { rowsPerPage = 25; }
        this.currentPage = 1;
        this.rowsPerPage = rowsPerPage;
        this.reset();
    }
    Object.defineProperty(Paginator.prototype, "pageNumber", {
        get: function () {
            return this.currentPage;
        },
        enumerable: true,
        configurable: true
    });
    Paginator.prototype.moveForward = function () {
        this.currentPage++;
    };
    Paginator.prototype.moveTo = function (pageNum) {
        this.currentPage = pageNum;
    };
    Paginator.prototype.moveBackward = function () {
        if (this.currentPage <= 1) {
            return;
        }
        this.currentPage--;
    };
    Object.defineProperty(Paginator.prototype, "numberOfPages", {
        get: function () {
            return Math.max(1, Math.ceil(this.totalCount / this.rowsPerPage));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paginator.prototype, "pageItems", {
        get: function () {
            return this.pages[this.currentPage - 1];
        },
        enumerable: true,
        configurable: true
    });
    Paginator.prototype.reset = function () {
        this.isLoading = false;
        this.pages = {};
        this.totalCount = 0;
        this.currentPage = 1;
    };
    Object.defineProperty(Paginator.prototype, "limit", {
        get: function () {
            return this.currentPage * this.rowsPerPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paginator.prototype, "offset", {
        get: function () {
            return (this.currentPage - 1) * this.rowsPerPage;
        },
        enumerable: true,
        configurable: true
    });
    Paginator.prototype.addPageData = function (data) {
        this.pages[this.currentPage - 1] = data;
    };
    Paginator.prototype.addPages = function (data) {
        this.pages = {};
        if (!!data && data.length) {
            var pageNum = 0;
            for (var index = 0; index < data.length;) {
                var onePage = data.slice(index, index + this.rowsPerPage);
                this.pages[pageNum] = onePage;
                index = index + onePage.length;
                pageNum++;
                if (onePage.length < this.rowsPerPage) {
                    break;
                }
            }
        }
        this.currentPage = 1;
        this.totalCount = data.length;
    };
    Object.defineProperty(Paginator.prototype, "pageData", {
        get: function () {
            return [this.currentPage - 1];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paginator.prototype, "hasPageData", {
        get: function () {
            var list = this.pages[this.currentPage - 1];
            return !!list && !!list.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paginator.prototype, "data", {
        get: function () {
            return { _offset: this.offset, _limit: this.limit };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paginator.prototype, "dataAsKeyVal", {
        get: function () {
            return [
                new KeyVal('_offset', this.offset),
                new KeyVal('_limit', this.limit)
            ];
        },
        enumerable: true,
        configurable: true
    });
    return Paginator;
}());
export { Paginator };
