import {Component, OnDestroy, OnInit, HostListener, ElementRef} from '@angular/core';
import {Lang, LangValues, UserData} from '../../api/api-types';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';


interface LangSelectOption {
  code?: 'gb' | 'ee' | null;
  value?: Lang;
  selected?: boolean;
}

@Component({
  selector: 'language-select',
  styleUrls: ['./language-select.component.scss'],
  templateUrl: './language-select.component.html'
})
export class LanguageSelectComponent implements OnInit, OnDestroy {

  public userData: UserData;
  public isOpen: boolean = false;
  private _languages: LangSelectOption[] = [{code: 'gb', value: LangValues.EN, selected: true}, {code: 'ee', value: LangValues.ET}];
  private subscriptions: Subscription = new Subscription;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  constructor(
      private eRef: ElementRef,
      private authService: AuthService
  ) {
  }

  public get languages() {
    return this._languages.sort((a, b) => {return a.selected ? -1 : 1});
  }

  public chooseAndUpdate(lang: Lang) {
    this.choose(lang);
    this.authService.updateLanguage(lang);
  }

  public toggle() {
    this.isOpen = !this.isOpen;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(this.authService.whoAmI().subscribe(res => {
      this.userData = res;
      if (res && res.lang) {
        this.choose(res.lang);
      }
    }));
  }

  private choose(lang: Lang) {
    this._languages.forEach((v) => v.selected = false);
    this._languages.find((v) => v.value === lang).selected = true;
  }

}
