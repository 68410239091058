import {KeyVal, PaginatorData} from '../custom-interfaces';

export class Paginator {
  private readonly rowsPerPage: number;
  private currentPage: number = 1;

  private pages: { [key: number]: any[] };
  public totalCount: number;
  public isLoading: boolean;

  constructor(rowsPerPage: number = 25) {
    this.rowsPerPage = rowsPerPage;
    this.reset();
  }

  public get pageNumber(): number {
    return this.currentPage;
  }

  public moveForward(): void {
    this.currentPage++;
  }

  public moveTo(pageNum: number): void {
    this.currentPage = pageNum;
  }

  public moveBackward(): void {
    if (this.currentPage <= 1) {
      return;
    }
    this.currentPage--;
  }

  public get numberOfPages(): number {
    return Math.max(1, Math.ceil(this.totalCount / this.rowsPerPage));
  }

  public get pageItems(): any[] {
    return this.pages[this.currentPage - 1];
  }

  public reset(): void {
    this.isLoading = false;
    this.pages = {};
    this.totalCount = 0;
    this.currentPage = 1;
  }

  public get limit(): number {
    return this.currentPage * this.rowsPerPage;
  }

  public get offset(): number {
    return (this.currentPage - 1) * this.rowsPerPage;
  }

  public addPageData(data: any[]): void {
    this.pages[this.currentPage - 1] = data;
  }

  public addPages(data: any[]): void {
    this.pages = {};
    if (!!data && data.length) {
      let pageNum = 0;
      for (let index = 0; index < data.length;) {
        const onePage = data.slice(index, index + this.rowsPerPage);
        this.pages[pageNum] = onePage;

        index = index + onePage.length;
        pageNum++;

        if (onePage.length < this.rowsPerPage) {
          break;
        } 
      }
    }
    this.currentPage = 1;
    this.totalCount = data.length;
  }

  public get pageData(): any[] {
    return [this.currentPage - 1];
  }

  public get hasPageData(): boolean {
    let list = this.pages[this.currentPage - 1];
    return !!list && !!list.length;
  }

  public get data(): PaginatorData {
    return {_offset: this.offset, _limit: this.limit}
  }

  public get dataAsKeyVal(): KeyVal[] {
    return [
      new KeyVal('_offset', this.offset),
      new KeyVal('_limit', this.limit)
    ];
  }
}
