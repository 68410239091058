import { ApiService } from '../api/api-service';
import { ReplaySubject } from 'rxjs';
import { ApiResponseMetadata, ComplaintOrderByValues, SortOrderValues } from '../api/api-types';
import { ComplaintsServiceData } from '../custom-interfaces';
import { ApiClient } from '../api/api-client';
var ComplaintsService = /** @class */ (function () {
    function ComplaintsService(api, apiClient) {
        this.api = api;
        this.apiClient = apiClient;
        this.metadata = new ApiResponseMetadata;
        this.complaintListSubject = new ReplaySubject(null);
        this._serviceData = new ComplaintsServiceData;
        this.pages = {};
        this.initServiceData();
    }
    ComplaintsService.prototype.doCount = function (complaintNumber, purchaseOrderNumber, customerId, managerId) {
        return this._serviceData.totalCount === 0
            || this._serviceData.customerId !== customerId
            || this._serviceData.complaintNumber !== complaintNumber
            || this._serviceData.purchaseOrderNumber !== purchaseOrderNumber
            || this._serviceData.managerId !== managerId
            || this._serviceData.status !== status;
    };
    ComplaintsService.prototype.getComplaints = function (complaintNumber, purchaseOrderNumber, customerId, managerId, status, orderBy, order, pageNum) {
        var _this = this;
        if (complaintNumber === void 0) { complaintNumber = null; }
        if (purchaseOrderNumber === void 0) { purchaseOrderNumber = null; }
        if (customerId === void 0) { customerId = null; }
        if (managerId === void 0) { managerId = null; }
        if (status === void 0) { status = null; }
        var forceFetch = false;
        if (this._serviceData.complaintNumber !== complaintNumber
            || this._serviceData.purchaseOrderNumber !== purchaseOrderNumber
            || this._serviceData.status !== status
            || this._serviceData.customerId !== customerId
            || this._serviceData.managerId !== managerId
            || this._serviceData.listOrderBy !== orderBy
            || this._serviceData.listSortOrder !== order) {
            forceFetch = true;
            this.pages = {};
        }
        else if (!this.pages[pageNum] || this.pages[pageNum].length === 0) {
            forceFetch = true;
        }
        if (forceFetch) {
            var cal = this.calculateOffsetAndLimit(pageNum);
            this.complaintListSubject.unsubscribe();
            this.complaintListSubject = new ReplaySubject(null);
            var request = {
                customerId: customerId ? customerId : undefined,
                salesManagerId: managerId ? managerId : undefined,
                complaintId: complaintNumber ? complaintNumber : undefined,
                purchaseOrderNumber: purchaseOrderNumber ? purchaseOrderNumber : undefined,
                status: status ? status : undefined,
                orderBy: orderBy ? orderBy : undefined,
                order: order ? order : undefined,
                offset: cal.offset,
                limit: cal.limit,
                count: this.doCount(complaintNumber, purchaseOrderNumber, customerId, managerId)
            };
            this.api.complaint.getComplaints(request).subscribe(function (res) {
                if (res && res.data) {
                    _this.pages[pageNum] = res.data;
                }
                if (res && res.metadata) {
                    _this.metadata.limit = res.metadata.limit;
                    _this.metadata.offset = res.metadata.offset;
                    if (res.metadata.totalCount) {
                        _this.metadata.totalCount = res.metadata.totalCount;
                        _this._serviceData.totalCount = res.metadata.totalCount;
                    }
                }
                _this.complaintListSubject.next({
                    data: _this.pages[pageNum],
                    metadata: _this.metadata
                });
                _this._serviceData.currentPage = pageNum;
            }, function (err) {
                _this.complaintListSubject.error(err);
            });
        }
        else {
            this.complaintListSubject.next({
                data: this.pages[pageNum],
                metadata: this.metadata
            });
            this._serviceData.currentPage = pageNum;
        }
        this._serviceData.complaintNumber = complaintNumber;
        this._serviceData.purchaseOrderNumber = purchaseOrderNumber;
        this._serviceData.customerId = customerId;
        this._serviceData.managerId = managerId;
        this._serviceData.status = status;
        this._serviceData.listOrderBy = orderBy;
        this._serviceData.listSortOrder = order;
        return this.complaintListSubject;
    };
    ComplaintsService.prototype.calculateOffsetAndLimit = function (page) {
        var offset = (page - 1) * this._serviceData.rowsPerPage;
        var limit = offset + this._serviceData.rowsPerPage;
        return { offset: offset, limit: limit };
    };
    ComplaintsService.prototype.saveComplaint = function (request) {
        return this.api.complaint.saveComplaint(request);
    };
    ComplaintsService.prototype.getComplaint = function (id) {
        return this.api.complaint.getComplaint(id);
    };
    ComplaintsService.prototype.getComplaintFiles = function (id) {
        return this.api.complaint.getComplaintFiles(id);
    };
    ComplaintsService.prototype.clean = function () {
        this.pages = {};
        this.metadata.offset = undefined;
        this.metadata.limit = undefined;
        this.metadata.totalCount = undefined;
        this.initServiceData();
    };
    ComplaintsService.prototype.initServiceData = function () {
        this._serviceData.listOrderBy = ComplaintOrderByValues.modified_at;
        this._serviceData.listSortOrder = SortOrderValues.DESC;
        this._serviceData.customerId = null;
        this._serviceData.managerId = null;
        this._serviceData.complaintNumber = null;
        this._serviceData.purchaseOrderNumber = null;
        this._serviceData.status = '';
        this._serviceData.totalCount = 0;
        this._serviceData.rowsPerPage = 25;
        this._serviceData.currentPage = 1;
        this._serviceData.showAll = false;
    };
    Object.defineProperty(ComplaintsService.prototype, "serviceData", {
        get: function () {
            return this._serviceData;
        },
        enumerable: true,
        configurable: true
    });
    ComplaintsService.prototype.getFileData = function (complaintId, fileId) {
        return this.apiClient.getFile('api/complaint/' + complaintId + '/file/' + fileId, true);
    };
    ComplaintsService.prototype.getComplaintDecisionFileData = function (complaintId) {
        return this.apiClient.getFile('api/complaint/' + complaintId + '/decision/download', true);
    };
    ComplaintsService.prototype.saveFile = function (request) {
        return this.api.complaint.saveComplaintFile(request);
    };
    ComplaintsService.prototype.submit = function (id) {
        return this.api.complaint.submitComplaint(id);
    };
    ComplaintsService.prototype.solve = function (complaintDecisionRequest) {
        return this.api.complaint.markComplaintAsSolved(complaintDecisionRequest);
    };
    ComplaintsService.prototype.delete = function (id) {
        return this.api.complaint.deleteComplaint(id);
    };
    ComplaintsService.prototype.refreshCurrentPage = function () {
        this.pages[this._serviceData.currentPage] = [];
    };
    ComplaintsService.prototype.deleteFile = function (complaintId, id) {
        return this.api.complaint.deleteComplaintFile(complaintId, id);
    };
    ComplaintsService.prototype.reject = function (complaintDecisionRequest) {
        return this.api.complaint.rejectComplaint(complaintDecisionRequest);
    };
    ComplaintsService.prototype.deleteByEstiko = function (complaintId) {
        return this.api.complaint.deleteComplaintEstiko(complaintId);
    };
    return ComplaintsService;
}());
export { ComplaintsService };
