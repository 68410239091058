/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-footer.component";
var styles_AppFooterComponent = [];
var RenderType_AppFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppFooterComponent, data: {} });
export { RenderType_AppFooterComponent as RenderType_AppFooterComponent };
export function View_AppFooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_AppFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "footer", [], null, null, null, View_AppFooterComponent_0, RenderType_AppFooterComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppFooterComponent, [], null, null)], null, null); }
var AppFooterComponentNgFactory = i0.ɵccf("footer", i1.AppFooterComponent, View_AppFooterComponent_Host_0, {}, {}, []);
export { AppFooterComponentNgFactory as AppFooterComponentNgFactory };
