import {ErrorHandler, NgModule} from '@angular/core';
import {APP_BASE_HREF, CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BsDatepickerModule, ModalModule, TooltipModule} from 'ngx-bootstrap';
import {AppRoutes} from './app.routes';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {AuthModule} from './auth/auth.module';
import {ApiService} from './api/api-service';
import {ApiClient} from './api/api-client';
import {ErrorService} from './api/error-service';
import {NotificationService} from './shared/notification/notification.service';
import {ValidationService} from './shared/validation/validation.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LoadingModule} from 'ngx-loading';
import {environment as avi} from '../environments/app-version';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {CustomErrorHandler} from './error-handler';
import {LocalStorageService} from './common/localStorage.service';
import {UsersService} from './services/users.service';
import {ProductsService} from './services/products.service';
import {ProductService} from './services/product.service';
import {AddressService} from './services/address.service';
import {CustomerService} from './services/customer.service';
import {AppSettingsService} from './services/app-settings.service';
import {AuthService} from './services/auth.service';
import {CallOffService} from './services/call-off.service';
import {ComplaintsService} from './services/complaints.service';
import {DesignsService} from './services/designs.service';
import {InventoryService} from './services/inventory.service';
import {MailTemplateService} from './services/mail-template.service';
import {PurchaseOrderService} from './services/purchase-order.service';
import {AppService} from './services/app.service';
import {StandardProductsService} from './services/standard-products.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './locale/i18n/', '.json?app_version=' + avi.APP_VERSION);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    LoadingModule.forRoot({
      backdropBackgroundColour: 'rgba(0,0,0,0.05)',
      primaryColour: '#3260cd',
      secondaryColour: '#3260cd',
      tertiaryColour: '#3260cd'
    }),
    AuthModule,
    SharedModule,
    ModalModule.forRoot(),
    TooltipModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    RouterModule.forRoot(AppRoutes, {useHash: true}),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularSvgIconModule,
  ],
  providers: [
    ApiService,
    ErrorService,
    ApiClient,
    CustomErrorHandler,
    [
      {provide: APP_BASE_HREF, useValue: '/'},
      {provide: ErrorHandler, useClass: CustomErrorHandler}
    ],
    UsersService,
    CustomerService,
    NotificationService,
    ValidationService,
    AddressService,
    LocalStorageService,
    AppSettingsService,
    AuthService,
    CallOffService,
    ComplaintsService,
    DesignsService,
    InventoryService,
    MailTemplateService,
    PurchaseOrderService,
    ProductService,
    ProductsService,
    StandardProductsService,
    AppService
  ],
  exports: [
    RouterModule,
    SharedModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
