/** base class for implementing enums with Typesafe Enum Pattern (to be able to use enum names, instead of ordinal values, in a type-safe manner) */
export class EnumPatternBase {
    constructor(public name: string){}
    toString(){ return this.name; }
}

// ** --ENUMS-- */

export type ActiveFilter = 'ALL' | 'ACTIVE' | 'INACTIVE' ;
export namespace ActiveFilterValues {
    export const ALL: ActiveFilter = 'ALL';
    export const ACTIVE: ActiveFilter = 'ACTIVE';
    export const INACTIVE: ActiveFilter = 'INACTIVE';
}

export type AxSalesStatus = 'ALL' | 'NONE' | 'BACKORDER' | 'DELIVERED' | 'INVOICED' | 'CANCELED' ;
export namespace AxSalesStatusValues {
    export const ALL: AxSalesStatus = 'ALL';
    export const NONE: AxSalesStatus = 'NONE';
    export const BACKORDER: AxSalesStatus = 'BACKORDER';
    export const DELIVERED: AxSalesStatus = 'DELIVERED';
    export const INVOICED: AxSalesStatus = 'INVOICED';
    export const CANCELED: AxSalesStatus = 'CANCELED';
}

export type ComplaintDecisionType = 'RESOLVE' | 'DECLINE' ;
export namespace ComplaintDecisionTypeValues {
    export const RESOLVE: ComplaintDecisionType = 'RESOLVE';
    export const DECLINE: ComplaintDecisionType = 'DECLINE';
}

export type ComplaintOrderBy = 'id' | 'created_at' | 'modified_at' | 'purchase_order_number' | 'quantity' | 'status' ;
export namespace ComplaintOrderByValues {
    export const id: ComplaintOrderBy = 'id';
    export const created_at: ComplaintOrderBy = 'created_at';
    export const modified_at: ComplaintOrderBy = 'modified_at';
    export const purchase_order_number: ComplaintOrderBy = 'purchase_order_number';
    export const quantity: ComplaintOrderBy = 'quantity';
    export const status: ComplaintOrderBy = 'status';
}

export type ComplaintStatus = 'DRAFT' | 'UNDER_REVIEW' | 'SOLVED' | 'REJECTED' ;
export namespace ComplaintStatusValues {
    export const DRAFT: ComplaintStatus = 'DRAFT';
    export const UNDER_REVIEW: ComplaintStatus = 'UNDER_REVIEW';
    export const SOLVED: ComplaintStatus = 'SOLVED';
    export const REJECTED: ComplaintStatus = 'REJECTED';
}

export type CustomerType = 'BUYER' | 'RESELLER' ;
export namespace CustomerTypeValues {
    export const BUYER: CustomerType = 'BUYER';
    export const RESELLER: CustomerType = 'RESELLER';
}

export type DesignOrderBy = 'design_id' | 'name' | 'product_id' | 'item_id' | 'created_at' | 'confirmation_date' | 'status' | 'upload_date' ;
export namespace DesignOrderByValues {
    export const design_id: DesignOrderBy = 'design_id';
    export const name: DesignOrderBy = 'name';
    export const product_id: DesignOrderBy = 'product_id';
    export const item_id: DesignOrderBy = 'item_id';
    export const created_at: DesignOrderBy = 'created_at';
    export const confirmation_date: DesignOrderBy = 'confirmation_date';
    export const status: DesignOrderBy = 'status';
    export const upload_date: DesignOrderBy = 'upload_date';
}

export type DesignStatus = 'DRAFT' | 'WAITING_FOR_APPROVAL' | 'UNDER_REVIEW' | 'CONFIRMED' | 'WAITING_FOR_DIGIPROOF' | 'DIGIPROOF_SENT' | 'DIGIPROOF_CONFIRMED' | 'DELIVERED' | 'STOPPED' | 'ARCHIVED' ;
export namespace DesignStatusValues {
    export const DRAFT: DesignStatus = 'DRAFT';
    export const WAITING_FOR_APPROVAL: DesignStatus = 'WAITING_FOR_APPROVAL';
    export const UNDER_REVIEW: DesignStatus = 'UNDER_REVIEW';
    export const CONFIRMED: DesignStatus = 'CONFIRMED';
    export const WAITING_FOR_DIGIPROOF: DesignStatus = 'WAITING_FOR_DIGIPROOF';
    export const DIGIPROOF_SENT: DesignStatus = 'DIGIPROOF_SENT';
    export const DIGIPROOF_CONFIRMED: DesignStatus = 'DIGIPROOF_CONFIRMED';
    export const DELIVERED: DesignStatus = 'DELIVERED';
    export const STOPPED: DesignStatus = 'STOPPED';
    export const ARCHIVED: DesignStatus = 'ARCHIVED';
}

export type ExceptionType = 'VALIDATION_ERROR' | 'GENERAL_ERROR' | 'INTERNAL_ERROR' | 'ACCESS_DENIED' | 'NOT_FOUND' ;
export namespace ExceptionTypeValues {
    export const VALIDATION_ERROR: ExceptionType = 'VALIDATION_ERROR';
    export const GENERAL_ERROR: ExceptionType = 'GENERAL_ERROR';
    export const INTERNAL_ERROR: ExceptionType = 'INTERNAL_ERROR';
    export const ACCESS_DENIED: ExceptionType = 'ACCESS_DENIED';
    export const NOT_FOUND: ExceptionType = 'NOT_FOUND';
}

export type Lang = 'EN' | 'ET' ;
export namespace LangValues {
    export const EN: Lang = 'EN';
    export const ET: Lang = 'ET';
}

export type MailTemplateOrderBy = 'id' | 'type' | 'lang' | 'subject' | 'modified_at' ;
export namespace MailTemplateOrderByValues {
    export const id: MailTemplateOrderBy = 'id';
    export const type: MailTemplateOrderBy = 'type';
    export const lang: MailTemplateOrderBy = 'lang';
    export const subject: MailTemplateOrderBy = 'subject';
    export const modified_at: MailTemplateOrderBy = 'modified_at';
}

export type MailType = 'CONFIRMED' | 'WAITING_FOR_APPROVAL' | 'WAITING_FOR_APPROVAL_TO_ESTIKO' | 'WAITING_FOR_DIGIPROOF' | 'DIGIPROOF_SENT' | 'DIGIPROOF_SENT_TO_ESTIKO' | 'STOPPED' | 'REOPENED' | 'DECLINED' | 'APPROVE_NOTIFICATION' | 'DIGIPROOF_CONFIRMED_TO_ESTIKO' | 'DIGIPROOF_DECLINED_TO_ESTIKO' | 'COMPLAINT_SENT_FOR_REVIEW' | 'COMPLAINT_REJECTED' | 'COMPLAINT_SOLVED' | 'PURCHASE_ORDER_ORDERED' | 'PURCHASE_ORDER_CONFIRMED' | 'PURCHASE_ORDER_REJECTED' | 'PURCHASE_ORDER_NEW_VERSION' | 'SALES_ORDER_UNCONFIRMED' | 'SALES_ORDER_AUTO_CONFIRMED' | 'SALES_ORDER_ERROR' | 'CALL_OFF' | 'CALL_OFF_TO_CUSTOMER' | 'RECOVER_PASSWORD' | 'INVITATION' | 'ESTIKO_LEPINGUD' | 'REGISTRATION_NOTIFICATION' ;
export namespace MailTypeValues {
    export const CONFIRMED: MailType = 'CONFIRMED';
    export const WAITING_FOR_APPROVAL: MailType = 'WAITING_FOR_APPROVAL';
    export const WAITING_FOR_APPROVAL_TO_ESTIKO: MailType = 'WAITING_FOR_APPROVAL_TO_ESTIKO';
    export const WAITING_FOR_DIGIPROOF: MailType = 'WAITING_FOR_DIGIPROOF';
    export const DIGIPROOF_SENT: MailType = 'DIGIPROOF_SENT';
    export const DIGIPROOF_SENT_TO_ESTIKO: MailType = 'DIGIPROOF_SENT_TO_ESTIKO';
    export const STOPPED: MailType = 'STOPPED';
    export const REOPENED: MailType = 'REOPENED';
    export const DECLINED: MailType = 'DECLINED';
    export const APPROVE_NOTIFICATION: MailType = 'APPROVE_NOTIFICATION';
    export const DIGIPROOF_CONFIRMED_TO_ESTIKO: MailType = 'DIGIPROOF_CONFIRMED_TO_ESTIKO';
    export const DIGIPROOF_DECLINED_TO_ESTIKO: MailType = 'DIGIPROOF_DECLINED_TO_ESTIKO';
    export const COMPLAINT_SENT_FOR_REVIEW: MailType = 'COMPLAINT_SENT_FOR_REVIEW';
    export const COMPLAINT_REJECTED: MailType = 'COMPLAINT_REJECTED';
    export const COMPLAINT_SOLVED: MailType = 'COMPLAINT_SOLVED';
    export const PURCHASE_ORDER_ORDERED: MailType = 'PURCHASE_ORDER_ORDERED';
    export const PURCHASE_ORDER_CONFIRMED: MailType = 'PURCHASE_ORDER_CONFIRMED';
    export const PURCHASE_ORDER_REJECTED: MailType = 'PURCHASE_ORDER_REJECTED';
    export const PURCHASE_ORDER_NEW_VERSION: MailType = 'PURCHASE_ORDER_NEW_VERSION';
    export const SALES_ORDER_UNCONFIRMED: MailType = 'SALES_ORDER_UNCONFIRMED';
    export const SALES_ORDER_AUTO_CONFIRMED: MailType = 'SALES_ORDER_AUTO_CONFIRMED';
    export const SALES_ORDER_ERROR: MailType = 'SALES_ORDER_ERROR';
    export const CALL_OFF: MailType = 'CALL_OFF';
    export const CALL_OFF_TO_CUSTOMER: MailType = 'CALL_OFF_TO_CUSTOMER';
    export const RECOVER_PASSWORD: MailType = 'RECOVER_PASSWORD';
    export const INVITATION: MailType = 'INVITATION';
    export const ESTIKO_LEPINGUD: MailType = 'ESTIKO_LEPINGUD';
    export const REGISTRATION_NOTIFICATION: MailType = 'REGISTRATION_NOTIFICATION';
}

export type ProductOrderBy = 'id' | 'code' | 'name' ;
export namespace ProductOrderByValues {
    export const id: ProductOrderBy = 'id';
    export const code: ProductOrderBy = 'code';
    export const name: ProductOrderBy = 'name';
}

export type PurchaseOrderCommentType = 'CREATION_COMMENT' | 'DECLINE_COMMENT' | 'SYSTEM_COMMENT' ;
export namespace PurchaseOrderCommentTypeValues {
    export const CREATION_COMMENT: PurchaseOrderCommentType = 'CREATION_COMMENT';
    export const DECLINE_COMMENT: PurchaseOrderCommentType = 'DECLINE_COMMENT';
    export const SYSTEM_COMMENT: PurchaseOrderCommentType = 'SYSTEM_COMMENT';
}

export type PurchaseOrderOrderBy = 'purchase_order_num' | 'sales_order_num' | 'status' | 'created_at' ;
export namespace PurchaseOrderOrderByValues {
    export const purchase_order_num: PurchaseOrderOrderBy = 'purchase_order_num';
    export const sales_order_num: PurchaseOrderOrderBy = 'sales_order_num';
    export const status: PurchaseOrderOrderBy = 'status';
    export const created_at: PurchaseOrderOrderBy = 'created_at';
}

export type PurchaseOrderStatus = 'DRAFT' | 'ORDERED' | 'UNCONFIRMED' | 'CONFIRMED' | 'REVIEW' | 'DELIVERED' | 'COMPLETED' | 'ARCHIVED' ;
export namespace PurchaseOrderStatusValues {
    export const DRAFT: PurchaseOrderStatus = 'DRAFT';
    export const ORDERED: PurchaseOrderStatus = 'ORDERED';
    export const UNCONFIRMED: PurchaseOrderStatus = 'UNCONFIRMED';
    export const CONFIRMED: PurchaseOrderStatus = 'CONFIRMED';
    export const REVIEW: PurchaseOrderStatus = 'REVIEW';
    export const DELIVERED: PurchaseOrderStatus = 'DELIVERED';
    export const COMPLETED: PurchaseOrderStatus = 'COMPLETED';
    export const ARCHIVED: PurchaseOrderStatus = 'ARCHIVED';
}

export type SortOrder = 'ASC' | 'DESC' ;
export namespace SortOrderValues {
    export const ASC: SortOrder = 'ASC';
    export const DESC: SortOrder = 'DESC';
}

export type UserCustomerRelationType = 'REPRESENTATIVE' | 'MANAGER' ;
export namespace UserCustomerRelationTypeValues {
    export const REPRESENTATIVE: UserCustomerRelationType = 'REPRESENTATIVE';
    export const MANAGER: UserCustomerRelationType = 'MANAGER';
}

export type UserOrderBy = 'email' | 'name' | 'active' ;
export namespace UserOrderByValues {
    export const email: UserOrderBy = 'email';
    export const name: UserOrderBy = 'name';
    export const active: UserOrderBy = 'active';
}

export type UserType = 'CUSTOMER' | 'ESTIKO' | 'ADMIN' | 'SYSTEM' ;
export namespace UserTypeValues {
    export const CUSTOMER: UserType = 'CUSTOMER';
    export const ESTIKO: UserType = 'ESTIKO';
    export const ADMIN: UserType = 'ADMIN';
    export const SYSTEM: UserType = 'SYSTEM';
}



// ** --INTERFACES-- */

export class ActivateUser$Response {
    data: boolean;
}

export class AdUserUpdate {
    userId: number;
    adUsername: string;
}

export class AddNewComment$Response {
    data: boolean;
}

export class AddNewImageComment$Response {
    data: boolean;
}

export class AddPurchaseOrderProduct$Response {
    data: PurchaseOrderProductListResource;
}

export class AddPurchaseOrderProductRequest {
    productId: number;
    quantity: number;
    unit: string;
    date: string;
    keepInStock: boolean;
}

export class AddressListRow {
    id: number;
    fullAddress: string;
    uses: number;
    customerDefault: boolean;
    isUserInput: boolean;
}

export class ApiError {
    type: ExceptionType;
    errors: ValidationError[];
    code: string;
    notificationShown: boolean;
    description: string;
}

export class ApiResponseMetadata {
    offset: number;
    limit: number;
    totalCount: number;
}

export class ApplicationSettings {
    emailsEnabled: boolean;
}

export class Authenticate$Response {
    data: UserData;
    metadata: JwtTokenWrapper;
}

export class AuthenticationCredentials {
    userName: string;
    password: string;
}

export class AxQuotationLineRow {
    idx: number;
    salesId: string;
    quotationId: string;
    itemId: string;
    itemName: string;
    departureDate: string;
    customerReceiveDate: string;
    salesQty: number;
    salesUnit: string;
    salesPrice: number;
    lineAmount: number;
    inventTransId: string;
    invoicedInTotal: number;
    deliveredInTotal: number;
    changedDate: string;
}

export class CallOffCombinedListResource {
    id: number;
    callOffNr: number;
    customerName: string;
    createdAt: string;
    callOffOrderNumb: string;
    comment: string;
    calculatedStatus: AxSalesStatus;
    callOffProducts: CallOffProductNew[];
}

export class CallOffInventoryProduct {
    id: number;
    productCode: string;
    productName: string;
    unit: string;
    departureDate: string;
    quantity: number;
    salesOrderNumber: string;
    salesOrderRowNum: number;
    purchaseOrderNumber: string;
    inventTransId: string;
    status: AxSalesStatus;
    keepInStock: string;
}

export class CallOffListResource {
    id: number;
    customerName: string;
    orderNumber: string;
    createdAt: string;
    modifiedAt: string;
}

export class CallOffProductNew {
    id: number;
    productNumber: string;
    productName: string;
    salesOrderNumber: string;
    departureDate: string;
    quantity: number;
    status: AxSalesStatus;
}

export class CallOffProductSaveResource {
    unit: string;
    departureDate: string;
    quantity: number;
    salesOrderNumber: string;
    salesOrderRowNum: number;
    productCode: string;
    purchaseOrderNumber: string;
    inventTransId: string;
}

export class CallOffResource {
    id: number;
    customerId: number;
    customerName: string;
    orderNumber: string;
    comment: string;
    createdAt: string;
    modifiedAt: string;
}

export class CallOffSaveResource {
    orderNumber: string;
    comment: string;
    products: CallOffProductSaveResource[];
}

export class ChangePurchaseOrderStatusToConfirmed$Response {
    data: PurchaseOrderResource;
}

export class ChangeUserLanguage$Response {
}

export class CommentFile {
    commentFileContent: string;
    dataContent: string;
}

export class CommonProductListRow {
    productId: string;
    itemCode: string;
    nameAlias: string;
    salesUnit: string;
    externalItemId: string;
    itemName: string;
    itemGroupId: string;
    itemGroupName: string;
    ean: string;
    inventUnit: string;
    availableQuantity: number;
}

export class CommonProductsByGroups {
    groupId: string;
    groupName: string;
    groupNameEN: string;
    containedGroups: string[];
    products: CommonProductListRow[];
}

export class Complaint {
    id: number;
    status: ComplaintStatus;
    customerId: number;
    customerName: string;
    quantity: number;
    roll: string;
    unit: string;
    invoiceNumber: string;
    productionId: string;
    itemCode: string;
    complaintContent: string;
    complaintClaim: string;
    decisionType: ComplaintDecisionType;
    decision: string;
    decisionFileName: string;
    decisionFileExtension: string;
    createdAt: string;
    modifiedAt: string;
    modifiedBy: string;
}

export class ComplaintDecisionRequest {
    complaintId: number;
    decision: string;
    filePath: string;
    fileName: string;
}

export class ComplaintFile {
    id: number;
    complaintId: number;
    comment: string;
    filePath: string;
    fileName: string;
    isImage: boolean;
    isMine: boolean;
    createdAt: string;
    modifiedAt: string;
}

export class ComplaintListRow {
    id: number;
    customerId: string;
    customerName: string;
    status: ComplaintStatus;
    purchaseOrderNumber: number;
    productId: number;
    productCode: string;
    quantity: number;
    createdAt: string;
    modifiedAt: string;
}

export class ComplaintsRequest {
    customerId: number;
    salesManagerId: number;
    complaintId: string;
    purchaseOrderNumber: string;
    status: ComplaintStatus;
    orderBy: ComplaintOrderBy;
    order: SortOrder;
    offset: number;
    limit: number;
    count: boolean;
}

export class CompleteInvitation$Response {
}

export class Confirm$Response {
    data: Design;
}

export class ConfirmDigiproof$Response {
    data: Design;
}

export class CreatePurchaseOrder$Response {
    data: PurchaseOrderResource;
}

export class CustomerData {
    id: number;
    customerName: string;
    accountNumber: string;
    lang: Lang;
    country: string;
    isActive: boolean;
    yearContract: boolean;
    blocked: boolean;
    modifiedAt: string;
    addresses: AddressListRow[];
    representativeUsers: CustomerUser[];
    managerUsers: CustomerUser[];
    products: ProductListRow[];
}

export class CustomerListRow {
    id: number;
    name: string;
    blocked: boolean;
    myCustomer: boolean;
}

export class CustomerProductsRequest {
    customerId: number;
    name: string;
    code: string;
    activeFilter: ActiveFilter;
    orderBy: ProductOrderBy;
    order: SortOrder;
    offset: number;
    limit: number;
}

export class CustomerStandardProductSalesUnit {
    id: number;
    customerId: number;
    productId: number;
    customSalesUnitId: number;
    customSalesUnit: string;
    defaultUnitId: number;
    defaultUnit: string;
    coefficient: number;
    createdAt: string;
    createdBy: number;
    modifiedAt: string;
    modifiedBy: number;
}

export class CustomerTypes {
    customerId: number;
    customerTypeList: CustomerType[];
}

export class CustomerUser {
    userId: number;
    name: string;
    email: string;
    type: UserCustomerRelationType;
}

export class DeactivateUser$Response {
    data: UserListRow;
}

export class Decline$Response {
    data: Design;
}

export class DeclineDigiproof$Response {
    data: Design;
}

export class DeclineSalesOrder$Response {
    data: PurchaseOrderResource;
}

export class DeleteComplaint$Response {
    data: boolean;
}

export class DeleteComplaintEstiko$Response {
    data: boolean;
}

export class DeleteComplaintFile$Response {
    data: boolean;
}

export class DeleteDesignDigiproofRow$Response {
    data: boolean;
}

export class DeleteLogContent$Response {
    data: VoidClass;
}

export class DeletePurchaseOrder$Response {
    data: boolean;
}

export class DeletePurchaseOrderEstiko$Response {
    data: boolean;
}

export class DeletePurchaseOrderProduct$Response {
    data: boolean;
}

export class Design {
    id: number;
    customerId: number;
    customerName: string;
    designNum: string;
    designName: string;
    nameAlias: string;
    version: number;
    itemId: string;
    productId: number;
    productName: string;
    productNameAlias: string;
    ean: string;
    confirmationDate: string;
    confirmerId: number;
    confirmerName: string;
    needDigiproof: boolean;
    status: DesignStatus;
    createdAt: string;
    modifiedAt: string;
    uploadDate: string;
    digiproofComment: string;
}

export class DesignComment {
    commentId: number;
    designId: number;
    designVersionId: number;
    version: number;
    commentOrder: number;
    hasImage: boolean;
    content: string;
    createdAt: string;
    modifiedAt: string;
    owner: string;
    isMyComment: boolean;
}

export class DesignConfirmRequest {
    designId: number;
    needsDigiproof: boolean;
    digiproofComment: string;
}

export class DesignDigiproofListRow {
    id: number;
    designId: number;
    addressId: number;
    fullAddress: string;
    contactPerson: string;
    phoneNumber: string;
    trackingNumber: string;
}

export class DesignDigiproofRow {
    id: number;
    designId: number;
    addressId: number;
    contactPerson: string;
    phoneNumber: string;
    trackingNumber: string;
    createdAt: string;
    createdBy: number;
    modifiedAt: string;
    modifiedBy: number;
}

export class DesignDigiproofSaveRow {
    digiproofId: number;
    designId: number;
    addressId: number;
    contactPerson: string;
    phoneNumber: string;
    fullAddress: string;
}

export class DesignListRow {
    id: number;
    customerName: string;
    designName: string;
    itemId: string;
    productId: number;
    productName: string;
    confirmationDate: string;
    confirmerName: string;
    status: DesignStatus;
    createdAt: string;
    uploadDate: string;
    modifiedAt: string;
    designId: string;
    ean: string;
}

export class EvaluateTemplate$Response {
    data: EvaluatedTemplate;
}

export class EvaluatedTemplate {
    subject: string;
    content: string;
}

export class FindAllCallOffs$Response {
    data: CallOffCombinedListResource[];
    metadata: ApiResponseMetadata;
}

export class FindCallOffs$Response {
    data: CallOffListResource[];
    metadata: ApiResponseMetadata;
}

export class FrontendError {
    stackTrace: string;
}

export class GetActiveProducts$Response {
    data: ProductListRow[];
    metadata: ApiResponseMetadata;
}

export class GetAllProductUnits$Response {
    data: string[];
}

export class GetApplicationSettings$Response {
    data: ApplicationSettings;
}

export class GetAvailableStandardProductsWithUnits$Response {
    data: StandardProductsWithUnits[];
}

export class GetCallOff$Response {
    data: CallOffResource;
}

export class GetCallOffProducts$Response {
    data: CallOffInventoryProduct[];
    metadata: ApiResponseMetadata;
}

export class GetComments$Response {
    data: PurchaseOrderCommentResource[];
    metadata: ApiResponseMetadata;
}

export class GetCommonProductsInGroups$Response {
    data: CommonProductsByGroups[];
    metadata: ApiResponseMetadata;
}

export class GetCommonProductsList$Response {
    data: CommonProductListRow[];
    metadata: ApiResponseMetadata;
}

export class GetComplaint$Response {
    data: Complaint;
}

export class GetComplaintFiles$Response {
    data: ComplaintFile[];
    metadata: ApiResponseMetadata;
}

export class GetComplaints$Response {
    data: ComplaintListRow[];
    metadata: ApiResponseMetadata;
}

export class GetCustomerAddresses$Response {
    data: AddressListRow[];
    metadata: ApiResponseMetadata;
}

export class GetCustomerData$Response {
    data: CustomerData;
}

export class GetCustomerStandardProductsSalesUnits$Response {
    data: CustomerStandardProductSalesUnit[];
}

export class GetCustomerTypes$Response {
    data: CustomerTypes;
}

export class GetCustomersList$Response {
    data: CustomerListRow[];
    metadata: ApiResponseMetadata;
}

export class GetDesign$Response {
    data: Design;
}

export class GetDesignComments$Response {
    data: DesignComment[];
    metadata: ApiResponseMetadata;
}

export class GetDesignDigiproofs$Response {
    data: DesignDigiproofListRow[];
    metadata: ApiResponseMetadata;
}

export class GetDesignListRequest {
    customerId: number;
    designId: string;
    designName: string;
    ean: string;
    status: DesignStatus;
    orderBy: DesignOrderBy;
    order: SortOrder;
    showAll: boolean;
    offset: number;
    limit: number;
    count: boolean;
}

export class GetDesigns$Response {
    data: DesignListRow[];
    metadata: ApiResponseMetadata;
}

export class GetInventory$Response {
    data: InventoryListRow[];
    metadata: ApiResponseMetadata;
}

export class GetLastUsersFetch$Response {
    data: string;
}

export class GetLatestEmails$Response {
    data: MailListRow[];
}

export class GetLogFileContent$Response {
    data: string;
}

export class GetMailTemplatesRequest {
    orderBy: MailTemplateOrderBy;
    order: SortOrder;
    offset: number;
    limit: number;
    count: boolean;
}

export class GetManagersList$Response {
    data: ManagerListRow[];
    metadata: ApiResponseMetadata;
}

export class GetProductList$Response {
    data: ProductListRow[];
    metadata: ApiResponseMetadata;
}

export class GetProductUnitsMap$Response {
    data: ProductUnitMapRow[];
}

export class GetPurchaseOrder$Response {
    data: PurchaseOrderResource;
}

export class GetPurchaseOrderListRequest {
    customerId: number;
    purchaseOrderNum: string;
    status: PurchaseOrderStatus;
    orderBy: PurchaseOrderOrderBy;
    order: SortOrder;
    showAll: boolean;
    myOrders: boolean;
    offset: number;
    limit: number;
    count: boolean;
}

export class GetPurchaseOrderProductsList$Response {
    data: PurchaseOrderProductListResource[];
}

export class GetPurchaseOrders$Response {
    data: PurchaseOrderListResource[];
    metadata: ApiResponseMetadata;
}

export class GetRepresentativeCustomersList$Response {
    data: CustomerListRow[];
    metadata: ApiResponseMetadata;
}

export class GetSalesOrderProductsList$Response {
    data: SalesOrderProductListResource[];
}

export class GetSalesOrderProductsLiveList$Response {
    data: SalesLineListItem[];
}

export class GetSalesOrderProductsQuoteList$Response {
    data: AxQuotationLineRow[];
}

export class GetStandardProductsList$Response {
    data: ProductListRow[];
    metadata: ApiResponseMetadata;
}

export class GetTemplate$Response {
    data: Template;
}

export class GetTemplates$Response {
    data: TemplateListRow[];
    metadata: ApiResponseMetadata;
}

export class GetUserViewData$Response {
    data: UserViewData;
}

export class GetUsers$Response {
    data: UserListRow[];
    metadata: ApiResponseMetadata;
}

export class GetUsersRequest {
    customerId: number;
    emailOrName: string;
    userType: UserType;
    orderBy: UserOrderBy;
    order: SortOrder;
    offset: number;
    limit: number;
    count: boolean;
}

export class InventoryListCallOffRow {
    salesOrderId: string;
    purchOrderFormNum: string;
    productName: string;
    productNumber: string;
    externalItemId: string;
    deliveryDate: string;
    createdAtDate: string;
    orderedQuantity: number;
    deliveredQuantity: number;
    quantityInWarehouse: number;
    unit: string;
    inventTransId: string;
    lineNum: number;
    calloffInventTransId: string;
    status: AxSalesStatus;
}

export class InventoryListRow {
    index: number;
    salesOrderId: string;
    purchOrderFormNum: string;
    productName: string;
    productNumber: string;
    externalItemId: string;
    deliveryDate: string;
    orderedQuantity: number;
    deliveredQuantity: number;
    quantityInWarehouse: number;
    unit: string;
    inventTransId: string;
    lineNum: number;
    calloffInventTransId: string;
    keepInStock: string;
    callOffRows: InventoryListCallOffRow[];
}

export class InvitationValidationResponse {
    userName: string;
    userEmail: string;
    regCode: string;
    customerName: string;
    customerAddress: string;
    managerUserName: string;
}

export class JwtTokenWrapper {
    jwt: string;
}

export class LogFrontendError$Response {
}

export class LoginAsUser$Response {
    data: UserData;
    metadata: JwtTokenWrapper;
}

export class Logout$Response {
}

export class MailListRow {
    id: number;
    email: string;
    subject: string;
    content: string;
    sentAt: string;
    failCount: number;
}

export class MailTemplate {
    id: number;
    type: MailType;
    filePath: string;
    subject: string;
    lang: Lang;
}

export class ManagerListRow {
    userId: number;
    name: string;
}

export class MarkArchived$Response {
    data: boolean;
}

export class MarkComplaintAsSolved$Response {
    data: Complaint;
}

export class MarkDigiproofAsSent$Response {
    data: boolean;
}

export class NewComment {
    designId: number;
    content: string;
}

export class NewImageComment {
    designId: number;
    content: string;
    commentFile: CommentFile;
}

export class PasswordRecovery {
    username: string;
    hash: string;
    password: string;
}

export class ProductAvailableSalesUnit {
    id: number;
    name: string;
    defaultUnit: string;
    defaultUnitId: number;
    itemCode: string;
    fromUnitId: number;
    fromUnit: string;
    toUnitId: number;
    toUnit: string;
    coefficient: number;
}

export class ProductListRow {
    id: number;
    customerId: number;
    customerName: string;
    code: string;
    name: string;
    externalItemId: string;
    preferredUnit: string;
    codeName: string;
    active: boolean;
    pendingActive: number;
}

export class ProductResponse {
    id: number;
    active: boolean;
    customerId: number;
    code: string;
    name: string;
    nameAlias: string;
    externalItemId: string;
    preferredUnit: string;
    ean: string;
    groupCode: string;
    groupName: string;
    pendingActive: number;
}

export class ProductStatusUpdateRequest {
    status: number;
}

export class ProductUnitMapRow {
    productId: number;
    units: string[];
}

export class PullUsersFromAxo$Response {
    data: boolean;
}

export class PurchaseOrderCommentResource {
    id: number;
    version: number;
    type: PurchaseOrderCommentType;
    content: string;
    createdAt: string;
    modifiedAt: string;
    createdByUser: string;
}

export class PurchaseOrderListResource {
    id: number;
    customerId: number;
    customerName: string;
    purchaseOrderNum: string;
    salesOrderNum: string;
    fullAddress: string;
    status: PurchaseOrderStatus;
    createdAt: string;
    modifiedAt: string;
}

export class PurchaseOrderProductListResource {
    id: number;
    productId: number;
    productName: string;
    itemId: string;
    externalItemId: string;
    ean: string;
    quantity: number;
    unit: string;
    date: string;
    keepInStock: boolean;
}

export class PurchaseOrderResource {
    id: number;
    customerId: number;
    customerName: string;
    purchaseOrderNum: string;
    addressId: number;
    fullAddress: string;
    status: PurchaseOrderStatus;
    salesOrderId: number;
    salesOrderNum: string;
    salesOrderCreatedAt: string;
    paymentType: string;
    paymentNumberOfDays: number;
    customerCreditMaxMst: number;
    customerInput: boolean;
    deliveryTerm: string;
    yearContract: boolean;
    createdAt: string;
    modifiedAt: string;
}

export class RecoverPassword$Response {
    data: boolean;
}

export class RecoveryRequest {
    email: string;
}

export class RejectComplaint$Response {
    data: Complaint;
}

export class ReopenDesign$Response {
    data: Design;
}

export class RequestPurchaseOrder$Response {
    data: PurchaseOrderResource;
}

export class SalesLineLisItemRow {
    requestedQuantity: number;
    unit: string;
    salesStatus: AxSalesStatus;
    requestedDelivery: string;
    quantityDelivered: number;
    deliveryDate: string;
    price: number;
    currency: string;
    invoiceId: string;
    unitPrice: number;
}

export class SalesLineListItem {
    rowId: string;
    productCode: string;
    productName: string;
    externalItemId: string;
    ean: string;
    rows: SalesLineLisItemRow[];
}

export class SalesOrderProductListResource {
    id: number;
    productId: number;
    productName: string;
    productCode: string;
    externalItemId: string;
    ean: string;
    requestedQuantity: number;
    unit: string;
    requestedDelivery: string;
    price: number;
    currency: string;
}

export class SaveAddress$Response {
    data: number;
}

export class SaveAddressForm {
    id: number;
    customerId: number;
    country: string;
    city: string;
    street: string;
    postalCode: string;
    fullAddress: string;
}

export class SaveCallOff$Response {
    data: number;
}

export class SaveComplaint$Response {
    data: Complaint;
}

export class SaveComplaintFile$Response {
    data: ComplaintFile[];
    metadata: ApiResponseMetadata;
}

export class SaveComplaintRequest {
    complaintId: number;
    customerId: number;
    quantity: number;
    roll: string;
    complaintContent: string;
    complaintClaim: string;
    files: ComplaintFile[];
    invoiceNum: string;
    productionId: string;
    itemCode: string;
    unit: string;
}

export class SaveCustomerType$Response {
}

export class SaveDesignDigiproofRow$Response {
    data: DesignDigiproofRow;
}

export class SaveNotificationsRequest {
    userId: number;
    mailTypes: MailType[];
}

export class SavePurchaseOrderCommentRequest {
    content: string;
}

export class SaveStandardProductCustomerSalesUnit$Response {
    data: boolean;
}

export class SaveStandardProductOrder$Response {
    data: number;
}

export class SaveTemplate$Response {
    data: boolean;
}

export class SaveUserNotifications$Response {
    data: boolean;
}

export class SendInvitation$Response {
}

export class SendRecoveryEmail$Response {
}

export class SendToCustomer$Response {
    data: Design;
}

export class StandardProductOrder {
    productId: string;
    itemCode: string;
    nameAlias: string;
    salesUnit: string;
    externalItemId: string;
    itemName: string;
    itemGroupId: string;
    itemGroupName: string;
    ean: string;
    inventUnit: string;
    availableQuantity: number;
    orderQuantity: number;
    orderDeliveryDate: string;
}

export class StandardProductOrderSaveResource {
    products: StandardProductOrder[];
    orderNumber: string;
    comment: string;
    addressId: string;
}

export class StandardProductsWithUnits {
    id: number;
    name: string;
    defaultUnit: string;
    defaultUnitId: number;
    itemCode: string;
    possibleConversions: ProductAvailableSalesUnit[];
}

export class StopDesign$Response {
    data: Design;
}

export class SubmitComplaint$Response {
    data: boolean;
}

export class Template {
    id: number;
    lang: Lang;
    type: MailType;
    subject: string;
    content: string;
    evaluatedContent: string;
    params: string[];
    updatedAt: string;
    updatedBy: string;
}

export class TemplateListRow {
    id: number;
    lang: Lang;
    type: MailType;
    subject: string;
    modifiedAt: string;
    modifiedBy: string;
}

export class ToggleAdmin$Response {
    data: boolean;
}

export class ToggleAdminRequest {
    userId: number;
    value: boolean;
}

export class ToggleEmail$Response {
    data: ApplicationSettings;
}

export class ToggleNotificationEmails$Response {
    data: boolean;
}

export class ToggleNotificationRequest {
    userId: number;
    value: boolean;
}

export class UpdateAdUsername$Response {
    data: boolean;
}

export class UpdateDesignDigiproofTrackingNumber$Response {
    data: DesignDigiproofRow;
}

export class UpdateProductStatus$Response {
    data: ProductResponse;
}

export class UpdatePurchaseOrder$Response {
    data: PurchaseOrderResource;
}

export class UpdatePurchaseOrderProduct$Response {
    data: PurchaseOrderProductListResource;
}

export class UpdatePurchaseOrderProductRequest {
    quantity: number;
    unit: string;
    date: string;
    keepInStock: boolean;
}

export class UpdatePurchaseOrderRequest {
    addressId: number;
    purchaseOrderNum: string;
}

export class UpdateTrackingNumberRequest {
    designDigiproofId: number;
    trackingNumber: string;
}

export class UseAddress$Response {
    data: boolean;
}

export class UserCustomerListRow {
    customerId: number;
    customerName: string;
}

export class UserData {
    userId: number;
    username: string;
    name: string;
    email: string;
    type: UserType;
    profile: UserProfile;
    lang: Lang;
    sessionExpires: string;
    numberOfCustomers: number;
}

export class UserListRow {
    id: number;
    type: UserType;
    isAdmin: boolean;
    isDesigner: boolean;
    name: string;
    username: string;
    adUsername: string;
    email: string;
    numberOfCustomers: number;
    active: boolean;
    activatedAt: string;
    sendNotificationEmail: boolean;
}

export class UserProfile {
    isAdmin: boolean;
    isManager: boolean;
    isCustomer: boolean;
    isDesigner: boolean;
    representsBuyer: boolean;
    representsReseller: boolean;
}

export class UserViewData {
    id: number;
    name: string;
    username: string;
    adUsername: string;
    email: string;
    lang: Lang;
    assistantEmail: string;
    isActive: boolean;
    activatedAt: string;
    type: UserType;
    isAdmin: boolean;
    isDesigner: boolean;
    isNotificationsEnabled: boolean;
    customers: UserCustomerListRow[];
    mailExclusions: MailType[];
}

export class ValidateInvitation$Response {
    data: InvitationValidationResponse;
}

export class ValidationError {
    objectName: string;
    field: string;
    errorCode: string;
    minValue: any;
    maxValue: any;
    rejectedValue: any;
}

export class VoidClass {
}

export class WhoAmI$Response {
    data: UserData;
}

