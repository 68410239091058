import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {Notification, NotificationType} from './notification.model';
import {Observable, Subject, Subscription} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {OnClean} from '../../custom-interfaces';

@Injectable()
export class NotificationService implements OnClean, OnDestroy {

  private subject = new Subject<Notification>();
  private keepAfterRouteChange = false;

  private subscriptions = new Subscription();

  constructor(private router: Router) {

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!this.keepAfterRouteChange) {
          this.clear();
        }
      }
    });

  }

  public getNotification(): Observable<Notification> {
    return this.subject.asObservable();
  }

  public success(message: string, autohide = true, keepAfterRouteChange = false): Notification {
    return this.notification(NotificationType.Success, message, autohide, keepAfterRouteChange, 4000);
  }

  public error(message: string, autohide = true, keepAfterRouteChange = false): Notification {
    return this.notification(NotificationType.Error, message, autohide, keepAfterRouteChange, 5000);
  }

  public info(message: string, autohide = true, keepAfterRouteChange = false): Notification {
    return this.notification(NotificationType.Info, message, autohide, keepAfterRouteChange, 4000);
  }

  public warning(message: string, autohide = true, keepAfterRouteChange = false): Notification {
    return this.notification(NotificationType.Warning, message, autohide, keepAfterRouteChange, 4000);
  }

  public newApiVersionNotification() {
    return this.notification(NotificationType.NewApiVersion, 'api.refreshNotification', false, true);
  }

  public notification(type: NotificationType, message: string, autohide = true, keepAfterRouteChange = false, time = null): Notification {
    if (message === undefined || message == null) {
      return null;
    }
    this.keepAfterRouteChange = keepAfterRouteChange;
    const notification = <Notification>{type: type, autohide: autohide, message: message, visibilityTime: time, onHide: new EventEmitter()};
    this.subject.next(notification);
    return notification;
  }

  public clear(): void {
    this.subject.next();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  clean(): void {
    this.clear();
    this.ngOnDestroy();
  }
}
