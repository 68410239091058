import { ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
var ControlMessagesComponent = /** @class */ (function () {
    function ControlMessagesComponent(elementRef, translate) {
        this.elementRef = elementRef;
        this.translate = translate;
    }
    ControlMessagesComponent.prototype.ngOnInit = function () {
        if (TooltipPosition[this.position] === TooltipPosition.right) {
            this.tooltipPosition = TooltipPosition.right;
            var prevElement = this.elementRef.nativeElement.previousElementSibling;
            this.elementRef.nativeElement.style.left = prevElement.offsetLeft + prevElement.offsetWidth + 'px';
            this.elementRef.nativeElement.style.position = 'absolute';
            this.elementRef.nativeElement.style.top = 0;
            this.cssClass += ' tooltip-right right';
        }
        else if (TooltipPosition[this.position] === TooltipPosition.top) {
            this.tooltipPosition = TooltipPosition.top;
            this.cssClass += ' tooltip-top top';
        }
        else {
            this.tooltipPosition = TooltipPosition.bottom;
            this.cssClass += ' tooltip-bottom bottom';
        }
        if (TooltipColor[this.color] === TooltipColor.green) {
            this.tooltipColor = TooltipColor.green;
            this.cssClass += ' success';
        }
        else if (TooltipColor[this.color] === TooltipColor.yellow) {
            this.tooltipColor = TooltipColor.yellow;
            this.cssClass += ' warning';
        }
        else if (TooltipColor[this.color] === TooltipColor.red) {
            this.tooltipColor = TooltipColor.red;
            this.cssClass += ' error';
        }
        else {
            this.tooltipColor = TooltipColor.black;
        }
    };
    Object.defineProperty(ControlMessagesComponent.prototype, "hasErrors", {
        get: function () {
            var fieldErrors = this.errors[this.fieldName];
            if (fieldErrors) {
                for (var code in fieldErrors) {
                    if (fieldErrors[code] !== undefined) {
                        return true;
                    }
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControlMessagesComponent.prototype, "fieldErrorCodes", {
        get: function () {
            var codes = [], errs = this.errors[this.fieldName];
            for (var code in errs) {
                codes.push(errs[code]);
            }
            return codes;
        },
        enumerable: true,
        configurable: true
    });
    return ControlMessagesComponent;
}());
export { ControlMessagesComponent };
var TooltipColor;
(function (TooltipColor) {
    TooltipColor[TooltipColor["red"] = 0] = "red";
    TooltipColor[TooltipColor["green"] = 1] = "green";
    TooltipColor[TooltipColor["yellow"] = 2] = "yellow";
    TooltipColor[TooltipColor["black"] = 3] = "black";
})(TooltipColor || (TooltipColor = {}));
var TooltipPosition;
(function (TooltipPosition) {
    TooltipPosition[TooltipPosition["right"] = 0] = "right";
    TooltipPosition[TooltipPosition["top"] = 1] = "top";
    TooltipPosition[TooltipPosition["bottom"] = 2] = "bottom";
})(TooltipPosition || (TooltipPosition = {}));
