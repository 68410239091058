import { ReplaySubject } from 'rxjs';
import { LangValues, UserData, UserProfile } from '../api/api-types';
import { ApiService } from '../api/api-service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../shared/notification/notification.service';
import { ApiClient } from '../api/api-client';
import { share, take, tap } from 'rxjs/operators';
import { last } from 'rxjs/internal/operators/last';
import { requestParamsToUrl } from '../common/ApiUtil';
var flatten = require('flat');
var AuthService = /** @class */ (function () {
    function AuthService(router, api, apiClient, translateService, notificationService) {
        this.router = router;
        this.api = api;
        this.apiClient = apiClient;
        this.translateService = translateService;
        this.notificationService = notificationService;
        this.userData = this.createUserData();
        this.userRoleSubject = new ReplaySubject(null);
        this.setLang = LangValues.EN;
        this.whoAmIInProgress = false;
        this.translateService.setDefaultLang('ET');
        this.translateService.setDefaultLang('EN');
    }
    Object.defineProperty(AuthService.prototype, "getUserData", {
        get: function () {
            return this.userData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "userId", {
        get: function () {
            return this.userData.userId;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.whoAmI = function (force) {
        var _this = this;
        if (force === void 0) { force = false; }
        if ((!this.userData || !this.userData.userId) && !this.whoAmIInProgress || force) {
            this.whoAmIInProgress = true;
            this.api.auth.whoAmI().pipe(take(1)).subscribe(function (response) {
                _this.userData = response.data;
                if (response && response.data && response.data.lang) {
                    _this.useLang(response.data.lang);
                }
                _this.userRoleSubject.next(_this.userData);
                if (response.data.sessionExpires) {
                    _this.setSessionNotifications(new Date(response.data.sessionExpires));
                }
                _this.whoAmIInProgress = false;
            }, function (error) {
                _this.whoAmIInProgress = false;
            });
        }
        else {
            this.userRoleSubject.next(this.userData);
        }
        return this.userRoleSubject;
    };
    AuthService.prototype.authenticate = function (userName, password) {
        var _this = this;
        this.apiClient.clean();
        return this.api.auth.authenticate({ userName: userName, password: password })
            .pipe(take(1), tap(function (res) {
            if (res.data && res.metadata && res.metadata.jwt) {
                localStorage.setItem('jwtToken', res.metadata.jwt);
                var sessionExpirationDate = new Date(((new Date).getTime() + 36000000));
                _this.userData = {
                    userId: res.data.userId,
                    username: res.data.username,
                    name: res.data.name,
                    email: res.data.email,
                    type: res.data.type,
                    profile: res.data.profile,
                    lang: res.data.lang,
                    numberOfCustomers: res.data.numberOfCustomers,
                    sessionExpires: sessionExpirationDate.toString()
                };
                _this.userRoleSubject.next(_this.userData);
                _this.useLang(_this.userData.lang);
                _this.setSessionExpiresNotification(sessionExpirationDate);
            }
        }), share());
    };
    AuthService.prototype.setPassword = function (username, token, password) {
        var request = {
            username: username,
            hash: token,
            password: password
        };
        return this.api.auth.completeInvitation(request);
    };
    AuthService.prototype.setSessionNotifications = function (expires) {
        if (this.expiresTimer || this.expiredTimer) {
            return;
        }
        this.setSessionExpiresNotification(expires);
    };
    AuthService.prototype.setSessionExpiresNotification = function (expires) {
        var _this = this;
        var c = expires.getTime() - (new Date().getTime());
        c -= 300000; // 5 minutes
        if (c < 0) {
            c = 0;
        }
        this.expiresTimer = setTimeout(function () {
            _this.expiresNotification =
                _this.notificationService.info('notification.sessionExpires', false, false);
        }, c);
        this.setSessionExpiredNotification(expires);
    };
    AuthService.prototype.setSessionExpiredNotification = function (expires) {
        var _this = this;
        var c = expires.getTime() - (new Date().getTime());
        this.expiredTimer = setTimeout(function () {
            _this.expiresNotification.onHide.emit(true);
            _this.expiredNotification =
                _this.notificationService.error('notification.sessionExpired', false, false);
        }, c);
    };
    AuthService.prototype.logOut = function (navigateToHome) {
        if (navigateToHome === void 0) { navigateToHome = true; }
        localStorage.clear();
        this.userData = this.createUserData();
        this.userRoleSubject.next(this.userData);
        if (this.expiresTimer) {
            clearTimeout(this.expiresTimer);
            this.expiresTimer = undefined;
        }
        if (this.expiredTimer) {
            clearTimeout(this.expiredTimer);
            this.expiredTimer = undefined;
        }
        this.expiresNotification = undefined;
        this.expiredNotification = undefined;
        if (navigateToHome) {
            this.router.navigate(['/login'], {});
        }
    };
    AuthService.prototype.resetPassword = function (username, token, password) {
        return this.api.auth.recoverPassword({ username: username, hash: token, password: password });
    };
    AuthService.prototype.sendResetPasswordEmail = function (email) {
        return this.api.auth.sendRecoveryEmail({ email: email });
    };
    AuthService.prototype.validateToken = function (token) {
        return this.api.auth.validateInvitation(token);
    };
    AuthService.prototype.clean = function () {
        this.logOut();
    };
    AuthService.prototype.useDevTranslations = function () {
        var DEV_T_F = flatten(this.translateService.store.translations['EN']);
        var DEV_T = {};
        Object.keys(DEV_T_F).forEach(function (key) { return DEV_T[key] = key; });
        this.translateService.addLangs(['DEV']);
        this.translateService.store.translations['DEV'] = DEV_T;
        this.translateService.use('DEV');
    };
    AuthService.prototype.useLang = function (lang) {
        var _this = this;
        this.setLang = lang;
        this.translateService.use(lang).pipe(last()).subscribe(function () {
            if (lang !== _this.setLang) {
                _this.useLang(_this.setLang);
            }
        });
    };
    AuthService.prototype.updateLanguage = function (lang) {
        if (this.userData && !!this.userData.lang && this.userData.lang !== lang) {
            var requestParams = [{ key: 'lang', value: lang }];
            this.api.users.changeUserLanguage(this.userData.userId, requestParamsToUrl(requestParams)).pipe(take(1)).subscribe(function () {
                location.reload();
            });
        }
        else {
            this.useLang(lang);
        }
    };
    AuthService.prototype.createUserData = function () {
        var userData = new UserData();
        userData.profile = new UserProfile();
        return userData;
    };
    return AuthService;
}());
export { AuthService };
