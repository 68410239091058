/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./language-select.component";
import * as i4 from "../../services/auth.service";
var styles_LanguageSelectComponent = [i0.styles];
var RenderType_LanguageSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguageSelectComponent, data: {} });
export { RenderType_LanguageSelectComponent as RenderType_LanguageSelectComponent };
function View_LanguageSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "className", 0], [2, "visible", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((!_v.context.$implicit.selected ? _co.chooseAndUpdate(_v.context.$implicit.value) : undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "option flag-icon flag-icon-", _v.context.$implicit.code, ""); var currVal_1 = (_v.context.$implicit.selected || _co.isOpen); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_LanguageSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "selector"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "options"]], [[2, "isOpen", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageSelectComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.languages; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOpen; _ck(_v, 1, 0, currVal_0); }); }
export function View_LanguageSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "language-select", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).clickout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LanguageSelectComponent_0, RenderType_LanguageSelectComponent)), i1.ɵdid(1, 245760, null, 0, i3.LanguageSelectComponent, [i1.ElementRef, i4.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LanguageSelectComponentNgFactory = i1.ɵccf("language-select", i3.LanguageSelectComponent, View_LanguageSelectComponent_Host_0, {}, {}, []);
export { LanguageSelectComponentNgFactory as LanguageSelectComponentNgFactory };
